.user_bonus {
  @include max-width-center(100%, 1130px);
  display: flex;
  justify-content: space-between;
  position: relative;

  &__container {
    box-sizing: border-box;


    &:first-child {
      padding: 375px 20px 0 0;
    }

    &:last-child {
      overflow: hidden;
      width: calc(100% - 450px);
    }

    p {
      line-height: 1.2;

      &:not(:last-child) {
        padding-bottom: 15px;
      }
    }

    &__top {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      & > *:not(p) {
        margin-bottom: 25px;
      }
    }
  }

  &__title {
    font-family: $fontLight;
    color: $darkColor;
    font-size: 36px;
    line-height: .9;
    //margin-bottom: 25px;
    
    .number {
      font-size: 48px;
      color: $blueColor;
    }
  }
}

.diagram_box {
  position: absolute;
  top: $verticalPd;
  left: $gorisontalPd;
  width: 430px;
}

.user_bonus__title__small {
  font-family: $fontLight;
  color: $darkColor;
  font-size: 24px;
  //margin-bottom: 25px;
}

.diagram_container {
  position: relative;
}

.diagram_circle {
  transform: rotateZ(-90deg);
  display: flex;
  justify-content: center;
  position: relative;
}

.val_diagram {
  transform: translate(-50%, -50%);
  font-family: $fontMedium;
  color: $darkColor;
  text-align: center;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;

  .number {
    display: block;
    font-family: $fontNumberSemibold;
    font-size: 56px;
    color: $darkMediumColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coin {
    font-size: 18px;
    padding-top: 15px;
  }
}

.cicle_chart {
  @include max-width-center(100%, 325px);
  display: flex;
  font-family: $fontMedium;
  justify-content: space-between;
  color: $darkColor;
  font-size: 14px;
  text-align: center;
  margin-bottom: 35px;
  padding-top: 17px;

  .number {
    font-family: $fontNumberSemibold;
    color: $darkMediumColor;
    font-size: 37px;
    display: block;
    margin-bottom: 10px;
  }

  & > * {
    padding-bottom: 20px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 5px;
      border-radius: 2px;
      background: #113b79;
    }

    &:first-child {
      &:after {
        background: $blueColor;
      }
    }

    &:last-child {
      &:after {
        background: #85b6ff;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .diagram_box {
    top: $verticalPdTab;
    left: $gorisontalPdTab;
  }
}

@media screen and (max-width: 1023px) {
  .diagram_box {
    position: absolute;
    position: static;
    top: 0;
    left: 0;
    width: 100%;
  }

  .user_bonus {
    max-width: 280px;

    &__container {
      &:first-child {
        display: none;
      }

      &:last-child {
        width: 100%;
      }

      p:not(:last-child) {
        padding-bottom: 10px;
      }

      &__top:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__title {
      font-size: 30px;
      line-height: 1.4;

      .number {
        font-size: 36px;
      }

      &__small {
        font-size: 18px;
      }
    }
  }

  .cicle_chart {
    margin-bottom: 25px;
  }
}