.exchange_page {
  padding-top: 264px;
  box-sizing: border-box;

  .new_wallet {
    margin-top: 5px;
    position: absolute;
    top: 100%;
    left: 20px;
    font-size: 14px;
    color: $blueColor;
    cursor: pointer;
    padding-left: 27px !important;
    line-height: 18px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
      background: $blueColor;
      border-radius: 50%;
      color: $liteColor;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      @include sprite($plus-little);
    }
  }
}

.fix_box {
  background: rgba($liteColor, 1);
  padding: 80px 0 15px 0;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  color: $liteColor;
  z-index: 5;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($blueColor, .8);
    z-index: -1;
  }

  .worksection {
    @include max-width-center(100%, 1140px);
    padding: 0 20px;
    box-sizing: border-box;
  }

  .title_small {
    color: $liteColor;
    text-align: right;
    margin-bottom: 15px;
  }
}

.exchange_steps {
  display: flex;
  justify-content: space-between;
  @include max-width-center(100%, 820px);
  font-family: $fontLight;
  align-items: center;
  margin-bottom: 12px;

  &__item:not(.arrow) {
    padding: 15px 5px;
    box-sizing: border-box;

    &.active_step {
      border-bottom: 2px solid $liteColor;
    }
  }
}

.arrow {
  margin: 0 5px;
  @include sprite($arrow);
}

.box_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 0 20px;

  &__left {
    font-family: $fontLight;
    font-size: 18px;
    display: flex;
    align-items: center;

    .number {
      padding-left: 10px;
      font-size: 36px;
    }
  }

  &__rigth {
    font-family: $fontLight;
    text-align: right;

    .number {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}

.exchange_step {
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;
  @include max-width-center(100%, 690px);
  line-height: 1.7;

  .attention {
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .with_title {
    &:not(:last-child) {
      margin-bottom: 30px!important;
    }
  }

  .attention_container {
    padding: 20px;
    box-sizing: border-box;
    background: rgba(#777777, .07);
    border-radius: 7px;
    margin-bottom: 15px;

    .attention {
      margin-bottom: 5px;
      color: $darkColor;
      font-family: $fontMedium;
    }
  }

  .title_fields {
    font-size: 30px;
    color: $darkColor;
    font-family: $fontMedium;
    margin-bottom: 25px;
    display: block;
  }


  .fields_container {
    & > *:not(.title_fields) {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }

  &.finish_step {
    .fields_container {
      & > *:not(.title_fields) {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

.exchange_variant {
  display: flex;
  justify-content: space-between;

  &__item {
    width: calc(50% - 15px);
    background: $liteColor;
    border-radius: 7px;
    padding: 19px;
    box-sizing: border-box;
    @include boxShadow();

    p {
      letter-spacing: -.1px;

      span {
        color: $blueColor;
        font-family: $fontMedium;
      }
    }
  }

  .label_radio {
    font-family: $fontMedium;
    color: $darkColor;
    font-size: 16px;
    line-height: 18px;
  }

  .radio_container {
    margin-bottom: 15px;
  }
}

.wallet_field {
  margin-bottom: 65px !important;

  .error_text {
    padding-top: 25px;
  }
}

//.exchange_btn {
  //display: flex;
  //justify-content: flex-end;

  //&.space_between {
  //  justify-content: space-between;
  //}
//}

.confirm_step {
  .exchange_btn {
    justify-content: space-between;
  }
}

.finish_step {
  text-align: center;
  font-size: 16px;
  position: relative;


  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100px;
    left: -65%;
    background: url(../images/bg/paper-plane-bg.png);
    background-repeat: no-repeat;
    background-position: 100% 0;

  }

  .title_fields {
    padding-top: 65px;
    position: relative;
    margin-bottom: 15px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @include sprite($paper-plane);
    }
  }

  .exchange_btn {
    justify-content: center;
  }
  
  &__text {
    @include max-width-center(100%, 480px);
    margin-bottom: 30px;

    & > *:not(:last-child) {
      padding-bottom: 10px;
    }
  }


}

.exchange_thanks {
  color: $blueColor;
  font-size: 30px;
}

.confirm_text {
  font-size: 24px;
  color: $darkMediumColor;
}

//____________________________________exchange_currency

.exchange_currency{
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;
  @include max-width-center(100%, 950px);

  .number{
    text-transform: uppercase;
    &.blue{
      color: $blueColor;
    }
  }

  &__title{
    font-family: $fontLight;
    color: $darkColor;
    font-size: 24px;
    line-height: 1.2;
    padding-bottom: 30px;
    text-align: center;
  }

  &__list{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 1.2;
    color: $darkColor;
    padding-bottom: 35px;
    li{
      width: 50%;
      padding-bottom: 15px;
      box-sizing: border-box;
      &:nth-child(odd){
        padding-right: 5px;
      }
      &:nth-child(even){
        padding-left: 5px;
      }
    }
    .list_title{
      font-family: $fontLight;
    }
    .list_info{
      font-family: $fontMedium;
      color: $blueColor;
    }

    .arrow{
      display: inline-block;
      @include sprite($arrow-dark);
    }
    .arrow_blue{
      display: inline-block;
      @include sprite($arrow-blue);
    }
  }

  .next-step{
    font-family: $fontMedium;
    font-size: 18px;
    color: $darkColor;
    line-height: 1.2;
    padding-bottom: 25px;
  }

  form{
    @include max-width-center(100%, 415px);
    padding-bottom: 10px;

    .label_field,
    .input_field{
      color: $darkMediumColor;
    }

    ::-webkit-input-placeholder {
      color: $darkMediumColor;
    }
    ::-moz-placeholder {
      color: $darkMediumColor;
    }
    :-ms-input-placeholder {
      color: $darkMediumColor;
    }
    :-moz-placeholder {
      color: $darkMediumColor;
    }

    .label_field{
      .error_text{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1280px){
  .exchange_step {
    padding: $verticalPdTab $gorisontalPdTab;
  }

  .exchange_currency {
    padding: $verticalPdTab $gorisontalPdTab;
  }
}

@media screen and (max-width: 1023px){
  .exchange_page {
    padding-top: 0;
  }

  .exchange_step {
    padding: $verticalPdMob $gorisontalPdMob;

    .title_fields {
      font-size: 22px;
      margin-bottom: 5px;
    }

    .fields_container {
      & > *:not(.title_fields) {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .space_between, .flex_end {
      padding-top: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      & > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &.finish_step {
      font-size: 14px;
      line-height: 1.3;

      &:before {
        display: none;
      }

      .title_fields {
        font-size: 18px;
        margin-bottom: 0px;
      }

      .fields_container > *:not(.title_fields):not(:last-child) {
        margin-bottom: 20px;
      }

      .fields_container:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  .finish_step__text > {
    *:not(:last-child) {
      font-size: 13px;
      padding-bottom: 15px;
    }
  }

  .exchange_thanks {
    font-size: 16px;
  }

  .exchange_steps {
    position: relative;
    justify-content: center;

    &__item {
      &:not(.active_step) {
        display: none;
      }

      &:not(.arrow) {
        padding: 10px 0;
      }
    }

    .active_step {
      & + .arrow {
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }
  }

  .fix_box {
    position: relative;

    .worksection {
      max-width: 320px;
      box-sizing: border-box;
    }

    .title_small {
      font-size: 24px;
      text-align: center;
      margin-bottom: 5px;
    }
  }

  .box_bottom {
    &__left {
      display: none;
    }

    &__rigth {
      width: 100%;
      text-align: center;
      font-size: 12px;
      letter-spacing: -.8px;

      .number {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

  //____________________________________exchange_currency

  .exchange_currency{
    padding: $verticalPdMob $gorisontalPdMob;

    &__title{
      font-size: 20px;
      padding-bottom: 20px;
    }

    &__list{
      font-size: 14px;
      padding-bottom: 15px;
      li{
        width: 100%;
        padding-bottom: 10px;

        &:nth-child(odd){
          padding-right: 0;
        }

        &:nth-child(even){
          padding-left: 0;
        }
      }
    }

    .next-step{
      font-size: 16px;
      padding-bottom: 20px;
    }

    form{
      padding-bottom: 5px;
    }
  }
}
//
@media screen and (max-width: 768px){
  .exchange_variant {
    flex-direction: column;

    &__item {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}