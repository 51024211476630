body {
  overflow-x: hidden;
  font-family: $fontBasic;
  font-size: $fontSizeBasic;
  color: $darkColor;
  background: $darkColor;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

#main {
  overflow: hidden;
  background-color: #fff;
  transform-origin: center bottom 0px;
  transition: all 0.7s ease 0s;
  box-shadow: 0px 22px 54px rgba(0, 0, 0, 0.5);

  &.scaled {
    transform: translateY(-60px) scale(.9);
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &.pseudo_showed {
    position: relative;
    z-index: 15;
  }
}

.slide {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  video {
    min-height: 100%;
    width: auto;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $liteColor;
  background: transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: $fontMedium;

  //************
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  &:after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    color: $blueColor;
    transform: translate3d(0, 25%, 0);
  }

  span {
    display: block;
    width: 100%;
    text-align: center;
  }

  &:after, & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  &:hover {
    background-color: #fff;

    &:after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    span {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }

    &.btn__fill {
      background-color: transparent;
    }
  }
  //************

  &__xs {
    width: 95px;
    height: 36px;
    border-radius: 18px;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__small {
    width: 165px;
    height: 46px;
    border-radius: 23px;
    font-size: 14px;
  }

  &__medium {
    width: 203px;
    height: 46px;
    border-radius: 23px;
    font-size: 14px;
  }

  &__large {
    width: 240px;
    height: 46px;
    border-radius: 23px;
    font-size: 14px;
  }

  &__white {
    color: $liteColor;
    border-color: $liteColor;

    &:after {
      color: $blueColor;
    }

    &:hover {
      background-color: $liteColor;
    }

    &.btn__fill {
      background-color: $liteColor;
      color: $blueColor;

      &:after {
        color: $liteColor;
      }
    }
  }

  &__blue {
    color: $blueColor;
    border-color: $blueColor;

    &:after {
      color: $liteColor;
    }

    &:hover {
      background-color: $blueColor;
    }

    &.btn__fill {
      background-color: $blueColor;
      color: $liteColor;

      &:after {
        color: $blueColor;
      }
    }
  }

  &__dark {
    border-color: $darkMediumColor;
    color: $darkMediumColor;

    &:after {
      color: $liteColor;
    }

    &:hover {
      background-color: $darkMediumColor;
    }
  }
}

.round_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $liteColor;
  color: $blueColor;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;

  span {
    @include sprite($plus-blue);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -8px;
  }

  &:after {
    content: '';
    @include sprite($plus-white);
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
  }
}

.fields_container {
  .title_fields {
    display: block;
    font-family: $fontMedium;
    font-size: 21px;
    margin-bottom: 10px;
  }
}

.few_fields {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  & > *:not(:last-child) {
    margin-right: 40px;
  }

  & > *:not(.custom_select) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .label_field:last-child {
    margin-bottom: 20px;
  }

  .title_fields {
    align-self: center;
  }

	&.captcha{
		.label_field{
			width: 50%;
		}
		.input_field{
			width: 25%;
			font-family: $fontBasic;
			padding: 0 10px 2px 5px;
			box-sizing: border-box;
		}
		.btn{
			margin-bottom: 0;
		}
	}
}

.label_field {
  cursor: pointer;
  color: rgba($liteColor,.8);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 19px;
  padding: 7px 0;
  margin-bottom: 25px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .field_title {
    padding: 0 5px 6px 0;
    white-space: nowrap;
  }

  &.invalid_field {
    .border_line {
      &:after {
        transform: translateX(0);
        background: rgba($redLightColor,.8);
      }
    }
  }

  .border_line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba($liteColor,.8);
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: transparent;
      transform: translateX(-100%);
      transition: transform .5s;
    }
  }
}

.invalid_field {
  .error_text {
    opacity: 1;
  }
}

.error_text {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: $redLightColor;
  font-size: 14px;
  font-family: $fontLight;
  opacity: 0;
  transition: opacity .3s;
  line-height: 1.5;
}

.input_field {
  border: none;
  background: transparent;
  height: auto;
  color: $liteColor;
  font-size: 32px;
  font-family: $fontMoneyField;
  text-align: right;
  line-height: 1;
  width: 100%;
}

//input:invalid{
//  box-shadow: none;
//}

.custom_select {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  width: 110px;

  .selected_val {
    padding: 10px 28px 10px 4px;
    border-bottom: 1px solid rgba($liteColor,.8);
    position: relative;
    cursor: pointer;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      @include sprite($select-arrow);
      margin-top: 1px;
      transition: transform .2s;
    }
  }

  &__list {
    display: none;
    position: absolute;
    z-index: 4;
    top: 100%;
    right: 0;
    opacity: 0;
    transition: opacity .5s;
    //transform: translateX(-50%);
    background: $liteColor;
    @include boxShadow();
    border-radius: 5px;

    &.one_col{
      //width: 100%;
      //padding: 10px 28px;
      box-sizing: border-box;

      & > * {
        width: 100%;
        padding: 5px 0;
      }
    }

	  &.two_col{
		  //width: 100%;
		  flex-wrap: wrap;
		  padding: 5px 5px 5px 40px;
		  box-sizing: border-box;
		  & > * {
			  width: 50%;
			  padding: 5px;
		  }
	  }

	  &.three_col{
		  //width: 100%;
		  flex-wrap: wrap;
		  padding: 5px 5px 5px 40px;
		  box-sizing: border-box;
		  & > * {
			  width: 33.333%;
			  padding: 10px;
		  }
	  }

    &.few_col {
      margin-top: 7px;
      width: 312px;
      background: $liteColor;
      border-radius: 8px;
      padding: 10px;
      box-sizing: border-box;
      color: $blueColor;
      //display: flex;
      flex-wrap: wrap;
      left: 50%;
      transform: translateX(-50%);

      //@include boxShadow();

      & > * {
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
      }

      &.little {
        max-width: 180px;
      }
    }


    & > * {
      box-sizing: border-box;
      cursor: pointer;
      &.selected {
        span {
          border-bottom: 1px solid rgba(51,133,255,.8);
        }
      }
    }
  }

  &.elem_droped {
    .selected_val {
      &:after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }
    .custom_select__list {
      display: inline-block;
      opacity: 1;

      &.few_col,
      &.two_col,
      &.three_col{
        display: flex;
      }
    }
  }

  &.right_pos {
    .custom_select__list {
      left: auto;
      right: 0;
      transform: translate(0);
    }
  }

  &.with_title{
    width: 100%;

    .selected_val{
      font-family: $fontMoneyField;
      font-size: 18px;
      padding: 10px 28px;
      text-align: right;
    }
    .custom_select__list{
      font-family: $fontMoneyField;
      font-size: 18px;
      &.one_col {
        padding: 10px 28px;
      }
    }

    .title{
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0 4px 0 0;
      height: 51px;
      //max-width: 145px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 16px;
      color: rgba($darkColor,.8);
      display: flex;
      align-items: center;
    }
  }
}

.text_explain {
  font-size: 11px;
}

.checkbox_container,
.radio_container {
  position: relative;

  .error_text {
    padding-left: 24px;
    box-sizing: border-box;
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
  
  &:checked + label {
    &:after {
      display: block;
      opacity: 1;
    }
  }
}

.label_checkbox,
.label_radio {
  font-family: $fontLight;
  font-size: 14px;
  position: relative;
  padding-left: 24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: block;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    //transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid $liteColor;
    border-radius: 50%;
    box-sizing: border-box;
  }

  &:after {
    left: 9px;
    top: 9px;
    transform: translate(-50%, -50%);
    margin-left: 1px;
    display: none;
    @include sprite($checbox-checked-light);
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.middle_field {
	.label_field {
		font-size: 16px;
		margin-bottom: 30px;
	}
	.custom_select {
		font-size: 16px;
		margin-bottom: 30px;
	}
}

.small_field {
  .label_field {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .input_field {
    font-size: 21px;
  }

  .label_checkbox,
  .label_radio {
    font-size: 11px;
  }

  .few_fields {
    & > * {
      margin-bottom: 20px;
    }

    & > *:last-child {
      margin-bottom: 20px;
    }

    .label_field:last-child {
      margin-bottom: 20px;
    }
  }
}

.dark__fields {
  .error_text {
    color: $redColor;
  }

  .label_field {
    color: rgba($darkColor,.8);

    .border_line {
      background: rgba($darkColor,.8);
    }

    &.invalid_field {
      .border_line {
        &:after {
          transform: translateX(0);
          background: rgba($redColor,.8);
        }
      }
    }
  }

  .input_field {
    color: $darkColor;
  }

  .custom_select {
    .selected_val {
      border-bottom: 1px solid rgba($darkColor,.8);

      &:after {
        @include sprite($select-arrow-dark);
      }
    }
  }

  .label_checkbox,
  .label_radio {

    &:before {
      background: transparent;
      border-radius: 50%;
      border: 1px solid $darkColor;
    }

    &:after {
      left: 8px;
      transform: translate(-50%, -50%);
      display: none;
      @include sprite($checbox-checked-dark);
      opacity: 0;
      transition: opacity 0.3s;
      margin-left: 1px;
    }
  }
}

.textarea_field{
	position: relative;
	width: 100%;
	display: block;
	margin-bottom: 50px;

	&__title{
		font-family: $fontBasic;
		color: rgba($darkColor,.8);
		font-size: 16px;
		display: block;
		position: absolute;
		bottom: 12px;
		left: 0;
		transform-origin: 0 50%;
		transition: transform .5s;
		z-index: 3;
		width: 100%;
		cursor: text;
	}

	.custom_textarea{
		font-family: $fontBasic;
		color: $darkColor;
		font-size: 16px;
		line-height: 1.2;
		resize: none;
		width: 100%;
		border: none;
		border-bottom: 1px solid rgba($darkColor,.8);
		height: 77.6px;
		padding: 10px 0;
		box-sizing: border-box;
		&:focus{
			border-bottom: 1px solid $blueColor;
			& +.textarea_field__title{
				transform: translateY(-75px);
				font-size: 14px;
				color: $blueColor;
			}
		}
	}
	&.focus{
		 .custom_textarea{
			 border-bottom: 1px solid $blueColor;
		 }

		 .textarea_field__title{
			 transform: translateY(-75px);
			 font-size: 14px;
			 color: $blueColor;
		 }
	 }
}

.money, .number {
  font-family: $fontMoneyField;
}

.box__date {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 28px;
  font-family: $fontMoneyField;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
  }

  &.light:before {
    @include sprite($clock-light-icon);
  }
  &.dark {
    color: $darkLight;
    &:before {
      @include sprite($clock-dark-icon);
    }
  }
}

.read_more {
  display: block;
  text-decoration: underline;
}

.nomargin {
  margin: 0;
}

.two_cols {
  overflow: hidden;
  display: flex;
  @include max-width-center(100%, $workSection);

  & > * {
    width: 50%;
  }

  .col_bg {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .col_content {
    display: flex;
  }
}

.title_small {
  font-family: $fontThin;
  font-size: 48px;
  color: $blueColor;
  line-height: 1;
}

.title_big {
  font-family: $fontThin;
  font-size: 98px;
  color: $blueColor;
  line-height: 1;
}

.headset {
  position: fixed;
  z-index: 10;
  bottom: $verticalPd;
  left: $gorisontalPd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 3px 3px 18px 0px rgba(66,66,66,1);
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    @include sprite($headset);
    transform: translate(-50%, -50%);
    transition: all .3s
  }
}

.link_text {
  color: $blueColor;
  display: inline-block;
  padding-left: 3px;
}

.semi_bold {
  font-family: $fontSemiBold;
}

.attention {
  padding-left: 25px;
  position: relative;

  &:before {
    content: '!';
    color: $liteColor;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: $redColor;
    border-radius: 50%;
    line-height: 1.2;
  }
}

.social_network {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;

  .social_network__item {

    .social_icon {
      display: block;
      position: relative;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity .3s;
      }

      &:hover {
        &:after {
          opacity: 1;
        }

        &.fb {
          &:after {
            @include sprite($fb-light-hover);
          }
        }

        &.vk {
          &:after {
            @include sprite($vk-light-hover);
          }
        }

        &.ok {
          &:after {
            @include sprite($ok-light-hover);
          }
        }

        &.google-plus {
          &:after {
            @include sprite($google-plus-light-hover);
          }
        }
      }

      &.fb {
        @include sprite($fb-light);
      }

      &.vk {
        @include sprite($vk-light);
      }

      &.ok {
        @include sprite($ok-light);
      }

      &.google-plus {
        @include sprite($google-plus-light);
      }
    }
  }

  &.dark_social {
    .social_network__item {

      .social_icon {
        display: block;

        &.fb {
          @include sprite($fb-dark);
        }

        &.vk {
          @include sprite($vk-dark);
        }

        &.ok {
          @include sprite($ok-dark);
        }

        &.google-plus {
          @include sprite($google-plus-dark);
        }

        &:hover {
          &.fb {
            &:after {
              @include sprite($fb-dark-hover);
            }
          }

          &.vk {
            &:after {
              @include sprite($vk-dark-hover);
            }
          }

          &.ok {
            &:after {
              @include sprite($ok-dark-hover);
            }
          }

          &.google-plus {
            &:after {
              @include sprite($google-plus-dark-hover);
            }
          }
        }
      }
    }
  }
}

.space_between {
  display: flex;
  justify-content: space-between;
}

.flex_end {
  display: flex;
  justify-content: flex-end;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.close_popup, .close_white {
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
  z-index: 1;

  @include sprite($close-light-big);
}

.close_dark {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;

  @include sprite($close-large);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  &_blue {
    background: rgba($blueColor, .41);
  }

  &_dark {
    background: rgba(99, 99, 99, 0.3);
  }
}

@media screen and (max-width: 1280px){
  .headset {
    bottom: $gorisontalPdTab;
    left: $verticalPdTab;
  }
}

@media screen and (max-width: 1023px){
  .title_big {
    font-size: 45px;
  }

  .title_small {
    font-size: 45px;
  }

  .headset {
    bottom: $gorisontalPdMob;
    left: $gorisontalPdMob;
  }

  .label_field {
    margin-bottom: 20px;
    font-size: 14px;
    flex-direction: column-reverse;
    align-items: flex-start;

    .field_title {
      padding-bottom: 0;
      transform: translateY(20px);
      transition: transform .5s;
    }
  }

  .focus {
    .field_title {
      transform: translateY(0);
    }

    .border_line {
      &:after {
        transform: translateX(0);
        background: rgba($liteColor,.8);
      }
    }
  }

  .dark__fields {
    .focus {
      .border_line {
        &:after {
          background: rgba($blueColor,.8);
        }
      }
    }

    .input_field {
      &:focus {
        & + .field_title {
          color: $blueColor;
        }

        & ~ .border_line {
          &:after {
            background: rgba($blueColor,.8);
          }
        }
      }
    }
  }

  .input_field {
    font-size: 18px;

    &:focus {
      & + .field_title {
        color: $liteColor;
        transform: translateY(0);
      }

      & ~ .border_line {
        &:after {
          transform: translateX(0);
          background: rgba($liteColor,.8);
        }
      }
    }
  }

  .custom_select__list {
    &.few_col {
      width: 230px;
      left: 0;
      transform: translateX(0);
    }
  }
}


@media screen and (max-width: 768px) {
	//.input_field{
	//	font-size: 18px;
	//}

	//.label_field{
   // .field_title {
	//		padding: 0 5px 4px 0;
	//	}
	//}

	.middle_field {
		.label_field {
			font-size: 14px;
		}
		.custom_select {
			font-size: 14px;
		}
	}

	.custom_select {
		&__list {
			&.two_col{
				padding: 5px;
			}
		}
		&.with_title{
			.selected_val{
				font-size: 14px;
				padding: 10px 28px 10px 0;
        letter-spacing: -.5px;
        line-height: 1;
			}
			.custom_select__list{
				font-size: 14px;

				&.one_col {
					padding: 10px;
				}
			}
			.title{
				font-size: 14px;
				//margin: 10px 4px 11px 0;
        width: 100%;
        display: block;
        margin: 10px 0 0 0;
        position: relative;
        height: auto;
        line-height: 1;
			}
		}
	}

	.textarea_field{
		margin-bottom: 30px;
		&__title{
			font-size: 14px;
		}
		.custom_textarea{
			font-size: 14px;
			line-height: 1.4;
			height: 98px;
			&:focus{
				& +.textarea_field__title{
					transform: translateY(-90px);
				}
			}
		}
		&.focus{
			.textarea_field__title{
				transform: translateY(-90px);
			}
		}
	}

	.few_fields {
		&.captcha{
			flex-wrap: wrap;
			justify-content: center;
			.label_field{
				width: 100%;
				margin-right: 0;
			}
			.input_field{
				text-align: left;
			}
		}
	}
}


@media screen and (max-width: 1023px){
	.space_between{
		flex-direction: column;
		align-items: center;
	}

	.close_popup,
	.close_white{
		top: 20px;
		right: 20px;
	}

	.social_network{
		margin-left: -14px;
	}
}
