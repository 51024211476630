.user_wallets{
	background: #fcfcfc;

	.btn{
		margin: 0 auto;
	}

	&__container{
		@include max-width-center(100%, 995px);
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}

	&__col{
		width: 48.7%;
	}
}

.user_wallets__item{
	width: 100%;
	padding: 25px;
	box-sizing: border-box;
	background: $liteColor;
	border-radius: 7px;
	box-shadow: 0 2px 15px 0 rgba(66,66,66,0.3);
	margin-bottom: 40px;
	&__title{
		position: relative;
		font-family: $fontMedium;
		font-size: 30px;
		line-height: 1.2;
		color: $darkColor;
		padding-bottom: 10px;
		&:after{
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	&.webmoney{
		.user_wallets__item__title{
			&:after{
				@include sprite($webmoney);
			}
		}
	}
	&.visa-master{
		.user_wallets__item__title{
			&:after{
				@include sprite($visa-master-wallet);
			}
		}
	}
	&.cash{
		.user_wallets__item__title{
			&:after{
				@include sprite($cash-money);
			}
		}
	}
	&.yandex{
		.user_wallets__item__title{
			&:after{
				@include sprite($yandex);
			}
		}
	}
	&.upc{
		.user_wallets__item__title{
			&:after{
				@include sprite($upc);
			}
		}
	}
	&.p24{
		.user_wallets__item__title{
			&:after{
				@include sprite($p24);
			}
		}
	}
	&.qiwi{
		.user_wallets__item__title{
			&:after{
				@include sprite($qiwi);
			}
		}
	}
	&.alfaclick{
		.user_wallets__item__title{
			&:after{
				@include sprite($alfaclick);
			}
		}
	}
	&.sber{
		.user_wallets__item__title{
			&:after{
				@include sprite($sber);
			}
		}
	}
	&.rsb{
		.user_wallets__item__title{
			&:after{
				@include sprite($rsb);
			}
		}
	}

	&__accounts{
		width: 87%;
		padding-bottom: 25px;
		li{
			position: relative;
			font-family: $fontMoneyField;
			font-size: 20px;
			line-height: 1.2;
			color: $darkMediumColor;
			margin-bottom: 10px;
			&:hover{
				.account_del{
					opacity: 1;
					transition: opacity 0.05s linear;
				}
			}
		}
		.account_currency{
			font-family: $fontSemiBold;
			font-size: 20px;
			line-height: 1.2;
			color: $darkColor;
			text-transform: uppercase;
			float: right;
			padding-right: 40px;
		}
		.account_del{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			@include sprite($close);
			cursor: pointer;
			opacity: 0;
			transition: opacity 0.05s linear;
		}
	}
	.account_currency_list{
		padding-bottom: 40px;
		span{
			font-family: $fontThin;
			font-size: 18px;
			line-height: 1.2;
			color: $darkMediumColor;
			text-transform: uppercase;
			padding-right: 5px;
		}
	}

	&__btn-group{
		text-align: right;

		.btn_custom{
			display: inline-block;
			font-size: 16px;
			font-family: $fontMedium;
			cursor: pointer;

			&:not(:last-child) {
				margin-right: 15px;
			}
			
			&.add{
				color: $blueColor;
			}
			&.del{
				color: $darkLight;
			}
		}
	}
}


@media screen and (max-width: 1024px){
	.user_wallets{
		&__container{
			padding-bottom: 15px;
		}
	}

	.user_wallets__item{
		padding: 15px 0;
		margin-bottom: 20px;

		&.webmoney{
			.user_wallets__item__title{
				&:after{
					@include sprite($webmoney-small);
				}
			}
		}
		&.visa-master{
			.user_wallets__item__title{
				&:after{
					@include sprite($visa-master-wallet-small);
				}
			}
		}
		&.cash{
			.user_wallets__item__title{
				&:after{
					@include sprite($cash-money-small);
				}
			}
		}
		&.yandex{
			.user_wallets__item__title{
				&:after{
					@include sprite($yandex-small);
				}
			}
		}
		&.upc{
			.user_wallets__item__title{
				&:after{
					@include sprite($upc-small);
				}
			}
		}
		&.p24{
			.user_wallets__item__title{
				&:after{
					@include sprite($p24-small);
				}
			}
		}
		&.qiwi{
			.user_wallets__item__title{
				&:after{
					@include sprite($qiwi-small);
				}
			}
		}
		&.alfaclick{
			.user_wallets__item__title{
				&:after{
					@include sprite($alfaclick-small);
				}
			}
		}
		&.sber{
			.user_wallets__item__title{
				&:after{
					@include sprite($sber-small);
				}
			}
		}
		&.rsb{
			.user_wallets__item__title{
				&:after{
					@include sprite($rsb-small);
				}
			}
		}

		&__title {
			font-size: 23px;
			margin: 0 15px;
		}

		&__accounts{
			width: 80%;
			padding-bottom: 15px;
			li{
				font-size: 15px;
				cursor: pointer;
				padding: 5px 15px;
				margin-bottom: 0;
				&:hover{
					.account_del{
						display: none;
					}
				}
				&.active{
					background: #efefef;
					.account_del{
						display: block;
					}
				}
			}
			.account_currency{
				font-size: 15px;
				padding-right: 0;
			}
			.account_del{
				opacity: 1;
				right: -12%;
				display: none;
			}
		}

		.account_currency_list{
			padding: 0 15px 25px 15px;
			span{
				font-size: 15px;
			}
		}

		&__btn-group{
			padding: 0 15px;

			.btn_custom{
				font-size: 14px;
			}
		}
	}
}



@media screen and (max-width: 768px){
	.user_wallets{

		&__container{
			flex-direction: column;
			align-items: center;
		}

		&__col{
			min-width: 280px;
			width: 55%;
		}
	}

	.user_wallets__item{

		&__title {
			font-size: 18px;
		}

		&__accounts{
			li{
				font-size: 12px;
			}
			.account_currency{
				font-size: 12px;
			}
		}

		.account_currency_list{
			span{
				font-size: 12px;
			}
		}

		&__btn-group{
			.btn_custom{
				font-size: 12px;
			}
		}
	}
}




