@font-face {
  font-family: "raleway_regular";
  src: url("../fonts/raleway_regular/raleway_regular.eot"); /* IE9*/
  src: url("../fonts/raleway_regular/raleway_regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../fonts/raleway_regular/raleway_regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
       url("../fonts/raleway_regular/raleway_regular.woff2") format("woff2"), /* chrome、firefox */
       url("../fonts/raleway_regular/raleway_regular.woff") format("woff"), /* chrome、firefox */
       url("../fonts/raleway_regular/raleway_regular.svg#raleway_regular") format("svg"); /* iOS 4.1- */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway_medium";
  src: url("../fonts/raleway_medium/raleway_medium.eot");
  src: url("../fonts/raleway_medium/raleway_medium.eot?#iefix")format("embedded-opentype"),
       url("../fonts/raleway_medium/raleway_medium.woff") format("woff"),
       url("../fonts/raleway_medium/raleway_medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "raleway_bold";
  src: url("../fonts/raleway_bold/raleway_bold.eot");
  src: url("../fonts/raleway_bold/raleway_bold.eot?#iefix")format("embedded-opentype"),
       url("../fonts/raleway_bold/raleway_bold.woff") format("woff"),
       url("../fonts/raleway_bold/raleway_bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "raleway_semibold";
  src: url("../fonts/raleway_semibold/raleway_semibold.eot");
  src: url("../fonts/raleway_semibold/raleway_semibold.eot?#iefix")format("embedded-opentype"),
       url("../fonts/raleway_semibold/raleway_semibold.woff") format("woff"),
       url("../fonts/raleway_semibold/raleway_semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'raleway_thin';
  src: url('../fonts/raleway_thin/raleway_thin.eot');
  src: url('../fonts/raleway_thin/raleway_thin.eot?#iefix') format('embedded-opentype'),
       url('../fonts/raleway_thin/raleway_thin.ttf') format('truetype'),
       url('../fonts/raleway_thin/raleway_thin.woff2') format('woff2'),
       url('../fonts/raleway_thin/raleway_thin.woff') format('woff'),
       url('../fonts/raleway_thin/raleway_thin.svg#raleway_thin') format('svg');
       font-weight: normal;
       font-style: normal;
}

@font-face {
  font-family: "raleway_light";
  src: url("../fonts/raleway_light/raleway_light.eot");
  src: url("../fonts/raleway_light/raleway_light.eot?#iefix")format("embedded-opentype"),
       url("../fonts/raleway_light/raleway_light.woff") format("woff"),
       url("../fonts/raleway_light/raleway_light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "whitney_book";
  src: url("../fonts/whitney_book/whitney_book.eot");
  src: url("../fonts/whitney_book/whitney_book.eot?#iefix")format("embedded-opentype"),
       url("../fonts/whitney_book/whitney_book.woff") format("woff"),
       url("../fonts/whitney_book/whitney_book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "whitney_semibold";
  src: url("../fonts/whitney_semibold/whitney_semibold.eot");
  src: url("../fonts/whitney_semibold/whitney_semibold.eot?#iefix")format("embedded-opentype"),
       url("../fonts/whitney_semibold/whitney_semibold.woff") format("woff"),
       url("../fonts/whitney_semibold/whitney_semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "avenir_roman";
  src: url("../fonts/avenir_roman/avenir_roman.eot");
  src: url("../fonts/avenir_roman/avenir_roman.eot?#iefix") format("embedded-opentype"),
       url("../fonts/avenir_roman/avenir_roman.ttf") format("truetype"),
       url("../fonts/avenir_roman/avenir_roman.woff2") format("woff2"),
       url("../fonts/avenir_roman/avenir_roman.woff") format("woff"),
       url("../fonts/avenir_roman/avenir_roman.svg#avenir_roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenir_medium";
  src: url("../fonts/avenir_medium/avenir_medium.eot");
  src: url("../fonts/avenir_medium/avenir_medium.eot?#iefix")format("embedded-opentype"),
       url("../fonts/avenir_medium/avenir_medium.woff") format("woff"),
       url("../fonts/avenir_medium/avenir_medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}