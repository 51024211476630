.slide1 {
  color: $liteColor;
  padding: 70px 0 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-image: url(../video/woman_cellphone.png);
  background-size: cover;
  background-repeat: no-repeat;

  .btns_box {
    display: none;
  }

  video {
    z-index: 0;
    //transform: translateX(-100%);
    opacity: 0;
    animation-name: video-animation;
    animation-timing-function: linear;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-delay: .1s;
  }

  @include opacityBg(100%, auto, 0, 724px);

  &:after {
    z-index: 0;
    transform: translateX(100%);
    animation-name: right-elem-anim;
    animation-timing-function: linear;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-delay: .2s;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #a4a0bb;
    opacity: .2;
    z-index: 2;
  }

  & > * {
    z-index: 2;
  }
}


.main_title {
  transform: translateX(-100%);
  animation-name: title-animation;
  animation-timing-function: linear;
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}

.container_tabs {
  transform: translateX(100%);
  animation-name: right-elem-anim2;
  animation-timing-function: linear;
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-delay: .2s;
}


@keyframes video-animation {
  from   {opacity: 0}
  to  {opacity: 1}
}

@keyframes right-elem-anim {
  from   {transform: translateX(100%);}
  to  {transform: translateX(0);}
}

@keyframes right-elem-anim2 {
  from   {transform: translateX(100%);}
  to  {transform: translateX(0);}
}


@keyframes title-animation {
  from   {transform: translateX(-100%);}
  to  {transform: translateX(0);}
}



.centering_container {
  display: flex;
  justify-content: flex-end;

  .main_title {
    br {
      display: none;
    }
  }
}

.main_title {
  //position: absolute;
  //top: 157px;
  //left: 76px;
  font-family: $fontThin;
  font-size: 94px;
  width: 45%;
  line-height: .87;
  max-width: calc(100% - 750px);
}

.container_tabs {
  //width: 100%;
  //max-width: 750px;
  width: 750px;
  padding-right: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .label_checkbox {
    margin-bottom: 10px;
  }

  .fields_container {
    margin-bottom: 30px;
  }
}

.action_tabs {
  align-self: flex-end;
  display: inline-block;
  position: relative;
  width: auto;
  font-family: $fontLight;
  color: #fefeff;
  font-size: 28px;
  margin-bottom: 46px;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    border-bottom: 1px solid rgba(227,234,239,.5);
    top: 100%;
    left: 0;
    width: 10000px;
  }

  &__item {
    padding-bottom: 5px;
    position: relative;
    display: inline-block;
    opacity: .4;
    cursor: pointer;
    transition: all .3s;

    &:not(:last-child) {
      margin-right: 25px;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      width: 0;
      position: absolute;
      top: 100%;
      left: 0;
      border-bottom: 2px solid #e3eaef;
      transition: width .4s;
     }

    &.active_tab {
      color: $liteColor;
      opacity: 1;

      &:after {
        width: 100%;
      }
    }
  }
}

.tab_content {
  display: none;
  width: 100% ;

  &.show_tab {
    display: block;
  }
}

.info_about_it {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__title {
    font-family: $fontMedium;
    font-size: 18px;
    display: block;
    padding-bottom: 10px;
  }

  &__text {
    font-family: $fontMoneyField;
    font-size: 20px;
    display: block;
  }
}

.info_container {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 34px;
  }
}

.less_width {
  max-width: 89%;
}

.container_card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;

  .flip-container {
    margin-right: 25px;
    margin-bottom: 0;
  }

  .fields_container {
    margin-bottom: 0;
    width: calc(100% - 295px);

    & > * {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: flex-end;

    .left_side {

      & > * {
        margin-bottom: 5px;

        &:last-child {
          margin: 10px 0 0 0;
        }
      }
    }

  }
}

// end SLIDE 1

// *************************** BENEFITS ********************
.benefits {
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  line-height: 1.7;
  color: $darkLight;
  @include max-width-center(100%, 1200px);

  &__item {
    width: 100/3 +%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__title {
    color: $darkColor;
    font-size: 24px;
    display: block;
    margin-bottom: 20px;
    padding-top: 65px;
    position: relative;
    line-height: 1.3;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 2px;
    }

    &.easy {
      &:before {
        @include sprite($easy-icon);
      }
    }

    &.fast {
      &:before {
        @include sprite($fast-icon);
      }
    }

    &.realible {
      &:before {
        @include sprite($realible-icon);
      }
    }
  }
}
// *************************** END BENEFITS ********************

// *************************** REVIEWS ********************
.main_reviews {
  position: relative;
  color: $liteColor;
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;

  & > * {
    @include max-width-center(100%, 1120px);
  }

  &:before {

  }

  @include imgBg('../images/bg/review-bg.png');

  @include opacityBg(100000px, -50000px, auto, none);

  .container_slider {
    padding-top: 65px;

    &__title {
      margin-top: -65px;
    }
  }

  .main__slider {
    margin-bottom: 55px;
  }
}

.container_slider {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;

  &__left {
    width: 70%;
    width: calc(100% - 215px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    overflow: hidden;
  }

  &__rigth {
    width: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 0;
    text-align: right;
  }

  &__title {
    text-align: right;
    font-family: $fontThin;
    font-size: 48px;
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & > * {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid rgba(255,255,255,.5);
      cursor: pointer;
      transition: all .3s;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        display: block;
        @include sprite($rectangle);
        transition: margin-top .1s;
      }

      &.prev_btn {
        transform: rotateZ(90deg);
      }

      &.next_btn {
        transform: rotateZ(-90deg);
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        border-color: rgba(255,255,255,1);

        &:before {
          margin-top: 3px ;
        }
      }
    }

    &.dark {
      & > * {
        border: 1px solid rgba($darkColor,.5);

        &:before {
          @include sprite($rectangle-dark);
        }

        &:hover {
          border-color: rgba($darkColor,1);
        }
      }
    }
  }
}

.main__slider {
  display: flex;
  align-items: flex-start;
  //width: 100%;

  &__item {
    width: 50%;

    & > * {
      padding-right: 20px;
      box-sizing: border-box;
    }


    //&:nth-child(even) {
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: flex-start;
    //  align-items: flex-end;
    //
    //  & > * {
    //    padding: 0 0 0 20px;
    //    justify-content: flex-end;
    //  }
    //}

    &.swiper-slide-next {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      & > * {
        padding: 0 0 0 20px;
        justify-content: flex-end;
      }
    }


  }
}

.title_slide {
  width: 100%;
  font-family: $fontMoneyField;
  display: flex;
  align-items: flex-end;
  margin-bottom: 45px;
  border-bottom: 1px solid rgba($liteColor, .5);
  color: rgba($liteColor, .5);

  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;

    & > *:not(:last-child) {
      margin-right: 13px;
    }
  }

  &__text {
    position: relative;
    font-family: $fontLight;
    font-size: 28px;
    padding-bottom: 7px;
    color: rgba($liteColor, 1);

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: rgba($liteColor, 1);;
    }
  }

  &__social {
    display: flex;
    align-items: center;

    &__item {
      &.fb {
        @include sprite($facebook-small-icon);
      }

      &.vk {
        @include sprite($vkontakte-small-icon);
      }
    }
  }
}

.content_slide {
  width: 100%;
  font-size: 18px;
  line-height: 1.2;
  display: flex;
  font-family: $fontLight;

  & > * {
    width: 100%;
    max-width: 400px;
    //padding: 0 10px;
  }
}
// *************************** END REVIEWS ********************

// *************************** NEWS ********************
.main_news {
  .col_bg {
    background-image: url(../images/bg/news-bg.png);
  }

  &__container {
    @include max-width-center(100%, 670px);
    margin: 0;
    padding: $verticalPd $gorisontalPd;
    box-sizing: border-box;
  }

  .title_big {
    margin-bottom: 55px;
  }
}

.news_little {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__title {
    font-family: $fontLight;
    border-bottom: 1px solid rgba($darkLight, .8);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 25px;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 100%;
      width: 10000px;
      border-bottom: 1px solid rgba($darkLight, .8);
    }

    &__text {
      color: $darkColor;
      font-size: 24px;
      position: relative;
      width: calc(100% - 185px);
      max-width: 350px;
      padding-bottom: 5px;
      line-height: 1.2;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border-bottom: 2px solid rgba($darkLight, 1);
      }
    }

    .box__date {
      margin-bottom: 9px;
    }
  }

  &__contant {
    line-height: 2;
    width: 100%;
    max-width: 440px;
  }
}
// *************************** END NEWS ********************

// *************************** BONUS SYSTEM ********************
.main_bonus_system {
  .col_bg {
    background-image: url(../images/bg/bonus-system-bg.png);
  }

  .col_content {
    justify-content: flex-end;
  }
}

.bonus_system_container {
  @include max-width-center(100%, 580px);
  margin: 0;
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;

  .title_small {
    margin-bottom: 25px;
  }
}

.title_bonus_system {
  color: $darkColor;
  font-size: 24px;
  margin-bottom: 25px;
}

.bs_content {
  line-height: 1.8;
}

.footnote {
  margin-top: 30px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    @include sprite($footnote);
    transform: translate(-42%, -18%);
    z-index: -1;
  }
}

// *************************** END BONUS SYSTEM ********************

// ***************************  QUESTION-ANSWER ********************
.question_answer {
  position: relative;
  color: $liteColor;
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;

  & > * {
    @include max-width-center(100%, 1120px);
  }

  @include imgBg('../images/bg/question-bg.png');

  @include opacityBg(100000px, -50000px, auto, none);

  .read_more {
    margin: 20px 0;
  }

  .title_slide {
    height: 91px;
  }
}
// *************************** END QUESTION-ANSWER ********************

// *************************** PARTNERS PROGRAM********************
.partners_program {
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;
  @include max-width-center(100%, $workSection);
  display: flex;
  justify-content: flex-end;

  .bonus_calc_btn {
    display: none;
  }

  .title_small {
    margin-bottom: 50px;
  }
}

.container_partners {
  display: flex;

  &__item {
    line-height: 1.7;
    color: $darkLight;
    padding-right: 30px;
    box-sizing: border-box;
    width: 50%;
  }
  
  &__title {
    font-size: 28px;
    color: $darkColor;
    line-height: 1.2;
    padding-top: 75px;
    position: relative;
    display: block;
    margin-bottom: 10px;
    padding-right: 30px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }

    &.invite {
      &:before {
        @include sprite($invite-icon);
      }
    }

    &.diagram {
      &:before {
        @include sprite($diagram-icon);
      }
    }
  }
}

.partners_container {
  @include max-width-center(100%, 1180px);
  display: flex;
  align-items: center;

  &__left {
    width: 100%;
    max-width: calc(100% - 430px);
  }
}

//.container_calculator {
//  .overlay {
//    display: none;
//  }
//}

.bonus_calculator {
  position: relative;
  width: 430px;
  padding: 45px 35px;
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 7px 7px 57px 0px rgba(66,66,66,0.3);
  background: #fff;

  .custom_select {
    margin-left: 10px;
    min-width: 95px;
  }

  .few_fields {
    & > *:not(:last-child) {
      margin-right: 0;
    }
  }

  .close_pseudo {
    display: none;
  }

  &__title {
    font-family: $fontLight;
    font-size: 30px;
    color: $darkMediumColor;
    margin-bottom: 30px;
  }

  .few_fields {
    .label_field {
      max-width: 230px;
    }

    .input_field {
      max-width: 180px;
    }
  }
}

.bonus {
  width: 100%;
  font-size: 21px;
  color: #000;
  font-family: $fontLight;
  margin: 10px 0 25px 0;
  
  &__title {
    color: $darkLight;
  }
  
  &_rules {
    color: $darkMediumColor;
  }

  & > * {
    display: block;
    text-align: right;
  }

  .bonus__money {
    font-size: 42px;
    color: $blueColor;
    font-family: $fontNumberSemibold;
  }

  .currency {
    font-family: $fontBold;
  }
}

.bonus_rules {
  &__title {
    font-family: $fontBold;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 10px;
    display: block;
  }
}

.bonus_rate {
  font-family: $fontMoneyField;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.exchange_info{
	padding-top: 25px;
	font-family: $fontLight;
	font-size: 10px;
	line-height: 1.3;
	&__container{

	}
	&__txt{
		position: relative;
		padding-left: 25px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		max-height: 39px;
		-webkit-line-clamp: 3;
		word-wrap: break-word;
		&:before{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			@include sprite($i);
		}
	}
	.read_more{
		padding-left: 25px;
		span{
			cursor: pointer;
		}
	}
}


// *************************** END PARTNERS PROGRAM********************

@media screen and (max-width: 1280px) {
  .benefits,
  .main_reviews,
  .main_news__container,
  .bonus_system_container,
  .question_answer,
  .partners_program {
    padding: $verticalPdTab $gorisontalPdTab;
    max-width: none;
  }

  .main_reviews > *,
  .question_answer > * {
    max-width: none;
  }

  .slide1 {
    align-items: stretch;
  }

  .centering_container {
    align-items: center;

    .main_title {
      align-self: flex-start;
      font-size: 58px;
      padding-left: $gorisontalPdTab;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1023px) {
  .benefits,
  .main_reviews,
  .main_news__container,
  .bonus_system_container,
  .question_answer,
  .partners_program {
    padding: $verticalPdMob $gorisontalPdMob;
    max-width: none;
  }

  .centering_container {
    .main_title {
      padding-left: $gorisontalPdMob;

      br {
        display: block;
      }
    }
  }

  .question_answer .title_slide {
    height: 105px;
  }

  .bonus_calculator {
    width: 280px;
    height: auto;
    padding: 30px 25px;

    .custom_select {
      margin-left: 10px;
      min-width: 95px;
    }

    .label_field {
      width: 125px;
      //display: block;
      margin-right: 0 !important;

      //span {
      //
      //}
      //
      //input {
      //  width: 100%;
      //}
    }

    &__title {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .bonus {
      margin-top: 0;
      font-size: 18px;

      &__money {
        font-size: 24px;
      }
    }

    .bonus_rules {
      &__title {
        font-size: 14px;
      }
    }

    .bonus_rate {
      font-size: 9px;
    }
  }

  //******************** slide1 **********
  .slide1 {
    padding-bottom: 160px;
    box-sizing: border-box;

    &:after {
      max-width: none;
    }

    &:before {
      display: none;
    }

    video {
      display: none;
    }

    .btns_box {
      display: block;
      position: absolute;
      left: 0;
      bottom: 30px;
      padding: 0 20px;
      box-sizing: border-box;
      width: 100%;

      & > * {
        width: 280px;
        margin: 0 auto;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    //.container_tabs {
    //
    //  padding: 0;
    //  min-height: 0px;
    //  max-width: none;
    //  padding-top: 65px;
    //  box-sizing: border-box;
    //  height: 100%;
    //  overflow: hidden;
    //
    //  .action_tabs {
    //    display: none;
    //  }
    //
    //  .overlay_blue {
    //    background: red;
    //  }
    //
    //  .tab_content {
    //    height: 100%;
    //    overflow: hidden;
    //    overflow-y: auto;
    //    padding: 0 $gorisontalPdMob;
    //    box-sizing: border-box;
    //
    //    .few_fields {
    //      flex-wrap: wrap;
    //
    //      & > *:first-child {
    //        width: 100%;
    //        margin-right: 0;
    //      }
    //    }
    //  }
    //
    //  .less_width {
    //    max-width: none;
    //  }
    //}

    .container_tabs {
      display: none;
    }

    .main_title {
      width: 100%;
      max-width: none;
      align-self: center;
      padding: 0 20px;
      text-align: center;
    }

    .centering_container {
      width: 100%;
      justify-content: flex-start;
    }
  }
  //******************** end slide1 **********


  //******************** benefits **********
  .benefits {
    flex-direction: column;
    align-items: center;

    &__item {
      width: auto;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    
    &__title {
      text-align: center;

      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  //******************** end benefits **********

  //******************** main_news **********
  .main_news {
    &__left {
      display: none;
    }

    &__right {
      width: 100%;
    }

    .btn {
      margin: 0 auto;
    }

    .title_big {
      margin-bottom: $verticalPdMob;
    }
  }
  //******************** end main_news **********

  //******************** news_little **********
  .news_little {
    &__contant {
      max-width: none;
    }

    &__title {
      margin-bottom: 35px;
      position: relative;

      &__text {
        width: 100%;
      }

      .box__date {
        padding-left: 20px;
        margin-top: 7px;
        margin-bottom: 0;
        position: absolute;
        top: 100%;

        &:before {
          left: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  //******************** end news_little **********

  //******************** main_bonus_system **********
  .main_bonus_system {
    position: relative;
    flex-direction: column-reverse;

    .col_content {
      width: 100%;
    }

    .col_bg {
      width: 100%;
      height: 163px;
      background-position: 0 100%;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, .2);
      }
    }

    .title_small {
      position: absolute;
      top: $verticalPdMob;
      left: $gorisontalPdMob;
      color: $liteColor;
      z-index: 1;
    }
  }
  //******************** end main_bonus_system **********

  //******************** partners_program **********
  .partners_program {

    .bonus_calc_btn {
      display: flex;
      margin: 0 auto;
      margin-top: 35px;
    }

    .partners_container__left {
      max-width: none;
    }

    .bonus_calculator {
      display: none;
    }

    //.container_calculator {
    //  //display: none;
    //  position: fixed;
    //  top: 0;
    //  left: 0;
    //  z-index: 15;
    //  width: 100%;
    //  height: 100%;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //}

    .title_small {
      margin-bottom: 25px;
    }

    .container_partners {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &__item {
        width: auto;
        padding: 0;
        max-width: none;
        width: 100%;
        padding-right: 0;
        padding-left: 0;

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      &__title {
        &:before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  //[data-pseudo-popup] {
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  z-index: 15;
  //  width: 100%;
  //  height: 100%;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  opacity: 0;
  //  visibility: hidden;
  //  transition: opacity 0ms linear 700ms, visibility 0s linear 700ms;
  //
  //  .bonus_calculator, .show_tab {
  //    transform: translateY(200%);
  //    transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms;
  //  }
  //
  //  .overlay {
  //    display: block;
  //    z-index: -1;
  //    opacity: 0;
  //    visibility: hidden;
  //    transition: opacity 700ms linear 0ms, visibility 0s linear 700ms;
  //  }
  //
  //  &.show_pseudo {
  //    display: flex;
  //    opacity: 1;
  //    visibility: visible;
  //    transition: opacity 0ms linear 0s, visibility 0s linear 0s;
  //
  //    .overlay {
  //      opacity: 1;
  //      visibility: visible;
  //      transition: opacity 700ms linear 0ms, visibility 0s linear 0ms;
  //    }
  //
  //    .bonus_calculator, .show_tab {
  //      transform: translateY(0);
  //      transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms;
  //    }
  //  }
  //}
  //******************** end partners_program **********

  //******************** main_reviews **********
  .main_reviews {
    .container_slider {
      padding-top: 0;

      &__title {
        margin-top: 0;
      }
    }
  }
  //******************** end main_reviews **********

  //******************** container_slider **********
  .container_slider {
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: 70px;
    box-sizing: border-box;

    .btn {
      display: none;
    }

    .round_btn {
      display: flex;
    }

    & > * {
      width: 100%;
      align-items: flex-start;
    }

    &__title {
      text-align: left;
      font-size: 45px;
      line-height: 1.5;
    }

    .container_slider__btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      justify-content: center;
    }

    .content_slide {
      & > * {
        max-width: none;
      }
    }

    &__left {
      & > *:not(.btn) {
        width: 100%;
      }
    }

    &__rigth {
      margin-bottom: 40px;

      .box {
        width: 100%;
        position: relative;
      }
    }

    .title_slide {
      position: relative;

      & > * {
        & > *:not(:last-child) {
          margin-right: 0;
        }
      }

      .box__date {
        padding-left: 20px;
        margin-top: 7px;
        margin-bottom: 0;
        position: absolute;
        top: 100%;

        &:before {
          left: 0;
        }
      }

      &__social {
        //padding-right: 20px;
        margin-top: 7px;
        margin-bottom: 0;
        position: absolute;
        top: 100%;
        right: 0;
      }

      &__text {
        font-size: 24px;
      }
    }
  }

  .main__slider__item {
    width: 100%;
  }
  //******************** end container_slider **********
}
