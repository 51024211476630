body{
	.preloader{
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100px;
		height: 112px;
		margin-left: -50px;
		margin-top: -56px;
		display: block;
		.st0{display:none;}
		.st3,
		.st4,
		.st5{
			fill:none;
			stroke:#fff;
		}

		.st3{
			stroke-dasharray: 200;
			animation: step-1 3s linear infinite;
		}
		.st4{
			stroke-dasharray: 100%;
			animation: step-2 3s linear infinite;
		}
		.st5{
			stroke-dasharray: 108%;
			animation: step-3 3s linear infinite;
		}
	}

	&:not(.ie_app){
		.preloader{
			.st3{
				stroke-dashoffset: 200;
			}
			.st4{
				stroke-dashoffset: 100%;
			}
			.st5{
				stroke-dashoffset: 108%;
			}
		}

	}

	&.ie_app{
		.preloader{
			animation: ie_animation 1s linear infinite alternate;
			.st3,.st4,.st5{
				stroke-dashoffset: 0;
			}
		}
	}
}



.box_preloader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #5c9dff;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s linear;

	&.show_preloader{
		opacity: 1;
		visibility: visible;
		transition: opacity 0.3s linear;
	}
}

@keyframes step-1 {
	0% {
		stroke-dashoffset: 200;
	}
	75% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes step-2{
	0% {
		stroke-dashoffset: 100%;
	}
	75% {
		stroke-dashoffset: 0%;
	}
	100% {
		stroke-dashoffset: 0%;
	}
}

@keyframes step-3{
	0% {
		stroke-dashoffset: 108%;
	}
	75% {
		stroke-dashoffset: 0%;
	}
	100% {
		stroke-dashoffset: 0%;
	}
}


@keyframes ie_animation {
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}