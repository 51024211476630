.article_top {
  padding: 140px $gorisontalPd 75px $gorisontalPd;
  box-sizing: border-box;
  position: relative;

  @include opacityBg(100000px, -50000px, auto, none);

  .title_small {
    color: $liteColor;
    padding: 0 30px;
    box-sizing: border-box;
    text-align: right;
    @include max-width-center(100%, 1155px);
  }
}

.about_page {
  .article_top {
    @include imgBg('../images/bg/about-bg.png');
  }

  .title_article {
    display: flex;
    justify-content: flex-end;

    & > * {
      display: inline-block;
      width: 100%;
      max-width: 800px;
      text-align: right;
    }
  }
}

.agreement_page {
  .article_top {
    @include imgBg('../images/bg/agreement-bg.png');
  }
}

.article_content {
  @include max-width-center(100%, 1175px);
  padding: $verticalPd $gorisontalPd;
  box-sizing: border-box;
  line-height: 1.7;
  font-family: $fontMoneyField;

  p {
    min-height: 23px;
  }
}

.title_article {
  color: $darkColor;
  font-size: 24px;
  margin-bottom: 40px;
}

.sub_title_article {
  font-family: $fontNumberSemibold;
  font-size: 18px;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.agreement_btn {
  padding-top: 40px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1280px){
  .article_top {
    padding: 140px $gorisontalPdTab 75px $gorisontalPdTab;
  }

  .article_content {
    padding: $verticalPdTab $gorisontalPdTab;
  }
}

@media screen and (max-width: 1024px){
  .article_top {
    padding: 80px $gorisontalPdMob 20px $gorisontalPdMob;

    .title_small {
      text-align: center;
      padding: 0;
      font-size: 24px;
    }
  }

  .article_content {
    padding: $verticalPdMob $gorisontalPdMob;
    font-size: 12px;
  }

  .sub_title_article {
    font-size: 12px;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .about_page {
      .title_article {
        & > * {
          max-width: none;
          text-align: left;
          line-height: 1.3;
        }
      }
  }

  .title_article {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
    max-width: none;
    width: 100%
  }
}
