.popup_ticket {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 11;
	width: 100%;
	height: 100%;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0ms linear 700ms, visibility 0s linear 700ms;

	&.blue_popup {
		.overlay {
			background: rgba($blueColor, 0.8);
		}

		.popup_ticket__item {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: rgba($blueColor, .8);
			}

			& > *:not(.popup_ticket__item__del) {
				position: relative;
				z-index: 1;
			}
		}

		.popup_ticket__item__del {
			@include sprite($close-light-big);
		}
	}

	&.active_popup {
		opacity: 1;
		visibility: visible;
		transition: opacity 0ms linear 0s, visibility 0s linear 0s;

		.overlay {
			opacity: 1;
			visibility: visible;
			transition: opacity 700ms linear 0ms, visibility 0s linear 0ms;
		}

		.content_popup{
			transform: translateY(0);
			transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms;
		}
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(99, 99, 99, 0.3);
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: opacity 700ms linear 0ms, visibility 0s linear 700ms;
	}

	.content_popup{
		transform: translateY(200%);
		transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms;
		max-height: 100%;
		//background: $liteColor;
		box-sizing: border-box;
		border-radius: 7px;
		overflow: hidden;
		overflow-y: auto;
		box-shadow: 0 2px 15px 0 rgba(66,66,66,0.3);
	}

	&__item{
		width: 100%;
		box-sizing: border-box;
		position: relative;
		background: $liteColor;
		box-sizing: border-box;
		//border-radius: 7px;
		//box-shadow: 0 2px 15px 0 rgba(66,66,66,0.3);

		//_____________________________________________review, new_ticket
		&.review{
			.btn_inner{
				margin-top: 20px;
			}
		}

		&.review,
		&.new_ticket,
		&.read{
			width: 540px;
			padding: 55px;

			.textarea_field{
				margin-top: 50px;
			}
		}

		//_____________________________________________read
		&.read{
			font-family: $fontLight;
			font-size: 14px;
			line-height: 1.3;
			text-align: justify;
		}

		//_____________________________________________new_bill, new_wallet
		&.new_wallet{
			.btn_inner{
				margin-top: 30px;
			}
		}

		&.new_bill,
		&.new_wallet{
			width: 430px;
			padding: 50px 40px;

			.custom_select{
				width: 100%;
				color: rgba($darkColor,.8);
				.selected_val{
					text-align: left;
					padding: 10px 28px 10px 0;
				}
			}
		}

		&__title{
			font-family: $fontLight;
			font-size: 36px;
			line-height: 1.2;
			color: $blueColor;
			padding-bottom: 30px;
		}

		&__del{
			position: absolute;
			top: 30px;
			right: 30px;
			@include sprite($close-large);
			cursor: pointer;
			z-index: 1;
		}
	}

	.btn_inner{
		text-align: right;
		.btn{
			display: inline-block;
		}
	}
}

//_____________________________________________animation for popup

#main{
	&.active_pop_up{
		transform: translateY(-60px) scale(.9);
		transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms;
	}
}

.tabs_popup {
	padding: 55px 40px 40px 40px;
	box-sizing: border-box;
	max-width: 506px;
	color: $liteColor;

	.tabs__title {
		text-align: center;
		font-size: 20px;
		font-family: $fontLight;
		color: $liteColor;
		margin-bottom: 20px;
	}

	.tab_content {
		& > *:not(:last-child) {
			margin-bottom: 35px;
		}
	}

	.checkbox_container {
		& + .text_explain {
			padding: 5px 0 0 24px;
		}
	}


	.few_fields {
		flex-wrap: wrap;
		margin-bottom: 0;

		& > *:first-child {
			width: 100%;
			margin-right: 0;
		}
	}

	.info_about_it {
		flex-wrap: wrap;
		font-size: 14px;

		&__title, &__text {
			font-size: 14px;
		}
	}

	.info_container {
		width: 100%;
		justify-content: space-between;
	}

	//.info_about_it__title

	.btn {
		margin: 0 auto;
		margin-top: 30px;
	}

	.container_card {
		flex-direction: column;

		& > * {
			width: 100%;
			margin: 0;

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		.flip-container {
			.credit_card {
				margin: 0 auto;
			}
		}
	}

	.container_card__info {
		justify-content: flex-start;
	}

	//.container_card .flip-container
}

.notification_popup {
	padding: 60px 40px 50px 40px;
	box-sizing: border-box;
	max-width: 429px;
	width: 100%;
	line-height: 1.5;

	.attention {
		&:before{
			top: 4px;
		}
	}
}

//_____________________________________________notification
.notification_box{
	display: block;
	position: fixed;
	right: 15px;
	bottom: 15px;
	text-align: center;
	z-index: 3;
	width: 295px;
}

.notification{
	display: none;
	width: 100%;
	background: #5c9dff;
	border-radius: 5px;
	position: relative;
	margin-bottom: 7px;
	box-shadow: 0 2px 15px 0 rgba(66,66,66,0.3);
	&.active{
		display: block;
	}
	&__img{
		width: 65px;
		height: 100%;
		background: $blueColor;
		position: absolute;
		top: 0;
		left: 0;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		&:after{
			display: block;
			content: '';
			@include sprite($bell);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__info{
		padding: 15px 20px 15px 85px;
		color: $liteColor;
		line-height: 1.2;
		text-align: left;

		&__title{
			font-family: $fontSemiBold;
			font-size: 11px;
			padding-bottom: 5px;
		}

		&__price{
			font-family: $fontNumberSemibold;
			font-size: 12px;
		}

		&__currency{
			font-family: $fontBasic;
			font-size: 12px;
			text-transform: uppercase;
		}

		&__img{
			display: inline-block;
			@include sprite($arrow-light-small);
		}
	}

	&__close{
		position: absolute;
		top: 7px;
		right: 7px;
		@include sprite($close-light-small);
		cursor: pointer;
	}
}

@media screen and (max-width: 768px) {
	.popup_ticket {
		bottom: 0;
		overflow: auto;

		//&[data-pop_up = 'ticket']{
			//&.active_popup{
			//	align-items: flex-start;
			//}
			//.content_popup{
			//	margin: 15px 0;
			//}
		//}
    //
    //.input_field{
			//width: 75%;
		//}

		&__item{
			//_____________________________________________new_ticket
			&.new_ticket{
				.custom_select {
					&.with_title{
						//.selected_val{
						//	padding: 10px 28px 10px 60px;
						//}
						.custom_select__list{
							font-size: 14px;
						}
					}
				}
				.textarea_field{
					margin-top: 40px;
					.custom_textarea{
						height: 58.8px;
						&:focus{
							& +.textarea_field__title{
								transform: translateY(-50px);
							}
						}
					}
					&.focus{
						.textarea_field__title{
							transform: translateY(-50px);
						}
					}
				}
			}

			&.review,
			&.new_ticket{
				width: 280px;
				padding: 40px 30px;
			}

			//_____________________________________________new_wallet
			&.new_wallet{
				width: 290px;
				.custom_select__list{
					font-size: 12px;
					&.two_col{
						padding: 5px 0;
						& > * {
							padding: 8px 5px;
						}
					}
				}
				.btn_inner {
					margin-top: 40px;
				}
			}

			//_____________________________________________new_bill
			&.new_bill{
				width: 280px;
			}

			&.new_bill,
			&.new_wallet{
				padding: 40px 30px;
			}

			&__title{
				font-size: 24px;
			}

			&__del{
				top: 20px;
				right: 20px;
			}
		}

		.btn_inner{
			text-align: center;
		}
	}
}

@media screen and (max-width: 500px) {
	.tabs_popup {
		padding: 55px 20px 20px 20px;
	}
}
//
//@media only screen and (max-width: 768px) and (orientation: landscape) {
//	.popup_ticket {
//		&.active_popup{
//			align-items: flex-start;
//		}
//		.content_popup{
//			margin: 15px 0;
//		}
//	}
//}