.exchange_rates_top {
  @include imgBg('../images/bg/exchange-rates-bg.png');
  @include opacityBg(100000px, auto, 0, 53%);
  position: relative;
  padding: 120px 0 60px 0;
  color: $liteColor;

  &:after {
    min-width: 723px;
  }

  .work_section {
    @include max-width-center(100%, $workSection);
    padding: 0 $gorisontalPd;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .title_small {
    font-size: 60px;
    color: $liteColor;
    margin-bottom: 45px;
  }

  .few_fields {
    margin-bottom: 25px;
  }

  .custom_select {
    width: 110px;
  }

  .box {
    & > *:not(:last-child) {
      margin-right: 40px;
    }
  }

  .delimiter {
    align-self: center;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.title_filters_rate {
  font-family: $fontMedium;
  font-size: 21px;
  display: block;
  margin-bottom: 15px;
}

.exchange_rates_content {
  @include max-width-center(100%, 880px);
  padding: $verticalPd $gorisontalPd;
}

.container_currency {
  &:not(:last-child) {
    margin-bottom: 50px;
  }
}

.title_currency {
  //margin: 0 0 30px 15px;
  margin: 0 0 30px 0;
  position: relative;
  display: inline-block;
  border-bottom: 2px solid $blueColor;
  color: $blueColor;
  font-size: 30px;
  font-family: $fontMedium;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    width: 100000000px;
    background-color: rgba($blueColor,.5);
  }

  &:before {
    left: 50%;
    transform: translateX(-50%);
  }
}

.delimiter {
  width: 55px;

  &_arrow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      display: block;
      @include sprite($arrow-dark);
    }

    &.arrow_light {
      &:before {
        @include sprite($arrow-light);
      }
    }
  }
}

.currencies_list__item {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.2;

  & > * {
    padding: 8px 15px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
    //box-sizing: border-box;
  }
}

.currency_from {
  width: 135px;
  //padding: 0 30px 0 0;
}

.currency_to {
  width: 215px;
}

.currency_rate {
  width: 215px;
  color: $blueColor;
  font-family: $fontMoneyField;
}

@media screen and (max-width: 1280px){
  .exchange_rates_top {
    .work_section {
      padding: 0 $gorisontalPdTab;
    }

    .left_col {
      max-width: 203px;
      margin: 0 auto;

      .btn {
        margin: 0 auto;
      }
    }
  }

  .exchange_rates_content {
    padding: $verticalPdTab $gorisontalPdTab;
  }
}


@media screen and (max-width: 1024px){
  .exchange_rates_top {
    padding: 100px 0 40px 0;
    &:after {
      max-width: 100%;
    }

    .work_section {
      padding: 0 $gorisontalPdMob;
    }

    .left_col {
      display: none;
    }

    .right_col {
      margin: 0 auto;
    }

    .title_filters_rate {
      font-size: 16px;
    }

    .ratesFilters {
      max-width: 280px;

      .few_fields {
        flex-wrap: wrap;
        position: relative;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%;

        & > * {
          margin-right: 0;
          display: flex;
        }

        .box {
          margin-bottom: 0;
        }
      }

      .delimiter {
        width: auto;
        position: absolute;
        top: 50%;
        right: -8px;
        transform: rotateZ(90deg);
        margin: 0;
      }

      .flex-end {
        justify-content: center;
      }
    }
  }

  .exchange_rates_content {
    padding: $verticalPdMob $gorisontalPdMob;
    max-width: 320px;
    box-sizing: border-box;

    .delimiter {
      display: none;
      width: auto;

      &_arrow {
        display: block;
      }
    }
  }

  .currencies_list__item {
    flex-wrap: wrap;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > * {
      padding: 5px 7px;
    }

    .currency_from {
      width: 82px;
      box-sizing: border-box;
    }

    .currency_to {
      width: 156px;
      box-sizing: border-box;
      padding-right: 0;
    }

    .currency_rate {
      padding-left: 0;
    }
  }

  .title_currency {
    margin-bottom: 20px;
  }
}