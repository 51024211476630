@keyframes showAuth {
  from {transform: translateX(100%); background: rgba($liteColor, 0);}
  to   {transform: translateX(0); background: rgba($liteColor, 1);}
}

@keyframes showAuthForm {
  from {transform: translateX(80%);}
  to   {transform: translateX(0);}
}


@keyframes hideAuth {
  from {transform: translateX(0); background: rgba($liteColor, 1);}
  to   {transform: translateX(100%); background: rgba($liteColor, 0);}
}

.popup_auth {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  display: none;

  .btn {
    text-transform: uppercase;
    font-size: 12px;
  }

  &.active_popup {
    display: flex;

    .content_popup {
      animation: showAuth .3s;


      .active_form  {
        animation: showAuthForm .8s;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($blueColor, .41);
    z-index: -1;
  }

  .content_popup {
    height: 100%;
    overflow: hidden;
    width: 50%;
    min-width: 723px;
    background: rgba($liteColor, 1);
    transition: all .5s;
    color: $liteColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: hideAuth .3s;
    animation-fill-mode: forwards;/* or: backwards, both, none */
    padding: 65px 0 $verticalPd 0;
    box-sizing: border-box;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($blueColor, .8);
		  bottom: 0;
    }

    & > * {
      position: relative;
      z-index: 1;
      @include max-width-center(100%, 430px);
      height: 100%;
      overflow-y: auto;
      padding: 0 $gorisontalPd;
    }
  }

  //.close_popup {
  //  position: absolute;
  //  top: 35px;
  //  right: 35px;
  //  cursor: pointer;
  //  z-index: 1;
  //
  //  @include sprite($close-light-big);
  //}

  .checkbox_container {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .agree {
    font-family: $fontSemiBold;
    padding-left: 5px;
  }
}

.title_auth {
  font-family: $fontLight;
  font-size: 30px;
  margin-bottom: 30px;
}

.forgot_pass {
  display: block;
  text-align: right;
  padding: 6px 0;
  margin-bottom: 20px;
  cursor: pointer;
}

.no-margin {
  margin-bottom: 0 !important;
}

.auth_from {
  margin: 70px 0 15px 0;
  font-size: 18px;
  font-family: $fontMedium;
  padding-left: 15px;
}

.social_auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;

  .social_auth__item {

    .social_icon {
      display: block;

      &.fb {
        @include sprite($fb-light);
      }

      &.vk {
        @include sprite($vk-light);
      }

      &.ok {
        @include sprite($ok-light);
      }

      &.google-plus {
        @include sprite($google-plus-light);
      }
    }
  }
}

.form_auth, .form_reg, .form_recovery {
  display: none;

  &.active_form {
    display: block;
    animation: showAuthForm .3s;
  }
}

.form_recovery {
  line-height: 1.3;

  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
}

.check_container {
  padding: 25px 0 50px 0;
}


@media screen and (max-width: 1280px){
	.popup_auth {
		.content_popup {
			padding: 65px 0 $verticalPdTab 0;
			box-sizing: border-box;

      & > * {
        padding: 0 $gorisontalPdTab;
      }
		}
	}
}

@media screen and (max-width: 1023px){
	.popup_auth {
		.btn {
			margin-bottom: 25px;
			&:last-child{
				margin-bottom: 0;
			}
		}

		.content_popup {
			width: 100%;
			min-width: 100%;
			padding: 65px 0 $verticalPdMob 0;
			//position: fixed;
			//top: 0;
			//bottom: 0;
			overflow-y: auto;
			overflow-x: hidden;

      & > * {
        padding: 0 $gorisontalPdMob;
      }
		}
	}

	.title_auth {
		font-size: 36px;
	}

	.auth_from {
		margin: 40px 0 15px 0;
		padding-left: 0;
	}

	.form_recovery {
		p{
			font-size: 12px;
		}
	}

	.check_container {
		padding: 10px 0 25px;
	}
}


@media only screen and (max-width: 1023px) and (orientation: landscape) {
	.popup_auth{
		.content_popup{
			align-items: flex-start;
		}
	}
}


@media only screen and (max-width: 1023px) and (orientation: portrait) {
	.popup_auth{
		.form_reg {
			#reg_confirm_pass{
				width: 50%;
			}
		}
	}
}
