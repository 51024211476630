.user_partners_program {
  @include max-width-center(100%, 1200px);
  box-sizing: border-box;

  .container_partners__title {
    font-size: 24px;
  }

  & > *:not(:last-child) {
    margin-bottom: 50px;
  }
}

.box_program {
  display: flex;
}

.title_programm {
  display: block;
  font-size: 30px;
  color: $darkColor;
  font-family: $fontMedium;
  margin-bottom: 20px;
}

.box_program__right {
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 24px;

  .number {
    font-size: 36px;
    color: $blueColor;
  }

  & > *:not(:last-child) {
    margin-bottom: 60px;
  }
}


.bonus_links {
  //display: flex;

  & > * {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    //&:first-child {
    //  padding-right: 15px;
    //}

    &:last-child {
      //padding-left: 15px;

      .bonus_links__title {
        &:before {
          content: '2';
        }
      }
    }

    & > * {
      padding-left: 80px;
    }
  }

  &__title {
    position: relative;
    line-height: 1.5;
    margin-bottom: 50px;

    &:before {
      content: '1';
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      font-family: $fontMoneyField;
      color: $blueColor;
      position: absolute;
      top: 50%;
      left: 0;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transform: translateY(-50%);
      border: 1px solid rgba($blueColor, 1);
    }
  }

  //.input_field {
  //  width: calc(100% - 210px);
  //}
}

.will_show_banner {
  font-family: $fontMedium;
  color: $blueColor;
  font-size: 18px;
  letter-spacing: -1.6px;
  align-self: flex-end;
  padding: 5px 10px 0 0;
  box-sizing: border-box;
  cursor: pointer;
}

.banners_container {
  .title_programm {
    text-align: center;
    margin-bottom: 30px;
  }

  [data-tab] {
    display: none;

    &.show_tab {
      display: block;
    }
  }
}

.banner_tab_list {
  display: flex;
  justify-content: space-between;
  font-family: $fontMoneyField;
  font-size: 18px;
  color: rgba($blueColor, .8);
  @include max-width-center(100%, 400px);
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    width: 999999999px;
    background: rgba($blueColor, .8);
    margin-top: -2px;
  }

  &:before {
    right: 0;
  }

  &:after {
    left: 100%;
  }
}

.banner_tab_item {
  padding: 0 10px;

  & > * {
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 0;
  }

  &.active_tab {
    color: rgba($blueColor, 1);

    & > * {
      border-bottom: 2px solid rgba($blueColor, 1);
    }
  }
}

.banner_tab {
  @include max-width-center(100%, 670px);
  padding: 50px 0;

  .custom_select {
    min-width: 150px;
  }

  .few_fields {
    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
}

.title_banner_exchange {
  font-family: $fontMedium;
  font-size: 18px;
  padding: 10px 10px 10px 0;
}

@media screen and (max-width: 1023px){
  .box_program {
    justify-content: center;

    .bonus_calculator {
      display: none;
    }
  }

  .title_programm {
    font-size: 22px;
  }

  .box_program__right {
    font-size: 18px;
    padding-left: 0;
    width: 100%;

    & > *:not(:last-child) {
      margin-bottom: 30px;
    }

    .number {
      font-size: inherit;
    }
  }

  .user_partners_program {
    max-width: 320px;
    box-sizing: border-box;

    .space_between {
      flex-wrap: wrap;
      justify-content: center;

      .will_show_banner {
        width: 100%;
        text-align: center;
        margin-bottom: 35px;
        padding: 0;
      }
    }

    .label_field {
      font-size: 14px;

      //.input_field {
      //  width: calc(100% - 150px);
      //}
    }

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }

    .container_partners__title {
     font-size: 14px;
    }
  }

  .container_partners {
    justify-content: space-between;

    &__item {
      padding: 0;
      box-sizing: border-box;
      max-width: 50%;
      width: auto;

      .container_partners__title {
        padding-right: 0;
        letter-spacing: -.5px;
      }

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }

  .bonus_links {
    flex-wrap: wrap;

    &__title {
      margin-bottom: 30px;

      &:before {
        width: 35px;
        height: 35px;
        font-size: 24px;
      }
    }

    & > * {
      width: 100%;
      padding: 0;

      &:not(:last-child){
        margin-bottom: 35px;
      }

      &:last-child {
        padding: 0;
      }

      & > * {
        padding-left: 45px;
      }

      & > form {
        padding-left: 0;
      }
    }
  }

  .banners_container {
    padding-top: 10px;

    .title_programm {
      margin-bottom: 10px;
    }

    .few_fields {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      & > *:not(.custom_select) {
        margin-bottom: 20px;
      }

      .delimiter {
        width: auto;
        margin: 0;
        position: absolute;
        bottom: 42px;
        right: 0;
        transform: rotateZ(90deg);
      }

      & > *:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .banner_tab {
    padding: 20px 0;
  }
}