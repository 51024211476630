.table_profile {
  width: 100%;
  //@include max-width-center(100%, 1000px);
  font-family: $fontMoneyField;
  font-size: 18px;

  td, th {
    padding: 15px;
    box-sizing: border-box;
    width: 20%;
    text-align: center;
  }

  th {
    font-size: 17px;
    &:first-child {
      .box_cell {
        position: relative;

        &:before, &:after {
          content: '';
          position: absolute;
          top: 100%;
          width: 999999999px;
          height: 1px;
          background: rgba($blueColor, .8);
        }

        &:before {
          right: -  1px;
        }

        &:after {
          left: 100%;
        }
      }
    }

    & > * {
      padding-bottom: 5px;
    }
  }
}

.cell {
  width: 100%;

  &_title {
    display: none;
    color: $blueColor;
  }
}

.box_cell {
  display: flex;
  justify-content: center;
  letter-spacing: -1.1px;
}

.table_row_title {
  position: relative;
}

.table_title {
  font-family: $fontMedium;
  font-size: 17px;
  color: $blueColor;
}

.user_promocodes, .user_history {
  .table_profile {
    td, th {
      text-align: left;
    }
  }

  .box_cell {
    justify-content: flex-start;
  }
}

.arrow_filter {
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;

  & > * {
    display: block;
    @include sprite($arrow-filter);

    &.arrow_filter__top {
      @include sprite($arrow-filter);
      transform: rotate(180deg) translateX(2px);
    }
  }


  &__top {
    transform: rotateZ(180deg);
  }
}

.descending {
  .arrow_filter {
    & > * {
      @include sprite($arrow-filter-blue);

      &.arrow_filter__top {
        @include sprite($arrow-filter);
      }
    }
  }
}

.ascending {
  .arrow_filter {
    & > * {
      @include sprite($arrow-filter);

      &.arrow_filter__top {
        @include sprite($arrow-filter-blue);
      }
    }
  }
}

.user_feedback, .user_promocodes, .user_history {
  @include max-width-center(100%, 1100px);
  box-sizing: border-box;
}

.profile_page {
  td {
    font-family: $fontMoneyField;
    font-size: 17px;
    letter-spacing: -.8px;
  }
}

@media screen and (max-width: 1023px){
  .table_profile {
    margin: 0 auto;
    max-width: 280px;
    width: 100%;
    display: block;

    tbody, tr, th, td {
      display: block;
      width: 100%;
      padding: 3px 0;
    }

    tr {
      padding: 10px 0;

      &:not(:last-child) {
        //border-bottom: 1px solid rgba($darkLight, .8);
        position: relative;

        &:before {
          content: '';
          width: 10000px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1px solid rgba($darkLight, .8);
        }
      }

      td:first-child {
        font-size: 18px;
        padding-bottom: 10px;

        .cell_title {
          font-size: 18px;
        }
      }

      td {
        font-size: 14px;
      }
    }

    .table_row_title {
      display: none;
    }

    .cell {
      display: flex;
      justify-content: space-between;

      &_title {
        display: inline-block;
      }
    }
  }
}