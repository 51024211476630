.faq_page {
  .article_top {
    @include imgBg('../images/bg/question-bg.png');
  }

  .article_content {
    @include max-width-center(100%, 1180px);
    //padding: 50px;
    box-sizing: border-box;
  }

  .container_news {
    overflow: hidden;

    .news_little__title__text {
      max-width: calc(100% - 200px);
      width: auto;
    }

    .news_little__contant {
      max-width: 100%;
    }
  }

  .read_more {
    display: inline-block;
    font-family: $fontMedium;
  }
}

@media screen and (max-width: 1024px) {
  .faq_page {
    .container_news {
      .news_little__title{
        margin-bottom: 10px;

        &__text {
          max-width: none;
        }
      }
    }
  }
}