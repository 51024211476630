$fontBasic: raleway_regular, Arial, sans-serif;
$fontMedium: raleway_medium, Arial, sans-serif;
$fontThin: raleway_thin, Arial, sans-serif;
$fontLight: raleway_light, Arial, sans-serif;
$fontBold: raleway_bold, Arial, sans-serif;
$fontSemiBold: raleway_semibold, Arial, sans-serif;
$fontMoneyField: whitney_book;
$fontNumberSemibold: whitney_semibold;
$fontCard: avenir_medium;
//$fontCardSmall: avenir_roman;
$fontCardSmall: avenir_medium;

$fontSizeBasic: 14px;

$liteColor: #fff;
$darkColor: #4b4b4b;
$darkMediumColor: #777;
$darkLight: #9e9e9e;
$blueColor: #3385ff;
$redColor: #ff0101;
$redLightColor: #fe8b8b;

$workSection: 1366px;

$gorisontalPd: 40px;
$gorisontalPdTab: 30px;
$gorisontalPdMob: 20px;
$verticalPd: 55px;
$verticalPdTab: 30px;
$verticalPdMob: 30px;
