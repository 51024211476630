@mixin max-width-center($width, $maxWidth) {
  width: $width;
  max-width: $maxWidth;
  margin: 0 auto;
}

@mixin cardFont($size) {
  font-family: $fontCard;
  font-size: $size;
  text-shadow: 0px 1px 1px rgba(81, 81, 81, 0.65);
}

@mixin boxShadow() {
  box-shadow: 7px 7px 57px 0px rgba(66,66,66,0.3);
}

//@mixin slidesAfter($width, $left, $rigth) {
//  content: '';
//  position: absolute;
//  top: 0;
//  right: $rigth;
//  left: $left;
//  width: $width;
//  height: 100%;
//  background-color: $blueColor;
//  opacity: 0.8;
//  z-index: -1;
//}

@mixin opacityBg($width, $left, $rigth, $maxWidth) {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: $rigth;
    left: $left;
    width: $width;
    height: 100%;
    background-color: $blueColor;
    opacity: 0.8;
    z-index: -1;
    max-width: $maxWidth;
  }
}

@mixin imgBg($bgImg) {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url($bgImg);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
