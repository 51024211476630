//http://webformyself.com/sozdajte-animaciyu-perevorota-v-css/
/* весь контейнер поддерживает перспективу */
.flip-container {
  perspective: 1000;
}

/* здесь находится скорость перевертывания */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

/* скройте обратную сторону во время переворота */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* лицевая сторона размещена над обратной */
.front {
  z-index: 2;
}

/* обратная, изначально скрытая сторона */
.back {
  transform: rotateY(180deg);
}

.flip-container.flip .flipper {
  transform: rotateY(180deg);
}

// custom Style


.flip-container {
  position: relative;
  //
  //&:before, &:after {
  //  content: '';
  //  position: absolute;
  //  top: -1px;
  //  left: 0;
  //  width: 100%;
  //  height: 50%;
  //  background: linear-gradient(to top right, #e4e0e1, #fff);
  //  z-index: -1;
  //  border-radius: 13px;
  //}
  //
  //&:after {
  //  top: auto;
  //  bottom: -1px;
  //  background: linear-gradient(to bottom right, #9e8c89, #a09598);
  //}
}

.credit_card {
  width: 268px;
  height: 168px;
  color: $liteColor;
  @include boxShadow();
  background: linear-gradient( -118deg, #d5d1d6, #bdb1b1 );
  border-radius: 13px;
  //overflow: hidden;
  position: relative;
  //z-index: 1;
  //border: 1.5px solid #e7e3e5;
  //border-top-color: #97898b;
  //border-bottom-color: #97898b;
  //border-left-color: #bbafb0;
  //border-right-color: #bbafb0;
  


  & > * {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100500;
    border-left: 1.5px solid rgba(#b9adad, 1);
    border-right: 1.5px solid rgba(#b2b6c8, 1);
    border-radius: 13px;

    //display: flex;
    //overflow: hidden;

    &:before, &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(to top right, #e5e1e2, #fff);
      z-index: -1;
      border-radius: 13px;
    }

    &:after {
      top: auto;
      bottom: -1px;
      background: linear-gradient(to bottom right, #9d8b88, #a09598);
    }

    & > * {
      height: 100%;
      width: 100%;
      @include boxShadow();
      background: linear-gradient( -118deg, #d5d1d6, #bdb1b1 );
      border-radius: 13px;
      overflow: hidden;
    }
  }

  &__logo {
    position: relative;
    //margin-bottom: 30px;

    &:before {
      content: '';
      width: 75px;
      height: 21px;
      display: block;
      background-image: url(../images/logo-dark.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  &__nubmer {
    //font-size: 18px;
    //font-family: $fontCard;
    //margin-bottom: 5px;
    //text-shadow: 0px 1px 1px rgba(81, 81, 81, 0.65);
    @include cardFont(18px);
    display: flex;
    justify-content: space-between;
  }

  &__logoCard {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1px;


    &:before {
      content: '';
      display: block;
    }

    &.visa_logo {
      &:before {
        @include sprite($visa-logo);
      }
    }

    &.mastercard_logo {
      &:before {
        //@include sprite($mastercard-logo);
      }
    }
  }

  &__chip {
    position: relative;
    margin-bottom: 12px;

    &:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      @include sprite($chip);
    }
  }

  //&__bin {
  //  color: #8e8e8e;
  //  font-size: 6px;
  //  font-family: $fontCardSmall;
  //}

  &__expires {
    text-align: center;
    padding-top: 8px;
    margin-bottom: 5px;

    & > * {
      display: inline-block;
    }

    &__title {
      color: #8e8e8e;
      font-size: 6px;
      font-family: $fontCardSmall;
    }

    &__date {
      @include cardFont(12px);

      & > *:not(:last-child) {
        &:after {
          content: ' / ';
        }
      }
    }
  }

  //&__debit {
  //  color: #8e8e8e;
  //  font-size: 10px;
  //  font-family: $fontCardSmall;
  //  text-align: right;
  //}

  &__name {
    @include cardFont(12px);
    padding-top: 3px;
  }
}

.front {
  & > * {
    padding: 20px;
    position: relative;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 0;
      width: 269px;
      height: 143px;
      background: url(../images/bg/card-bg.png);
      background-repeat: no-repeat;
      //z-index: -1;
    }

    & > * {
      position: relative;
      z-index: 1;
    }
  }
}

.back {
  & > * {
    padding: 15px 0;
    box-sizing: border-box;
    @include cardFont(12px);
    color: #585556;

    & > *:not(.magnite) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .magnite {
    width: 100%;
    height: 30px;
    background: linear-gradient(61deg, #585556, #585556);
    margin-bottom: 8px;
  }

  .white_line {
    background: linear-gradient(79deg, #f3efec, #ffffff);
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    height: 26px;
    padding: 7px;
    box-sizing: border-box;
    text-shadow: none;

    .num {
      padding-right: 5px;
    }
  }

  .credit_card__nubmer {
    transform: rotateY(180deg);
    color: rgba(#fff, .5);
    background-clip: content-box;
    text-shadow: 0 1px 0 #bdb1b1, 0 0 0 #442424, 2px 0 0 #bdb1b1;
  }

  .credit_card__expires__date {
    transform: rotateY(180deg);
    color: rgba(#fff, .5);
    background-clip: content-box;
    text-shadow: 0 1px 0 #bdb1b1, 0 0 0 #442424, 2px 0 0 #bdb1b1 !important;
  }

  .card_footer {
    transform: rotateY(180deg);
    color: rgba(#fff, .5);
    background-clip: content-box;

    .credit_card__name {
      text-shadow: 0 1px 0 #bdb1b1, 0 0 0 #442424, 2px 0 0 #bdb1b1 !important;
    }
  }
}

.card_footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}