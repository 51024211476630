//_____________________________________footer
.footer{
	width: 100%;
	max-width: 1150px;
	margin: 0 auto;
	color: $liteColor;
	background-color: transparent;
	padding: 30px 50px 0 50px;
	box-sizing: border-box;
	transition: all .3s;

	//_________________________________footer-services
	.footer-services{
		padding-bottom: 60px;

		.logo{
			float: left;
			width: 190px;
			img{
				width: 100%;
				height: auto;
				vertical-align: bottom;
			}
		}

		.connection{
			float: right;
			width: 210px;
			text-align: center;
			.btn{
				margin: 0 auto 25px;
			}
		}

		.social{
			font-size: 0;
			li{
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px;
				&:hover{
					a{
						&.facebook{
							&:after{
								@include sprite($fb-blue);
							}
						}
						&.vk{
							&:after{
								@include sprite($vk-blue);
							}
						}
						&.ok{
							&:after{
								@include sprite($ok-orange);
							}
						}
						&.google{
							&:after{
								@include sprite($google-plus-red);
							}
						}
					}
				}
			}
			a{
				display: block;
				position: relative;
				width: 42px;
				height: 42px;
				background: #a5a5a5;
				border-radius: 50%;

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				&.facebook{
					&:after{
						@include sprite($fb-light);
					}
				}
				&.vk{
					&:after{
						@include sprite($vk-light);
					}
				}
				&.ok{
					&:after{
						@include sprite($ok-light);
					}
				}
				&.google{
					&:after{
						@include sprite($google-plus-light);
					}
				}
			}
		}

		.menu{
			margin: 0 210px 0 190px;
			padding: 0 10px;
			box-sizing: border-box;

			.menu_inner{
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: flex-start;
			}

			ul{
				max-width: 50%;
				box-sizing: border-box;
				&:first-child{
					padding-right: 27px;
				}
				&:last-child{
					padding-left: 27px;
				}
			}

			li {
				padding-bottom: 5px;
				font-family: $fontThin;
				cursor: pointer;
				&:nth-child(1),
				&:nth-child(2) {
					padding-bottom: 10px;
					font-family: $fontMedium;
				}
			}
			a, span{
				font-size: 14px;
				cursor: pointer;
				color: $liteColor;
				text-decoration: none;
				line-height: 1.3;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}

	//_________________________________footer-payment
	.footer-payment{
		font-size: 0;
		background-color: #353535;
		position: relative;
		padding: 20px 0 15px;
		&:before,
		&:after{
			content: "";
			display: block;
			background-color: #353535;
			width: 10000000000px;
			height: 100%;
			position: absolute;
			top: 0;
			z-index: -1;
		}
		&:before{
			left: 99%;
		}
		&:after{
			right: 99%;
		}

		.accreditation,
		.accreditation-yandex {
			display: inline-block;
			vertical-align: top;
		}

		.accreditation{
			a{
				width: 95px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			}
			img{
				width: 100%;
				height: auto;
				vertical-align: bottom;
			}
		}

		.accreditation-yandex{
			width: 160px;
			p{
				padding-bottom: 7px;
			}
			p,
			span{
				font-size: 11px;
				color: #7b7b7b;
				font-family: $fontMedium;
			}
			span,img{
				display: inline-block;
				vertical-align: middle;
			}
			img{
				width: 93px;
				padding-right: 7px;
			}
		}
	}
}



@media screen and (max-width: 1024px){
	//_____________________________________footer
	.footer{
		padding: 30px 20px 0 20px;
		//_________________________________footer-services
		.footer-services{
			padding-bottom: 30px;

			.logo{
				width: 170px;
			}

			.connection{
				.btn{
					margin: 0 auto 20px;
				}
			}

			.menu{
				margin: 0 210px 0 170px;

				ul{
					&:first-child{
						padding-right: 10px;
					}
					&:last-child{
						padding-left: 10px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 850px){
	//_____________________________________footer
	.footer{
		padding: 25px 20px 0 20px;

		//_________________________________footer-services
		.footer-services{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			padding-bottom: 25px;

			.logo{
				float: none;
				order: 1;
				align-self: center;
				width: 35%;
				min-width: 110px;
				max-width: 170px;
				padding-bottom: 30px;
			}

			.menu{
				margin: 0;
				padding: 0;
				order: 2;
				padding-bottom: 20px;

				.menu_inner{
					flex-direction: column;
					align-items: center;
					text-align: center;
				}

				ul{
					max-width: inherit;
					padding-bottom: 5px;
					&:first-child{
						padding-right: 0;
					}
					&:last-child{
						padding-left: 0;
					}
				}

				li {
					padding-bottom: 10px;
					&:nth-child(1),
					&:nth-child(2) {
						padding-bottom: 15px;
					}
				}
				a, span{
					line-height: 1.2;
				}
			}

			.connection{
				float: none;
				order: 3;
				.btn{
					margin: 0 auto 30px;
				}
			}
		}

		//_________________________________footer-payment
		.footer-payment{
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 15px 0;

			.accreditation{
				order: 2;
				a{
					width: 70px;
					margin-right: 10px;
				}
			}

			.accreditation-yandex{
				order: 1;
				padding-bottom: 10px;
				img{
					width: 90px;
				}
			}
		}
	}
}