.news_page {
  .article_top {
    @include imgBg('../images/bg/news-single-bg.png');
  }

  .article_content {
    @include max-width-center(100%, 1180px);
    //padding: $verticalPd $gorisontalPd;
    box-sizing: border-box;
  }

  .container_news {
    overflow: hidden;

    .news_little__title__text {
      max-width: calc(100% - 200px);
      width: auto;
    }

    .news_little__contant {
      max-width: 100%;
    }
  }
}

.single_news {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__text {
      max-width: 350px;
      font-size: 24px;
      line-height: 1.5;
      text-align: right;
    }
  }

  .box__date {
    margin-top: 4px;
  }

  .sub_title_article {
    margin: 0 0 30px 0;
  }

  .container_slider__btns {
    margin: 20px 0;
  }
}


@media screen and (max-width: 1024px) {
  .article_content p {
    min-height: 13px;
  }
  .news_page {
    .article_content {
      font-size: 14px;
      position: relative;

      .container_slider__btns {
        & > * {
          position: absolute;
          top: -57px;
          border: none;

          &:before {
            @include sprite($news-arrow);
          }

          &:hover {
            &:before {
              margin: 0;
            }
          }

          &.next_btn {
            right: 0;
            transform: rotateZ(180deg);
          }

          &.prev_btn {
            left: 0;
            transform: rotate(0deg);
          }
        }
      }
    }
    .container_news {
      overflow: inherit;
    }

    .title_small {
      padding: 0 40px;
    }
  }

  .single_news {
    line-height: 1.4;

    &__title {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        height: 1px;
        background: rgba($darkLight, .8);
        width: 100%;
        z-index: -1;
      }

      &__text {
        text-align: left;
        max-width: none;
        border-bottom: 2px solid rgba($darkLight, 1);
        line-height: 1.4;
      }

      .box__date {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }

    .sub_title_article {
      margin-bottom: 5px;
    }
  }
}