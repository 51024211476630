#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  color: $liteColor;
  transition: all .3s;

  .close_menu {
    display: none;
  }

  .workSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px $gorisontalPd;
    box-sizing: border-box;
    @include max-width-center(100%, $workSection);
  }
  
  &.fill {
    background-color: #fff;
    @include boxShadow;
    color: $blueColor;

    .elem_anim {
      &:after {
        color: $darkColor;
      }
    }

    .logo {
      position: relative;

      img {
        opacity: 0;
      }

      &:after {
        content: '';
        z-index: 1;
        width: 107px;
        height: 31px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/logo-blue.png);
        background-repeat: no-repeat;
      }
    }

    .main_menu {
      &__more {
        &:after {
          @include sprite($rectangle-blue);
        }
      }
    }

    .btn__white {
      color: $blueColor;
      border-color: $blueColor;

      &:after {
        color: $liteColor;
      }

      &:hover {
        background-color: $blueColor;
      }

      &.btn__fill {
        background-color: $blueColor;
        color: $liteColor;

        &:after {
          color: $blueColor;
        }
      }
    }

    #name_user {
      &:before {
        @include sprite($user-icon-blue);
      }
    }

    .mob_menu_icon {
      & > * {
        background: $blueColor;
      }
    }
  }
}

.logo {
  display: block;
  align-self: flex-start;
}

.main_menu {
  height: 100%;
  display: block;
  //display: flex;
  //align-items: center;
  position: relative;

  .lang, .overlay {
    display: none;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__item {
    display: block;
    padding: 10px 20px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__more {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      @include sprite($rectangle);
      margin-left: 10px;
      transition: transform .3s;
    }

    &.elem_droped {
      &:after {
        transform: rotateX(180deg);
      }
    }
  }

  &__sub {
    display: none;
    //position: absolute;
    top: 100%;
    padding-right: 15px;
    box-sizing: border-box;
    width: auto;

    &.elem_active {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.user_menu {
  display: none;
}

.header_right {
  display: flex;
  align-self: flex-start;
}

.mob_menu_icon {
  display: none;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding-left: 7px;
  box-sizing: border-box;

  & > * {
    display: block;
    width: 20px;
    height: 2px;
    background: $liteColor;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

#name_user {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    @include sprite($user-icon-white);
  }
}

.lang {
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 48px;
  display: block;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1280px) {
  .elem_anim {
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:after {
      content: attr(data-text);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      color: #b3d1ff;
      -webkit-transform: translate3d(0, 25%, 0);
      transform: translate3d(0, 25%, 0);
      padding: 0 20px;
      box-sizing: border-box;
    }

    & > span {
      display: block;
    }

    &:after, & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s, opacity 0.1s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      & > span {
        opacity: 0;
        transform: translate3d(0, -25%, 0);
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .lang {
    margin-left: 20px;;
  }

  .main_menu__item {
    padding: 8px;
    font-size: 13px;
  }

  .elem_anim {
    &:after {
      padding: 8px;
    }
  }

  #header {
    .workSection {
      padding: 17px $gorisontalPdTab;
    }
  }
}

@media screen and (max-width: 1023px) {
  #header {
    .workSection {
      padding: 17px $gorisontalPdMob;
    }

    .close_menu {
      display: block;
      top: 20px;
      right: 20px;
    }

    .more_menu {
      display: none;
    }

    &.fill {
      .main_menu {
        color: $liteColor;
      }
    }
  }

  .mob_menu_icon {
    display: flex;
  }

  .main_menu, .user_menu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    padding: 65px 0 20px 0;
    box-sizing: border-box;
    animation: hideMenu .3s;
    animation-fill-mode: forwards;/* or: backwards, both, none */

    &.show_el {
      animation: showMenu .3s;

      .overlay {
        display: block;
        opacity: 1;
        z-index: 0;
      }

      .menu_container {
        animation: showMenuContent .6s;
      }
    }

    .overlay {
      display: block;
      opacity: 0;
      z-index: -1;
      transition: all .9s;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($blueColor, .8);
    }

    &__container {
      flex-direction: column;
    }

    &__item {
      padding: 17px 35px;
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      text-align: center;
    }

    &__sub {
      padding: 0;
      display: block;
      position: relative;
      top: 0;
      left: 0;
    }

    .lang {
      display: block;
      position: relative;
      z-index: 1;
      text-align: center;
      margin: 0;
      padding: 17px 35px;
    }
  }

  .menu_container {
    height: 100%;
    overflow-y: auto;
    animation: hideMenuContent .3s;
    animation-fill-mode: forwards;
  }

  .header_right {
    .lang {
      display: none;
    }
  }

  .user_menu {
    display: block;

    &__item {
      position: relative;
      z-index: 1;
      display: block;
      padding: 17px 35px;
      color: $liteColor;
    }
  }

  #name_user {
    span {
      display: none;
    }
  }
}

@keyframes showMenu {
  from {transform: translateX(100%);}
  to   {transform: translateX(0);}
}

@keyframes showMenuContent {
  from {transform: translateX(80%);}
  to   {transform: translateX(0);}
}

@keyframes hideMenuContent {
  from {transform: translateX(0);}
  to   {transform: translateX(80%);}
}

@keyframes hideMenu {
  from {transform: translateX(0);}
  to   {transform: translateX(100%);}
}