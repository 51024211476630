/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$alfaclick-small-name: 'alfaclick-small';
$alfaclick-small-x: 178px;
$alfaclick-small-y: 265px;
$alfaclick-small-offset-x: -178px;
$alfaclick-small-offset-y: -265px;
$alfaclick-small-width: 25px;
$alfaclick-small-height: 25px;
$alfaclick-small-total-width: 332px;
$alfaclick-small-total-height: 314px;
$alfaclick-small-image: '../images/sprites/sprite.png';
$alfaclick-small: (178px, 265px, -178px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'alfaclick-small', );
$alfaclick-name: 'alfaclick';
$alfaclick-x: 188px;
$alfaclick-y: 0px;
$alfaclick-offset-x: -188px;
$alfaclick-offset-y: 0px;
$alfaclick-width: 44px;
$alfaclick-height: 44px;
$alfaclick-total-width: 332px;
$alfaclick-total-height: 314px;
$alfaclick-image: '../images/sprites/sprite.png';
$alfaclick: (188px, 0px, -188px, 0px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'alfaclick', );
$arrow-blue-name: 'arrow-blue';
$arrow-blue-x: 27px;
$arrow-blue-y: 303px;
$arrow-blue-offset-x: -27px;
$arrow-blue-offset-y: -303px;
$arrow-blue-width: 27px;
$arrow-blue-height: 11px;
$arrow-blue-total-width: 332px;
$arrow-blue-total-height: 314px;
$arrow-blue-image: '../images/sprites/sprite.png';
$arrow-blue: (27px, 303px, -27px, -303px, 27px, 11px, 332px, 314px, '../images/sprites/sprite.png', 'arrow-blue', );
$arrow-dark-name: 'arrow-dark';
$arrow-dark-x: 232px;
$arrow-dark-y: 215px;
$arrow-dark-offset-x: -232px;
$arrow-dark-offset-y: -215px;
$arrow-dark-width: 28px;
$arrow-dark-height: 12px;
$arrow-dark-total-width: 332px;
$arrow-dark-total-height: 314px;
$arrow-dark-image: '../images/sprites/sprite.png';
$arrow-dark: (232px, 215px, -232px, -215px, 28px, 12px, 332px, 314px, '../images/sprites/sprite.png', 'arrow-dark', );
$arrow-filter-blue-name: 'arrow-filter-blue';
$arrow-filter-blue-x: 308px;
$arrow-filter-blue-y: 282px;
$arrow-filter-blue-offset-x: -308px;
$arrow-filter-blue-offset-y: -282px;
$arrow-filter-blue-width: 10px;
$arrow-filter-blue-height: 16px;
$arrow-filter-blue-total-width: 332px;
$arrow-filter-blue-total-height: 314px;
$arrow-filter-blue-image: '../images/sprites/sprite.png';
$arrow-filter-blue: (308px, 282px, -308px, -282px, 10px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'arrow-filter-blue', );
$arrow-filter-name: 'arrow-filter';
$arrow-filter-x: 322px;
$arrow-filter-y: 113px;
$arrow-filter-offset-x: -322px;
$arrow-filter-offset-y: -113px;
$arrow-filter-width: 10px;
$arrow-filter-height: 16px;
$arrow-filter-total-width: 332px;
$arrow-filter-total-height: 314px;
$arrow-filter-image: '../images/sprites/sprite.png';
$arrow-filter: (322px, 113px, -322px, -113px, 10px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'arrow-filter', );
$arrow-light-small-name: 'arrow-light-small';
$arrow-light-small-x: 308px;
$arrow-light-small-y: 273px;
$arrow-light-small-offset-x: -308px;
$arrow-light-small-offset-y: -273px;
$arrow-light-small-width: 20px;
$arrow-light-small-height: 9px;
$arrow-light-small-total-width: 332px;
$arrow-light-small-total-height: 314px;
$arrow-light-small-image: '../images/sprites/sprite.png';
$arrow-light-small: (308px, 273px, -308px, -273px, 20px, 9px, 332px, 314px, '../images/sprites/sprite.png', 'arrow-light-small', );
$arrow-light-name: 'arrow-light';
$arrow-light-x: 200px;
$arrow-light-y: 202px;
$arrow-light-offset-x: -200px;
$arrow-light-offset-y: -202px;
$arrow-light-width: 28px;
$arrow-light-height: 12px;
$arrow-light-total-width: 332px;
$arrow-light-total-height: 314px;
$arrow-light-image: '../images/sprites/sprite.png';
$arrow-light: (200px, 202px, -200px, -202px, 28px, 12px, 332px, 314px, '../images/sprites/sprite.png', 'arrow-light', );
$arrow-name: 'arrow';
$arrow-x: 0px;
$arrow-y: 303px;
$arrow-offset-x: 0px;
$arrow-offset-y: -303px;
$arrow-width: 27px;
$arrow-height: 11px;
$arrow-total-width: 332px;
$arrow-total-height: 314px;
$arrow-image: '../images/sprites/sprite.png';
$arrow: (0px, 303px, 0px, -303px, 27px, 11px, 332px, 314px, '../images/sprites/sprite.png', 'arrow', );
$bell-name: 'bell';
$bell-x: 308px;
$bell-y: 24px;
$bell-offset-x: -308px;
$bell-offset-y: -24px;
$bell-width: 22px;
$bell-height: 22px;
$bell-total-width: 332px;
$bell-total-height: 314px;
$bell-image: '../images/sprites/sprite.png';
$bell: (308px, 24px, -308px, -24px, 22px, 22px, 332px, 314px, '../images/sprites/sprite.png', 'bell', );
$cash-money-small-name: 'cash_money-small';
$cash-money-small-x: 232px;
$cash-money-small-y: 190px;
$cash-money-small-offset-x: -232px;
$cash-money-small-offset-y: -190px;
$cash-money-small-width: 25px;
$cash-money-small-height: 25px;
$cash-money-small-total-width: 332px;
$cash-money-small-total-height: 314px;
$cash-money-small-image: '../images/sprites/sprite.png';
$cash-money-small: (232px, 190px, -232px, -190px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'cash_money-small', );
$cash-money-name: 'cash_money';
$cash-money-x: 132px;
$cash-money-y: 54px;
$cash-money-offset-x: -132px;
$cash-money-offset-y: -54px;
$cash-money-width: 44px;
$cash-money-height: 44px;
$cash-money-total-width: 332px;
$cash-money-total-height: 314px;
$cash-money-image: '../images/sprites/sprite.png';
$cash-money: (132px, 54px, -132px, -54px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'cash_money', );
$checbox-checked-dark-name: 'checbox-checked-dark';
$checbox-checked-dark-x: 324px;
$checbox-checked-dark-y: 165px;
$checbox-checked-dark-offset-x: -324px;
$checbox-checked-dark-offset-y: -165px;
$checbox-checked-dark-width: 8px;
$checbox-checked-dark-height: 8px;
$checbox-checked-dark-total-width: 332px;
$checbox-checked-dark-total-height: 314px;
$checbox-checked-dark-image: '../images/sprites/sprite.png';
$checbox-checked-dark: (324px, 165px, -324px, -165px, 8px, 8px, 332px, 314px, '../images/sprites/sprite.png', 'checbox-checked-dark', );
$checbox-checked-light-name: 'checbox-checked-light';
$checbox-checked-light-x: 324px;
$checbox-checked-light-y: 173px;
$checbox-checked-light-offset-x: -324px;
$checbox-checked-light-offset-y: -173px;
$checbox-checked-light-width: 8px;
$checbox-checked-light-height: 8px;
$checbox-checked-light-total-width: 332px;
$checbox-checked-light-total-height: 314px;
$checbox-checked-light-image: '../images/sprites/sprite.png';
$checbox-checked-light: (324px, 173px, -324px, -173px, 8px, 8px, 332px, 314px, '../images/sprites/sprite.png', 'checbox-checked-light', );
$chip-name: 'chip';
$chip-x: 38px;
$chip-y: 265px;
$chip-offset-x: -38px;
$chip-offset-y: -265px;
$chip-width: 40px;
$chip-height: 32px;
$chip-total-width: 332px;
$chip-total-height: 314px;
$chip-image: '../images/sprites/sprite.png';
$chip: (38px, 265px, -38px, -265px, 40px, 32px, 332px, 314px, '../images/sprites/sprite.png', 'chip', );
$clock-dark-icon-name: 'clock-dark-icon';
$clock-dark-icon-x: 308px;
$clock-dark-icon-y: 245px;
$clock-dark-icon-offset-x: -308px;
$clock-dark-icon-offset-y: -245px;
$clock-dark-icon-width: 14px;
$clock-dark-icon-height: 14px;
$clock-dark-icon-total-width: 332px;
$clock-dark-icon-total-height: 314px;
$clock-dark-icon-image: '../images/sprites/sprite.png';
$clock-dark-icon: (308px, 245px, -308px, -245px, 14px, 14px, 332px, 314px, '../images/sprites/sprite.png', 'clock-dark-icon', );
$clock-light-icon-name: 'clock-light-icon';
$clock-light-icon-x: 308px;
$clock-light-icon-y: 259px;
$clock-light-icon-offset-x: -308px;
$clock-light-icon-offset-y: -259px;
$clock-light-icon-width: 14px;
$clock-light-icon-height: 14px;
$clock-light-icon-total-width: 332px;
$clock-light-icon-total-height: 314px;
$clock-light-icon-image: '../images/sprites/sprite.png';
$clock-light-icon: (308px, 259px, -308px, -259px, 14px, 14px, 332px, 314px, '../images/sprites/sprite.png', 'clock-light-icon', );
$close-large-name: 'close-large';
$close-large-x: 253px;
$close-large-y: 265px;
$close-large-offset-x: -253px;
$close-large-offset-y: -265px;
$close-large-width: 24px;
$close-large-height: 24px;
$close-large-total-width: 332px;
$close-large-total-height: 314px;
$close-large-image: '../images/sprites/sprite.png';
$close-large: (253px, 265px, -253px, -265px, 24px, 24px, 332px, 314px, '../images/sprites/sprite.png', 'close-large', );
$close-light-big-name: 'close-light-big';
$close-light-big-x: 308px;
$close-light-big-y: 0px;
$close-light-big-offset-x: -308px;
$close-light-big-offset-y: 0px;
$close-light-big-width: 24px;
$close-light-big-height: 24px;
$close-light-big-total-width: 332px;
$close-light-big-total-height: 314px;
$close-light-big-image: '../images/sprites/sprite.png';
$close-light-big: (308px, 0px, -308px, 0px, 24px, 24px, 332px, 314px, '../images/sprites/sprite.png', 'close-light-big', );
$close-light-small-name: 'close-light-small';
$close-light-small-x: 318px;
$close-light-small-y: 282px;
$close-light-small-offset-x: -318px;
$close-light-small-offset-y: -282px;
$close-light-small-width: 13px;
$close-light-small-height: 12px;
$close-light-small-total-width: 332px;
$close-light-small-total-height: 314px;
$close-light-small-image: '../images/sprites/sprite.png';
$close-light-small: (318px, 282px, -318px, -282px, 13px, 12px, 332px, 314px, '../images/sprites/sprite.png', 'close-light-small', );
$close-name: 'close';
$close-x: 308px;
$close-y: 65px;
$close-offset-x: -308px;
$close-offset-y: -65px;
$close-width: 17px;
$close-height: 17px;
$close-total-width: 332px;
$close-total-height: 314px;
$close-image: '../images/sprites/sprite.png';
$close: (308px, 65px, -308px, -65px, 17px, 17px, 332px, 314px, '../images/sprites/sprite.png', 'close', );
$diagram-icon-name: 'diagram-icon';
$diagram-icon-x: 132px;
$diagram-icon-y: 0px;
$diagram-icon-offset-x: -132px;
$diagram-icon-offset-y: 0px;
$diagram-icon-width: 56px;
$diagram-icon-height: 54px;
$diagram-icon-total-width: 332px;
$diagram-icon-total-height: 314px;
$diagram-icon-image: '../images/sprites/sprite.png';
$diagram-icon: (132px, 0px, -132px, 0px, 56px, 54px, 332px, 314px, '../images/sprites/sprite.png', 'diagram-icon', );
$easy-icon-name: 'easy-icon';
$easy-icon-x: 56px;
$easy-icon-y: 44px;
$easy-icon-offset-x: -56px;
$easy-icon-offset-y: -44px;
$easy-icon-width: 58px;
$easy-icon-height: 54px;
$easy-icon-total-width: 332px;
$easy-icon-total-height: 314px;
$easy-icon-image: '../images/sprites/sprite.png';
$easy-icon: (56px, 44px, -56px, -44px, 58px, 54px, 332px, 314px, '../images/sprites/sprite.png', 'easy-icon', );
$facebook-small-icon-name: 'facebook-small-icon';
$facebook-small-icon-x: 308px;
$facebook-small-icon-y: 99px;
$facebook-small-icon-offset-x: -308px;
$facebook-small-icon-offset-y: -99px;
$facebook-small-icon-width: 20px;
$facebook-small-icon-height: 14px;
$facebook-small-icon-total-width: 332px;
$facebook-small-icon-total-height: 314px;
$facebook-small-icon-image: '../images/sprites/sprite.png';
$facebook-small-icon: (308px, 99px, -308px, -99px, 20px, 14px, 332px, 314px, '../images/sprites/sprite.png', 'facebook-small-icon', );
$fast-icon-name: 'fast-icon';
$fast-icon-x: 0px;
$fast-icon-y: 104px;
$fast-icon-offset-x: 0px;
$fast-icon-offset-y: -104px;
$fast-icon-width: 54px;
$fast-icon-height: 54px;
$fast-icon-total-width: 332px;
$fast-icon-total-height: 314px;
$fast-icon-image: '../images/sprites/sprite.png';
$fast-icon: (0px, 104px, 0px, -104px, 54px, 54px, 332px, 314px, '../images/sprites/sprite.png', 'fast-icon', );
$fb-blue-name: 'fb-blue';
$fb-blue-x: 232px;
$fb-blue-y: 0px;
$fb-blue-offset-x: -232px;
$fb-blue-offset-y: 0px;
$fb-blue-width: 38px;
$fb-blue-height: 38px;
$fb-blue-total-width: 332px;
$fb-blue-total-height: 314px;
$fb-blue-image: '../images/sprites/sprite.png';
$fb-blue: (232px, 0px, -232px, 0px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'fb-blue', );
$fb-dark-hover-name: 'fb-dark-hover';
$fb-dark-hover-x: 232px;
$fb-dark-hover-y: 38px;
$fb-dark-hover-offset-x: -232px;
$fb-dark-hover-offset-y: -38px;
$fb-dark-hover-width: 38px;
$fb-dark-hover-height: 38px;
$fb-dark-hover-total-width: 332px;
$fb-dark-hover-total-height: 314px;
$fb-dark-hover-image: '../images/sprites/sprite.png';
$fb-dark-hover: (232px, 38px, -232px, -38px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'fb-dark-hover', );
$fb-dark-name: 'fb-dark';
$fb-dark-x: 232px;
$fb-dark-y: 76px;
$fb-dark-offset-x: -232px;
$fb-dark-offset-y: -76px;
$fb-dark-width: 38px;
$fb-dark-height: 38px;
$fb-dark-total-width: 332px;
$fb-dark-total-height: 314px;
$fb-dark-image: '../images/sprites/sprite.png';
$fb-dark: (232px, 76px, -232px, -76px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'fb-dark', );
$fb-light-hover-name: 'fb-light-hover';
$fb-light-hover-x: 232px;
$fb-light-hover-y: 114px;
$fb-light-hover-offset-x: -232px;
$fb-light-hover-offset-y: -114px;
$fb-light-hover-width: 38px;
$fb-light-hover-height: 38px;
$fb-light-hover-total-width: 332px;
$fb-light-hover-total-height: 314px;
$fb-light-hover-image: '../images/sprites/sprite.png';
$fb-light-hover: (232px, 114px, -232px, -114px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'fb-light-hover', );
$fb-light-name: 'fb-light';
$fb-light-x: 232px;
$fb-light-y: 152px;
$fb-light-offset-x: -232px;
$fb-light-offset-y: -152px;
$fb-light-width: 38px;
$fb-light-height: 38px;
$fb-light-total-width: 332px;
$fb-light-total-height: 314px;
$fb-light-image: '../images/sprites/sprite.png';
$fb-light: (232px, 152px, -232px, -152px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'fb-light', );
$footnote-name: 'footnote';
$footnote-x: 270px;
$footnote-y: 228px;
$footnote-offset-x: -270px;
$footnote-offset-y: -228px;
$footnote-width: 36px;
$footnote-height: 34px;
$footnote-total-width: 332px;
$footnote-total-height: 314px;
$footnote-image: '../images/sprites/sprite.png';
$footnote: (270px, 228px, -270px, -228px, 36px, 34px, 332px, 314px, '../images/sprites/sprite.png', 'footnote', );
$google-plus-dark-hover-name: 'google-plus-dark-hover';
$google-plus-dark-hover-x: 38px;
$google-plus-dark-hover-y: 227px;
$google-plus-dark-hover-offset-x: -38px;
$google-plus-dark-hover-offset-y: -227px;
$google-plus-dark-hover-width: 38px;
$google-plus-dark-hover-height: 38px;
$google-plus-dark-hover-total-width: 332px;
$google-plus-dark-hover-total-height: 314px;
$google-plus-dark-hover-image: '../images/sprites/sprite.png';
$google-plus-dark-hover: (38px, 227px, -38px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'google-plus-dark-hover', );
$google-plus-dark-name: 'google-plus-dark';
$google-plus-dark-x: 76px;
$google-plus-dark-y: 227px;
$google-plus-dark-offset-x: -76px;
$google-plus-dark-offset-y: -227px;
$google-plus-dark-width: 38px;
$google-plus-dark-height: 38px;
$google-plus-dark-total-width: 332px;
$google-plus-dark-total-height: 314px;
$google-plus-dark-image: '../images/sprites/sprite.png';
$google-plus-dark: (76px, 227px, -76px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'google-plus-dark', );
$google-plus-light-hover-name: 'google-plus-light-hover';
$google-plus-light-hover-x: 114px;
$google-plus-light-hover-y: 227px;
$google-plus-light-hover-offset-x: -114px;
$google-plus-light-hover-offset-y: -227px;
$google-plus-light-hover-width: 38px;
$google-plus-light-hover-height: 38px;
$google-plus-light-hover-total-width: 332px;
$google-plus-light-hover-total-height: 314px;
$google-plus-light-hover-image: '../images/sprites/sprite.png';
$google-plus-light-hover: (114px, 227px, -114px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'google-plus-light-hover', );
$google-plus-light-name: 'google-plus-light';
$google-plus-light-x: 152px;
$google-plus-light-y: 227px;
$google-plus-light-offset-x: -152px;
$google-plus-light-offset-y: -227px;
$google-plus-light-width: 38px;
$google-plus-light-height: 38px;
$google-plus-light-total-width: 332px;
$google-plus-light-total-height: 314px;
$google-plus-light-image: '../images/sprites/sprite.png';
$google-plus-light: (152px, 227px, -152px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'google-plus-light', );
$google-plus-red-name: 'google-plus-red';
$google-plus-red-x: 148px;
$google-plus-red-y: 104px;
$google-plus-red-offset-x: -148px;
$google-plus-red-offset-y: -104px;
$google-plus-red-width: 38px;
$google-plus-red-height: 38px;
$google-plus-red-total-width: 332px;
$google-plus-red-total-height: 314px;
$google-plus-red-image: '../images/sprites/sprite.png';
$google-plus-red: (148px, 104px, -148px, -104px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'google-plus-red', );
$headset-name: 'headset';
$headset-x: 277px;
$headset-y: 265px;
$headset-offset-x: -277px;
$headset-offset-y: -265px;
$headset-width: 24px;
$headset-height: 24px;
$headset-total-width: 332px;
$headset-total-height: 314px;
$headset-image: '../images/sprites/sprite.png';
$headset: (277px, 265px, -277px, -265px, 24px, 24px, 332px, 314px, '../images/sprites/sprite.png', 'headset', );
$i-name: 'i';
$i-x: 308px;
$i-y: 46px;
$i-offset-x: -308px;
$i-offset-y: -46px;
$i-width: 19px;
$i-height: 19px;
$i-total-width: 332px;
$i-total-height: 314px;
$i-image: '../images/sprites/sprite.png';
$i: (308px, 46px, -308px, -46px, 19px, 19px, 332px, 314px, '../images/sprites/sprite.png', 'i', );
$invite-icon-name: 'invite-icon';
$invite-icon-x: 54px;
$invite-icon-y: 104px;
$invite-icon-offset-x: -54px;
$invite-icon-offset-y: -104px;
$invite-icon-width: 52px;
$invite-icon-height: 54px;
$invite-icon-total-width: 332px;
$invite-icon-total-height: 314px;
$invite-icon-image: '../images/sprites/sprite.png';
$invite-icon: (54px, 104px, -54px, -104px, 52px, 54px, 332px, 314px, '../images/sprites/sprite.png', 'invite-icon', );
$mastercard-logo-name: 'mastercard-logo';
$mastercard-logo-x: 75px;
$mastercard-logo-y: 202px;
$mastercard-logo-offset-x: -75px;
$mastercard-logo-offset-y: -202px;
$mastercard-logo-width: 62px;
$mastercard-logo-height: 20px;
$mastercard-logo-total-width: 332px;
$mastercard-logo-total-height: 314px;
$mastercard-logo-image: '../images/sprites/sprite.png';
$mastercard-logo: (75px, 202px, -75px, -202px, 62px, 20px, 332px, 314px, '../images/sprites/sprite.png', 'mastercard-logo', );
$news-arrow-name: 'news-arrow';
$news-arrow-x: 308px;
$news-arrow-y: 113px;
$news-arrow-offset-x: -308px;
$news-arrow-offset-y: -113px;
$news-arrow-width: 14px;
$news-arrow-height: 20px;
$news-arrow-total-width: 332px;
$news-arrow-total-height: 314px;
$news-arrow-image: '../images/sprites/sprite.png';
$news-arrow: (308px, 113px, -308px, -113px, 14px, 20px, 332px, 314px, '../images/sprites/sprite.png', 'news-arrow', );
$ok-dark-hover-name: 'ok-dark-hover';
$ok-dark-hover-x: 270px;
$ok-dark-hover-y: 152px;
$ok-dark-hover-offset-x: -270px;
$ok-dark-hover-offset-y: -152px;
$ok-dark-hover-width: 38px;
$ok-dark-hover-height: 38px;
$ok-dark-hover-total-width: 332px;
$ok-dark-hover-total-height: 314px;
$ok-dark-hover-image: '../images/sprites/sprite.png';
$ok-dark-hover: (270px, 152px, -270px, -152px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'ok-dark-hover', );
$ok-dark-name: 'ok-dark';
$ok-dark-x: 270px;
$ok-dark-y: 190px;
$ok-dark-offset-x: -270px;
$ok-dark-offset-y: -190px;
$ok-dark-width: 38px;
$ok-dark-height: 38px;
$ok-dark-total-width: 332px;
$ok-dark-total-height: 314px;
$ok-dark-image: '../images/sprites/sprite.png';
$ok-dark: (270px, 190px, -270px, -190px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'ok-dark', );
$ok-light-hover-name: 'ok-light-hover';
$ok-light-hover-x: 0px;
$ok-light-hover-y: 265px;
$ok-light-hover-offset-x: 0px;
$ok-light-hover-offset-y: -265px;
$ok-light-hover-width: 38px;
$ok-light-hover-height: 38px;
$ok-light-hover-total-width: 332px;
$ok-light-hover-total-height: 314px;
$ok-light-hover-image: '../images/sprites/sprite.png';
$ok-light-hover: (0px, 265px, 0px, -265px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'ok-light-hover', );
$ok-light-name: 'ok-light';
$ok-light-x: 270px;
$ok-light-y: 76px;
$ok-light-offset-x: -270px;
$ok-light-offset-y: -76px;
$ok-light-width: 38px;
$ok-light-height: 38px;
$ok-light-total-width: 332px;
$ok-light-total-height: 314px;
$ok-light-image: '../images/sprites/sprite.png';
$ok-light: (270px, 76px, -270px, -76px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'ok-light', );
$ok-orange-name: 'ok-orange';
$ok-orange-x: 190px;
$ok-orange-y: 227px;
$ok-orange-offset-x: -190px;
$ok-orange-offset-y: -227px;
$ok-orange-width: 38px;
$ok-orange-height: 38px;
$ok-orange-total-width: 332px;
$ok-orange-total-height: 314px;
$ok-orange-image: '../images/sprites/sprite.png';
$ok-orange: (190px, 227px, -190px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'ok-orange', );
$p24-small-name: 'p24-small';
$p24-small-x: 153px;
$p24-small-y: 265px;
$p24-small-offset-x: -153px;
$p24-small-offset-y: -265px;
$p24-small-width: 25px;
$p24-small-height: 25px;
$p24-small-total-width: 332px;
$p24-small-total-height: 314px;
$p24-small-image: '../images/sprites/sprite.png';
$p24-small: (153px, 265px, -153px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'p24-small', );
$p24-name: 'p24';
$p24-x: 176px;
$p24-y: 158px;
$p24-offset-x: -176px;
$p24-offset-y: -158px;
$p24-width: 44px;
$p24-height: 44px;
$p24-total-width: 332px;
$p24-total-height: 314px;
$p24-image: '../images/sprites/sprite.png';
$p24: (176px, 158px, -176px, -158px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'p24', );
$paper-plane-name: 'paper-plane';
$paper-plane-x: 0px;
$paper-plane-y: 44px;
$paper-plane-offset-x: 0px;
$paper-plane-offset-y: -44px;
$paper-plane-width: 56px;
$paper-plane-height: 60px;
$paper-plane-total-width: 332px;
$paper-plane-total-height: 314px;
$paper-plane-image: '../images/sprites/sprite.png';
$paper-plane: (0px, 44px, 0px, -44px, 56px, 60px, 332px, 314px, '../images/sprites/sprite.png', 'paper-plane', );
$plus-blue-name: 'plus-blue';
$plus-blue-x: 308px;
$plus-blue-y: 181px;
$plus-blue-offset-x: -308px;
$plus-blue-offset-y: -181px;
$plus-blue-width: 16px;
$plus-blue-height: 16px;
$plus-blue-total-width: 332px;
$plus-blue-total-height: 314px;
$plus-blue-image: '../images/sprites/sprite.png';
$plus-blue: (308px, 181px, -308px, -181px, 16px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'plus-blue', );
$plus-little-name: 'plus-little';
$plus-little-x: 308px;
$plus-little-y: 82px;
$plus-little-offset-x: -308px;
$plus-little-offset-y: -82px;
$plus-little-width: 17px;
$plus-little-height: 17px;
$plus-little-total-width: 332px;
$plus-little-total-height: 314px;
$plus-little-image: '../images/sprites/sprite.png';
$plus-little: (308px, 82px, -308px, -82px, 17px, 17px, 332px, 314px, '../images/sprites/sprite.png', 'plus-little', );
$plus-white-name: 'plus-white';
$plus-white-x: 308px;
$plus-white-y: 197px;
$plus-white-offset-x: -308px;
$plus-white-offset-y: -197px;
$plus-white-width: 16px;
$plus-white-height: 16px;
$plus-white-total-width: 332px;
$plus-white-total-height: 314px;
$plus-white-image: '../images/sprites/sprite.png';
$plus-white: (308px, 197px, -308px, -197px, 16px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'plus-white', );
$qiwi-small-name: 'qiwi-small';
$qiwi-small-x: 228px;
$qiwi-small-y: 265px;
$qiwi-small-offset-x: -228px;
$qiwi-small-offset-y: -265px;
$qiwi-small-width: 25px;
$qiwi-small-height: 25px;
$qiwi-small-total-width: 332px;
$qiwi-small-total-height: 314px;
$qiwi-small-image: '../images/sprites/sprite.png';
$qiwi-small: (228px, 265px, -228px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'qiwi-small', );
$qiwi-name: 'qiwi';
$qiwi-x: 0px;
$qiwi-y: 158px;
$qiwi-offset-x: 0px;
$qiwi-offset-y: -158px;
$qiwi-width: 44px;
$qiwi-height: 44px;
$qiwi-total-width: 332px;
$qiwi-total-height: 314px;
$qiwi-image: '../images/sprites/sprite.png';
$qiwi: (0px, 158px, 0px, -158px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'qiwi', );
$realible-icon-name: 'realible-icon';
$realible-icon-x: 106px;
$realible-icon-y: 104px;
$realible-icon-offset-x: -106px;
$realible-icon-offset-y: -104px;
$realible-icon-width: 42px;
$realible-icon-height: 54px;
$realible-icon-total-width: 332px;
$realible-icon-total-height: 314px;
$realible-icon-image: '../images/sprites/sprite.png';
$realible-icon: (106px, 104px, -106px, -104px, 42px, 54px, 332px, 314px, '../images/sprites/sprite.png', 'realible-icon', );
$rectangle-blue-name: 'rectangle-blue';
$rectangle-blue-x: 308px;
$rectangle-blue-y: 213px;
$rectangle-blue-offset-x: -308px;
$rectangle-blue-offset-y: -213px;
$rectangle-blue-width: 15px;
$rectangle-blue-height: 16px;
$rectangle-blue-total-width: 332px;
$rectangle-blue-total-height: 314px;
$rectangle-blue-image: '../images/sprites/sprite.png';
$rectangle-blue: (308px, 213px, -308px, -213px, 15px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'rectangle-blue', );
$rectangle-dark-name: 'rectangle-dark';
$rectangle-dark-x: 308px;
$rectangle-dark-y: 165px;
$rectangle-dark-offset-x: -308px;
$rectangle-dark-offset-y: -165px;
$rectangle-dark-width: 16px;
$rectangle-dark-height: 16px;
$rectangle-dark-total-width: 332px;
$rectangle-dark-total-height: 314px;
$rectangle-dark-image: '../images/sprites/sprite.png';
$rectangle-dark: (308px, 165px, -308px, -165px, 16px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'rectangle-dark', );
$rectangle-name: 'rectangle';
$rectangle-x: 308px;
$rectangle-y: 229px;
$rectangle-offset-x: -308px;
$rectangle-offset-y: -229px;
$rectangle-width: 15px;
$rectangle-height: 16px;
$rectangle-total-width: 332px;
$rectangle-total-height: 314px;
$rectangle-image: '../images/sprites/sprite.png';
$rectangle: (308px, 229px, -308px, -229px, 15px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'rectangle', );
$rsb-small-name: 'rsb-small';
$rsb-small-x: 103px;
$rsb-small-y: 265px;
$rsb-small-offset-x: -103px;
$rsb-small-offset-y: -265px;
$rsb-small-width: 25px;
$rsb-small-height: 25px;
$rsb-small-total-width: 332px;
$rsb-small-total-height: 314px;
$rsb-small-image: '../images/sprites/sprite.png';
$rsb-small: (103px, 265px, -103px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'rsb-small', );
$rsb-name: 'rsb';
$rsb-x: 132px;
$rsb-y: 158px;
$rsb-offset-x: -132px;
$rsb-offset-y: -158px;
$rsb-width: 44px;
$rsb-height: 44px;
$rsb-total-width: 332px;
$rsb-total-height: 314px;
$rsb-image: '../images/sprites/sprite.png';
$rsb: (132px, 158px, -132px, -158px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'rsb', );
$sber-small-name: 'sber-small';
$sber-small-x: 203px;
$sber-small-y: 265px;
$sber-small-offset-x: -203px;
$sber-small-offset-y: -265px;
$sber-small-width: 25px;
$sber-small-height: 25px;
$sber-small-total-width: 332px;
$sber-small-total-height: 314px;
$sber-small-image: '../images/sprites/sprite.png';
$sber-small: (203px, 265px, -203px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'sber-small', );
$sber-name: 'sber';
$sber-x: 88px;
$sber-y: 158px;
$sber-offset-x: -88px;
$sber-offset-y: -158px;
$sber-width: 44px;
$sber-height: 44px;
$sber-total-width: 332px;
$sber-total-height: 314px;
$sber-image: '../images/sprites/sprite.png';
$sber: (88px, 158px, -88px, -158px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'sber', );
$select-arrow-name: 'select_arrow';
$select-arrow-x: 213px;
$select-arrow-y: 132px;
$select-arrow-offset-x: -213px;
$select-arrow-offset-y: -132px;
$select-arrow-width: 14px;
$select-arrow-height: 8px;
$select-arrow-total-width: 332px;
$select-arrow-total-height: 314px;
$select-arrow-image: '../images/sprites/sprite.png';
$select-arrow: (213px, 132px, -213px, -132px, 14px, 8px, 332px, 314px, '../images/sprites/sprite.png', 'select_arrow', );
$select-arrow-dark-name: 'select_arrow_dark';
$select-arrow-dark-x: 213px;
$select-arrow-dark-y: 140px;
$select-arrow-dark-offset-x: -213px;
$select-arrow-dark-offset-y: -140px;
$select-arrow-dark-width: 14px;
$select-arrow-dark-height: 8px;
$select-arrow-dark-total-width: 332px;
$select-arrow-dark-total-height: 314px;
$select-arrow-dark-image: '../images/sprites/sprite.png';
$select-arrow-dark: (213px, 140px, -213px, -140px, 14px, 8px, 332px, 314px, '../images/sprites/sprite.png', 'select_arrow_dark', );
$upc-small-name: 'upc-small';
$upc-small-x: 128px;
$upc-small-y: 265px;
$upc-small-offset-x: -128px;
$upc-small-offset-y: -265px;
$upc-small-width: 25px;
$upc-small-height: 25px;
$upc-small-total-width: 332px;
$upc-small-total-height: 314px;
$upc-small-image: '../images/sprites/sprite.png';
$upc-small: (128px, 265px, -128px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'upc-small', );
$upc-name: 'upc';
$upc-x: 44px;
$upc-y: 158px;
$upc-offset-x: -44px;
$upc-offset-y: -158px;
$upc-width: 44px;
$upc-height: 44px;
$upc-total-width: 332px;
$upc-total-height: 314px;
$upc-image: '../images/sprites/sprite.png';
$upc: (44px, 158px, -44px, -158px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'upc', );
$user-icon-blue-name: 'user-icon-blue';
$user-icon-blue-x: 308px;
$user-icon-blue-y: 149px;
$user-icon-blue-offset-x: -308px;
$user-icon-blue-offset-y: -149px;
$user-icon-blue-width: 17px;
$user-icon-blue-height: 16px;
$user-icon-blue-total-width: 332px;
$user-icon-blue-total-height: 314px;
$user-icon-blue-image: '../images/sprites/sprite.png';
$user-icon-blue: (308px, 149px, -308px, -149px, 17px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'user-icon-blue', );
$user-icon-white-name: 'user-icon-white';
$user-icon-white-x: 308px;
$user-icon-white-y: 133px;
$user-icon-white-offset-x: -308px;
$user-icon-white-offset-y: -133px;
$user-icon-white-width: 17px;
$user-icon-white-height: 16px;
$user-icon-white-total-width: 332px;
$user-icon-white-total-height: 314px;
$user-icon-white-image: '../images/sprites/sprite.png';
$user-icon-white: (308px, 133px, -308px, -133px, 17px, 16px, 332px, 314px, '../images/sprites/sprite.png', 'user-icon-white', );
$visa-logo-name: 'visa-logo';
$visa-logo-x: 137px;
$visa-logo-y: 202px;
$visa-logo-offset-x: -137px;
$visa-logo-offset-y: -202px;
$visa-logo-width: 63px;
$visa-logo-height: 19px;
$visa-logo-total-width: 332px;
$visa-logo-total-height: 314px;
$visa-logo-image: '../images/sprites/sprite.png';
$visa-logo: (137px, 202px, -137px, -202px, 63px, 19px, 332px, 314px, '../images/sprites/sprite.png', 'visa-logo', );
$visa-master-wallet-small-name: 'visa-master-wallet-small';
$visa-master-wallet-small-x: 0px;
$visa-master-wallet-small-y: 202px;
$visa-master-wallet-small-offset-x: 0px;
$visa-master-wallet-small-offset-y: -202px;
$visa-master-wallet-small-width: 75px;
$visa-master-wallet-small-height: 25px;
$visa-master-wallet-small-total-width: 332px;
$visa-master-wallet-small-total-height: 314px;
$visa-master-wallet-small-image: '../images/sprites/sprite.png';
$visa-master-wallet-small: (0px, 202px, 0px, -202px, 75px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'visa-master-wallet-small', );
$visa-master-wallet-name: 'visa-master-wallet';
$visa-master-wallet-x: 0px;
$visa-master-wallet-y: 0px;
$visa-master-wallet-offset-x: 0px;
$visa-master-wallet-offset-y: 0px;
$visa-master-wallet-width: 132px;
$visa-master-wallet-height: 44px;
$visa-master-wallet-total-width: 332px;
$visa-master-wallet-total-height: 314px;
$visa-master-wallet-image: '../images/sprites/sprite.png';
$visa-master-wallet: (0px, 0px, 0px, 0px, 132px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'visa-master-wallet', );
$vk-blue-name: 'vk-blue';
$vk-blue-x: 228px;
$vk-blue-y: 227px;
$vk-blue-offset-x: -228px;
$vk-blue-offset-y: -227px;
$vk-blue-width: 38px;
$vk-blue-height: 38px;
$vk-blue-total-width: 332px;
$vk-blue-total-height: 314px;
$vk-blue-image: '../images/sprites/sprite.png';
$vk-blue: (228px, 227px, -228px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'vk-blue', );
$vk-dark-hover-name: 'vk-dark-hover';
$vk-dark-hover-x: 270px;
$vk-dark-hover-y: 0px;
$vk-dark-hover-offset-x: -270px;
$vk-dark-hover-offset-y: 0px;
$vk-dark-hover-width: 38px;
$vk-dark-hover-height: 38px;
$vk-dark-hover-total-width: 332px;
$vk-dark-hover-total-height: 314px;
$vk-dark-hover-image: '../images/sprites/sprite.png';
$vk-dark-hover: (270px, 0px, -270px, 0px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'vk-dark-hover', );
$vk-dark-name: 'vk-dark';
$vk-dark-x: 270px;
$vk-dark-y: 38px;
$vk-dark-offset-x: -270px;
$vk-dark-offset-y: -38px;
$vk-dark-width: 38px;
$vk-dark-height: 38px;
$vk-dark-total-width: 332px;
$vk-dark-total-height: 314px;
$vk-dark-image: '../images/sprites/sprite.png';
$vk-dark: (270px, 38px, -270px, -38px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'vk-dark', );
$vk-light-hover-name: 'vk-light-hover';
$vk-light-hover-x: 270px;
$vk-light-hover-y: 114px;
$vk-light-hover-offset-x: -270px;
$vk-light-hover-offset-y: -114px;
$vk-light-hover-width: 38px;
$vk-light-hover-height: 38px;
$vk-light-hover-total-width: 332px;
$vk-light-hover-total-height: 314px;
$vk-light-hover-image: '../images/sprites/sprite.png';
$vk-light-hover: (270px, 114px, -270px, -114px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'vk-light-hover', );
$vk-light-name: 'vk-light';
$vk-light-x: 0px;
$vk-light-y: 227px;
$vk-light-offset-x: 0px;
$vk-light-offset-y: -227px;
$vk-light-width: 38px;
$vk-light-height: 38px;
$vk-light-total-width: 332px;
$vk-light-total-height: 314px;
$vk-light-image: '../images/sprites/sprite.png';
$vk-light: (0px, 227px, 0px, -227px, 38px, 38px, 332px, 314px, '../images/sprites/sprite.png', 'vk-light', );
$vkontakte-small-icon-name: 'vkontakte-small-icon';
$vkontakte-small-icon-x: 148px;
$vkontakte-small-icon-y: 142px;
$vkontakte-small-icon-offset-x: -148px;
$vkontakte-small-icon-offset-y: -142px;
$vkontakte-small-icon-width: 30px;
$vkontakte-small-icon-height: 14px;
$vkontakte-small-icon-total-width: 332px;
$vkontakte-small-icon-total-height: 314px;
$vkontakte-small-icon-image: '../images/sprites/sprite.png';
$vkontakte-small-icon: (148px, 142px, -148px, -142px, 30px, 14px, 332px, 314px, '../images/sprites/sprite.png', 'vkontakte-small-icon', );
$webmoney-small-name: 'webmoney-small';
$webmoney-small-x: 188px;
$webmoney-small-y: 132px;
$webmoney-small-offset-x: -188px;
$webmoney-small-offset-y: -132px;
$webmoney-small-width: 25px;
$webmoney-small-height: 25px;
$webmoney-small-total-width: 332px;
$webmoney-small-total-height: 314px;
$webmoney-small-image: '../images/sprites/sprite.png';
$webmoney-small: (188px, 132px, -188px, -132px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'webmoney-small', );
$webmoney-name: 'webmoney';
$webmoney-x: 188px;
$webmoney-y: 44px;
$webmoney-offset-x: -188px;
$webmoney-offset-y: -44px;
$webmoney-width: 44px;
$webmoney-height: 44px;
$webmoney-total-width: 332px;
$webmoney-total-height: 314px;
$webmoney-image: '../images/sprites/sprite.png';
$webmoney: (188px, 44px, -188px, -44px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'webmoney', );
$yandex-small-name: 'yandex-small';
$yandex-small-x: 78px;
$yandex-small-y: 265px;
$yandex-small-offset-x: -78px;
$yandex-small-offset-y: -265px;
$yandex-small-width: 25px;
$yandex-small-height: 25px;
$yandex-small-total-width: 332px;
$yandex-small-total-height: 314px;
$yandex-small-image: '../images/sprites/sprite.png';
$yandex-small: (78px, 265px, -78px, -265px, 25px, 25px, 332px, 314px, '../images/sprites/sprite.png', 'yandex-small', );
$yandex-name: 'yandex';
$yandex-x: 188px;
$yandex-y: 88px;
$yandex-offset-x: -188px;
$yandex-offset-y: -88px;
$yandex-width: 44px;
$yandex-height: 44px;
$yandex-total-width: 332px;
$yandex-total-height: 314px;
$yandex-image: '../images/sprites/sprite.png';
$yandex: (188px, 88px, -188px, -88px, 44px, 44px, 332px, 314px, '../images/sprites/sprite.png', 'yandex', );
$spritesheet-width: 332px;
$spritesheet-height: 314px;
$spritesheet-image: '../images/sprites/sprite.png';
$spritesheet-sprites: ($alfaclick-small, $alfaclick, $arrow-blue, $arrow-dark, $arrow-filter-blue, $arrow-filter, $arrow-light-small, $arrow-light, $arrow, $bell, $cash-money-small, $cash-money, $checbox-checked-dark, $checbox-checked-light, $chip, $clock-dark-icon, $clock-light-icon, $close-large, $close-light-big, $close-light-small, $close, $diagram-icon, $easy-icon, $facebook-small-icon, $fast-icon, $fb-blue, $fb-dark-hover, $fb-dark, $fb-light-hover, $fb-light, $footnote, $google-plus-dark-hover, $google-plus-dark, $google-plus-light-hover, $google-plus-light, $google-plus-red, $headset, $i, $invite-icon, $mastercard-logo, $news-arrow, $ok-dark-hover, $ok-dark, $ok-light-hover, $ok-light, $ok-orange, $p24-small, $p24, $paper-plane, $plus-blue, $plus-little, $plus-white, $qiwi-small, $qiwi, $realible-icon, $rectangle-blue, $rectangle-dark, $rectangle, $rsb-small, $rsb, $sber-small, $sber, $select-arrow, $select-arrow-dark, $upc-small, $upc, $user-icon-blue, $user-icon-white, $visa-logo, $visa-master-wallet-small, $visa-master-wallet, $vk-blue, $vk-dark-hover, $vk-dark, $vk-light-hover, $vk-light, $vkontakte-small-icon, $webmoney-small, $webmoney, $yandex-small, $yandex, );
$spritesheet: (332px, 314px, '../images/sprites/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
