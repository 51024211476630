@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
body .preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 112px;
  margin-left: -50px;
  margin-top: -56px;
  display: block; }
  body .preloader .st0 {
    display: none; }
  body .preloader .st3,
  body .preloader .st4,
  body .preloader .st5 {
    fill: none;
    stroke: #fff; }
  body .preloader .st3 {
    stroke-dasharray: 200;
    animation: step-1 3s linear infinite; }
  body .preloader .st4 {
    stroke-dasharray: 100%;
    animation: step-2 3s linear infinite; }
  body .preloader .st5 {
    stroke-dasharray: 108%;
    animation: step-3 3s linear infinite; }

body:not(.ie_app) .preloader .st3 {
  stroke-dashoffset: 200; }

body:not(.ie_app) .preloader .st4 {
  stroke-dashoffset: 100%; }

body:not(.ie_app) .preloader .st5 {
  stroke-dashoffset: 108%; }

body.ie_app .preloader {
  animation: ie_animation 1s linear infinite alternate; }
  body.ie_app .preloader .st3, body.ie_app .preloader .st4, body.ie_app .preloader .st5 {
    stroke-dashoffset: 0; }

.box_preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #5c9dff;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear; }
  .box_preloader.show_preloader {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear; }

@keyframes step-1 {
  0% {
    stroke-dashoffset: 200; }
  75% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes step-2 {
  0% {
    stroke-dashoffset: 100%; }
  75% {
    stroke-dashoffset: 0%; }
  100% {
    stroke-dashoffset: 0%; } }

@keyframes step-3 {
  0% {
    stroke-dashoffset: 108%; }
  75% {
    stroke-dashoffset: 0%; }
  100% {
    stroke-dashoffset: 0%; } }

@keyframes ie_animation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@font-face {
  font-family: "raleway_regular";
  src: url("../fonts/raleway_regular/raleway_regular.eot");
  /* IE9*/
  src: url("../fonts/raleway_regular/raleway_regular.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway_regular/raleway_regular.ttf") format("truetype"), url("../fonts/raleway_regular/raleway_regular.woff2") format("woff2"), url("../fonts/raleway_regular/raleway_regular.woff") format("woff"), url("../fonts/raleway_regular/raleway_regular.svg#raleway_regular") format("svg");
  /* iOS 4.1- */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "raleway_medium";
  src: url("../fonts/raleway_medium/raleway_medium.eot");
  src: url("../fonts/raleway_medium/raleway_medium.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway_medium/raleway_medium.woff") format("woff"), url("../fonts/raleway_medium/raleway_medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "raleway_bold";
  src: url("../fonts/raleway_bold/raleway_bold.eot");
  src: url("../fonts/raleway_bold/raleway_bold.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway_bold/raleway_bold.woff") format("woff"), url("../fonts/raleway_bold/raleway_bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "raleway_semibold";
  src: url("../fonts/raleway_semibold/raleway_semibold.eot");
  src: url("../fonts/raleway_semibold/raleway_semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway_semibold/raleway_semibold.woff") format("woff"), url("../fonts/raleway_semibold/raleway_semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'raleway_thin';
  src: url("../fonts/raleway_thin/raleway_thin.eot");
  src: url("../fonts/raleway_thin/raleway_thin.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway_thin/raleway_thin.ttf") format("truetype"), url("../fonts/raleway_thin/raleway_thin.woff2") format("woff2"), url("../fonts/raleway_thin/raleway_thin.woff") format("woff"), url("../fonts/raleway_thin/raleway_thin.svg#raleway_thin") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "raleway_light";
  src: url("../fonts/raleway_light/raleway_light.eot");
  src: url("../fonts/raleway_light/raleway_light.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway_light/raleway_light.woff") format("woff"), url("../fonts/raleway_light/raleway_light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "whitney_book";
  src: url("../fonts/whitney_book/whitney_book.eot");
  src: url("../fonts/whitney_book/whitney_book.eot?#iefix") format("embedded-opentype"), url("../fonts/whitney_book/whitney_book.woff") format("woff"), url("../fonts/whitney_book/whitney_book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "whitney_semibold";
  src: url("../fonts/whitney_semibold/whitney_semibold.eot");
  src: url("../fonts/whitney_semibold/whitney_semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/whitney_semibold/whitney_semibold.woff") format("woff"), url("../fonts/whitney_semibold/whitney_semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "avenir_roman";
  src: url("../fonts/avenir_roman/avenir_roman.eot");
  src: url("../fonts/avenir_roman/avenir_roman.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir_roman/avenir_roman.ttf") format("truetype"), url("../fonts/avenir_roman/avenir_roman.woff2") format("woff2"), url("../fonts/avenir_roman/avenir_roman.woff") format("woff"), url("../fonts/avenir_roman/avenir_roman.svg#avenir_roman") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "avenir_medium";
  src: url("../fonts/avenir_medium/avenir_medium.eot");
  src: url("../fonts/avenir_medium/avenir_medium.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir_medium/avenir_medium.woff") format("woff"), url("../fonts/avenir_medium/avenir_medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

body {
  overflow-x: hidden;
  font-family: raleway_regular, Arial, sans-serif;
  font-size: 14px;
  color: #4b4b4b;
  background: #4b4b4b; }

* {
  outline: none; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

#main {
  overflow: hidden;
  background-color: #fff;
  transform-origin: center bottom 0px;
  transition: all 0.7s ease 0s;
  box-shadow: 0px 22px 54px rgba(0, 0, 0, 0.5); }
  #main.scaled {
    transform: translateY(-60px) scale(0.9); }
  #main > * {
    position: relative;
    z-index: 1; }
  #main.pseudo_showed {
    position: relative;
    z-index: 15; }

.slide {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative; }
  .slide video {
    min-height: 100%;
    width: auto;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  background: transparent;
  background-color: transparent;
  cursor: pointer;
  font-family: raleway_medium, Arial, sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1); }
  .btn:after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    color: #3385ff;
    transform: translate3d(0, 25%, 0); }
  .btn span {
    display: block;
    width: 100%;
    text-align: center; }
  .btn:after, .btn > span {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1); }
  .btn:hover {
    background-color: #fff; }
    .btn:hover:after {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .btn:hover span {
      opacity: 0;
      transform: translate3d(0, -25%, 0); }
    .btn:hover.btn__fill {
      background-color: transparent; }
  .btn__xs {
    width: 95px;
    height: 36px;
    border-radius: 18px;
    font-size: 12px;
    text-transform: uppercase; }
  .btn__small {
    width: 165px;
    height: 46px;
    border-radius: 23px;
    font-size: 14px; }
  .btn__medium {
    width: 203px;
    height: 46px;
    border-radius: 23px;
    font-size: 14px; }
  .btn__large {
    width: 240px;
    height: 46px;
    border-radius: 23px;
    font-size: 14px; }
  .btn__white {
    color: #fff;
    border-color: #fff; }
    .btn__white:after {
      color: #3385ff; }
    .btn__white:hover {
      background-color: #fff; }
    .btn__white.btn__fill {
      background-color: #fff;
      color: #3385ff; }
      .btn__white.btn__fill:after {
        color: #fff; }
  .btn__blue {
    color: #3385ff;
    border-color: #3385ff; }
    .btn__blue:after {
      color: #fff; }
    .btn__blue:hover {
      background-color: #3385ff; }
    .btn__blue.btn__fill {
      background-color: #3385ff;
      color: #fff; }
      .btn__blue.btn__fill:after {
        color: #3385ff; }
  .btn__dark {
    border-color: #777;
    color: #777; }
    .btn__dark:after {
      color: #fff; }
    .btn__dark:hover {
      background-color: #777; }

.round_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #3385ff;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: none; }
  .round_btn span {
    background-image: url(../images/sprites/sprite.png);
    background-position: -308px -181px;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -8px; }
  .round_btn:after {
    content: '';
    background-image: url(../images/sprites/sprite.png);
    background-position: -308px -197px;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px; }

.fields_container .title_fields {
  display: block;
  font-family: raleway_medium, Arial, sans-serif;
  font-size: 21px;
  margin-bottom: 10px; }

.few_fields {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px; }
  .few_fields > *:not(:last-child) {
    margin-right: 40px; }
  .few_fields > *:not(.custom_select) {
    margin-bottom: 20px; }
    .few_fields > *:not(.custom_select):last-child {
      margin-bottom: 20px; }
  .few_fields .label_field:last-child {
    margin-bottom: 20px; }
  .few_fields .title_fields {
    align-self: center; }
  .few_fields.captcha .label_field {
    width: 50%; }
  .few_fields.captcha .input_field {
    width: 25%;
    font-family: raleway_regular, Arial, sans-serif;
    padding: 0 10px 2px 5px;
    box-sizing: border-box; }
  .few_fields.captcha .btn {
    margin-bottom: 0; }

.label_field {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 19px;
  padding: 7px 0;
  margin-bottom: 25px;
  position: relative; }
  .label_field:last-child {
    margin-bottom: 0; }
  .label_field .field_title {
    padding: 0 5px 6px 0;
    white-space: nowrap; }
  .label_field.invalid_field .border_line:after {
    transform: translateX(0);
    background: rgba(254, 139, 139, 0.8); }
  .label_field .border_line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.8);
    overflow: hidden; }
    .label_field .border_line:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: transparent;
      transform: translateX(-100%);
      transition: transform .5s; }

.invalid_field .error_text {
  opacity: 1; }

.error_text {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: #fe8b8b;
  font-size: 14px;
  font-family: raleway_light, Arial, sans-serif;
  opacity: 0;
  transition: opacity .3s;
  line-height: 1.5; }

.input_field {
  border: none;
  background: transparent;
  height: auto;
  color: #fff;
  font-size: 32px;
  font-family: whitney_book;
  text-align: right;
  line-height: 1;
  width: 100%; }

.custom_select {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  width: 110px; }
  .custom_select .selected_val {
    padding: 10px 28px 10px 4px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    position: relative;
    cursor: pointer;
    text-align: center; }
    .custom_select .selected_val:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      background-image: url(../images/sprites/sprite.png);
      background-position: -213px -132px;
      width: 14px;
      height: 8px;
      margin-top: 1px;
      transition: transform .2s; }
  .custom_select__list {
    display: none;
    position: absolute;
    z-index: 4;
    top: 100%;
    right: 0;
    opacity: 0;
    transition: opacity .5s;
    background: #fff;
    box-shadow: 7px 7px 57px 0px rgba(66, 66, 66, 0.3);
    border-radius: 5px; }
    .custom_select__list.one_col {
      box-sizing: border-box; }
      .custom_select__list.one_col > * {
        width: 100%;
        padding: 5px 0; }
    .custom_select__list.two_col {
      flex-wrap: wrap;
      padding: 5px 5px 5px 40px;
      box-sizing: border-box; }
      .custom_select__list.two_col > * {
        width: 50%;
        padding: 5px; }
    .custom_select__list.three_col {
      flex-wrap: wrap;
      padding: 5px 5px 5px 40px;
      box-sizing: border-box; }
      .custom_select__list.three_col > * {
        width: 33.333%;
        padding: 10px; }
    .custom_select__list.few_col {
      margin-top: 7px;
      width: 312px;
      background: #fff;
      border-radius: 8px;
      padding: 10px;
      box-sizing: border-box;
      color: #3385ff;
      flex-wrap: wrap;
      left: 50%;
      transform: translateX(-50%); }
      .custom_select__list.few_col > * {
        width: 50%;
        padding: 10px;
        box-sizing: border-box; }
      .custom_select__list.few_col.little {
        max-width: 180px; }
    .custom_select__list > * {
      box-sizing: border-box;
      cursor: pointer; }
      .custom_select__list > *.selected span {
        border-bottom: 1px solid rgba(51, 133, 255, 0.8); }
  .custom_select.elem_droped .selected_val:after {
    transform: translateY(-50%) rotateX(180deg); }
  .custom_select.elem_droped .custom_select__list {
    display: inline-block;
    opacity: 1; }
    .custom_select.elem_droped .custom_select__list.few_col, .custom_select.elem_droped .custom_select__list.two_col, .custom_select.elem_droped .custom_select__list.three_col {
      display: flex; }
  .custom_select.right_pos .custom_select__list {
    left: auto;
    right: 0;
    transform: translate(0); }
  .custom_select.with_title {
    width: 100%; }
    .custom_select.with_title .selected_val {
      font-family: whitney_book;
      font-size: 18px;
      padding: 10px 28px;
      text-align: right; }
    .custom_select.with_title .custom_select__list {
      font-family: whitney_book;
      font-size: 18px; }
      .custom_select.with_title .custom_select__list.one_col {
        padding: 10px 28px; }
    .custom_select.with_title .title {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0 4px 0 0;
      height: 51px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-size: 16px;
      color: rgba(75, 75, 75, 0.8);
      display: flex;
      align-items: center; }

.text_explain {
  font-size: 11px; }

.checkbox_container,
.radio_container {
  position: relative; }
  .checkbox_container .error_text,
  .radio_container .error_text {
    padding-left: 24px;
    box-sizing: border-box; }

input[type="checkbox"],
input[type="radio"] {
  display: none; }
  input[type="checkbox"]:checked + label:after,
  input[type="radio"]:checked + label:after {
    display: block;
    opacity: 1; }

.label_checkbox,
.label_radio {
  font-family: raleway_light, Arial, sans-serif;
  font-size: 14px;
  position: relative;
  padding-left: 24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: block; }
  .label_checkbox:before, .label_checkbox:after,
  .label_radio:before,
  .label_radio:after {
    content: '';
    position: absolute;
    top: 0; }
  .label_checkbox:before,
  .label_radio:before {
    left: 0;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-sizing: border-box; }
  .label_checkbox:after,
  .label_radio:after {
    left: 9px;
    top: 9px;
    transform: translate(-50%, -50%);
    margin-left: 1px;
    display: none;
    background-image: url(../images/sprites/sprite.png);
    background-position: -324px -173px;
    width: 8px;
    height: 8px;
    opacity: 0;
    transition: opacity 0.3s; }

.middle_field .label_field {
  font-size: 16px;
  margin-bottom: 30px; }

.middle_field .custom_select {
  font-size: 16px;
  margin-bottom: 30px; }

.small_field .label_field {
  font-size: 13px;
  margin-bottom: 20px; }

.small_field .input_field {
  font-size: 21px; }

.small_field .label_checkbox,
.small_field .label_radio {
  font-size: 11px; }

.small_field .few_fields > * {
  margin-bottom: 20px; }

.small_field .few_fields > *:last-child {
  margin-bottom: 20px; }

.small_field .few_fields .label_field:last-child {
  margin-bottom: 20px; }

.dark__fields .error_text {
  color: #ff0101; }

.dark__fields .label_field {
  color: rgba(75, 75, 75, 0.8); }
  .dark__fields .label_field .border_line {
    background: rgba(75, 75, 75, 0.8); }
  .dark__fields .label_field.invalid_field .border_line:after {
    transform: translateX(0);
    background: rgba(255, 1, 1, 0.8); }

.dark__fields .input_field {
  color: #4b4b4b; }

.dark__fields .custom_select .selected_val {
  border-bottom: 1px solid rgba(75, 75, 75, 0.8); }
  .dark__fields .custom_select .selected_val:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -213px -140px;
    width: 14px;
    height: 8px; }

.dark__fields .label_checkbox:before,
.dark__fields .label_radio:before {
  background: transparent;
  border-radius: 50%;
  border: 1px solid #4b4b4b; }

.dark__fields .label_checkbox:after,
.dark__fields .label_radio:after {
  left: 8px;
  transform: translate(-50%, -50%);
  display: none;
  background-image: url(../images/sprites/sprite.png);
  background-position: -324px -165px;
  width: 8px;
  height: 8px;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 1px; }

.textarea_field {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 50px; }
  .textarea_field__title {
    font-family: raleway_regular, Arial, sans-serif;
    color: rgba(75, 75, 75, 0.8);
    font-size: 16px;
    display: block;
    position: absolute;
    bottom: 12px;
    left: 0;
    transform-origin: 0 50%;
    transition: transform .5s;
    z-index: 3;
    width: 100%;
    cursor: text; }
  .textarea_field .custom_textarea {
    font-family: raleway_regular, Arial, sans-serif;
    color: #4b4b4b;
    font-size: 16px;
    line-height: 1.2;
    resize: none;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(75, 75, 75, 0.8);
    height: 77.6px;
    padding: 10px 0;
    box-sizing: border-box; }
    .textarea_field .custom_textarea:focus {
      border-bottom: 1px solid #3385ff; }
      .textarea_field .custom_textarea:focus + .textarea_field__title {
        transform: translateY(-75px);
        font-size: 14px;
        color: #3385ff; }
  .textarea_field.focus .custom_textarea {
    border-bottom: 1px solid #3385ff; }
  .textarea_field.focus .textarea_field__title {
    transform: translateY(-75px);
    font-size: 14px;
    color: #3385ff; }

.money, .number {
  font-family: whitney_book; }

.box__date {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 28px;
  font-family: whitney_book; }
  .box__date:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-50%); }
  .box__date.light:before {
    background-image: url(../images/sprites/sprite.png);
    background-position: -308px -259px;
    width: 14px;
    height: 14px; }
  .box__date.dark {
    color: #9e9e9e; }
    .box__date.dark:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -245px;
      width: 14px;
      height: 14px; }

.read_more {
  display: block;
  text-decoration: underline; }

.nomargin {
  margin: 0; }

.two_cols {
  overflow: hidden;
  display: flex;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto; }
  .two_cols > * {
    width: 50%; }
  .two_cols .col_bg {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat; }
  .two_cols .col_content {
    display: flex; }

.title_small {
  font-family: raleway_thin, Arial, sans-serif;
  font-size: 48px;
  color: #3385ff;
  line-height: 1; }

.title_big {
  font-family: raleway_thin, Arial, sans-serif;
  font-size: 98px;
  color: #3385ff;
  line-height: 1; }

.headset {
  position: fixed;
  z-index: 10;
  bottom: 55px;
  left: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 3px 3px 18px 0px #424242;
  cursor: pointer; }
  .headset:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: url(../images/sprites/sprite.png);
    background-position: -277px -265px;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%);
    transition: all .3s; }

.link_text {
  color: #3385ff;
  display: inline-block;
  padding-left: 3px; }

.semi_bold {
  font-family: raleway_semibold, Arial, sans-serif; }

.attention {
  padding-left: 25px;
  position: relative; }
  .attention:before {
    content: '!';
    color: #fff;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: #ff0101;
    border-radius: 50%;
    line-height: 1.2; }

.social_network {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px; }
  .social_network .social_network__item .social_icon {
    display: block;
    position: relative;
    cursor: pointer; }
    .social_network .social_network__item .social_icon:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .3s; }
    .social_network .social_network__item .social_icon:hover:after {
      opacity: 1; }
    .social_network .social_network__item .social_icon:hover.fb:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -114px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon:hover.vk:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px -114px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon:hover.ok:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: 0px -265px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon:hover.google-plus:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -114px -227px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon.fb {
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -152px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon.vk {
      background-image: url(../images/sprites/sprite.png);
      background-position: 0px -227px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon.ok {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px -76px;
      width: 38px;
      height: 38px; }
    .social_network .social_network__item .social_icon.google-plus {
      background-image: url(../images/sprites/sprite.png);
      background-position: -152px -227px;
      width: 38px;
      height: 38px; }
  .social_network.dark_social .social_network__item .social_icon {
    display: block; }
    .social_network.dark_social .social_network__item .social_icon.fb {
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -76px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon.vk {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px -38px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon.ok {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px -190px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon.google-plus {
      background-image: url(../images/sprites/sprite.png);
      background-position: -76px -227px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon:hover.fb:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -38px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon:hover.vk:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px 0px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon:hover.ok:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px -152px;
      width: 38px;
      height: 38px; }
    .social_network.dark_social .social_network__item .social_icon:hover.google-plus:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -38px -227px;
      width: 38px;
      height: 38px; }

.space_between {
  display: flex;
  justify-content: space-between; }

.flex_end {
  display: flex;
  justify-content: flex-end; }

.flex_center {
  display: flex;
  justify-content: center; }

.close_popup, .close_white {
  position: absolute;
  top: 35px;
  right: 35px;
  cursor: pointer;
  z-index: 1;
  background-image: url(../images/sprites/sprite.png);
  background-position: -308px 0px;
  width: 24px;
  height: 24px; }

.close_dark {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
  background-image: url(../images/sprites/sprite.png);
  background-position: -253px -265px;
  width: 24px;
  height: 24px; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
  .overlay_blue {
    background: rgba(51, 133, 255, 0.41); }
  .overlay_dark {
    background: rgba(99, 99, 99, 0.3); }

@media screen and (max-width: 1280px) {
  .headset {
    bottom: 30px;
    left: 30px; } }

@media screen and (max-width: 1023px) {
  .title_big {
    font-size: 45px; }
  .title_small {
    font-size: 45px; }
  .headset {
    bottom: 20px;
    left: 20px; }
  .label_field {
    margin-bottom: 20px;
    font-size: 14px;
    flex-direction: column-reverse;
    align-items: flex-start; }
    .label_field .field_title {
      padding-bottom: 0;
      transform: translateY(20px);
      transition: transform .5s; }
  .focus .field_title {
    transform: translateY(0); }
  .focus .border_line:after {
    transform: translateX(0);
    background: rgba(255, 255, 255, 0.8); }
  .dark__fields .focus .border_line:after {
    background: rgba(51, 133, 255, 0.8); }
  .dark__fields .input_field:focus + .field_title {
    color: #3385ff; }
  .dark__fields .input_field:focus ~ .border_line:after {
    background: rgba(51, 133, 255, 0.8); }
  .input_field {
    font-size: 18px; }
    .input_field:focus + .field_title {
      color: #fff;
      transform: translateY(0); }
    .input_field:focus ~ .border_line:after {
      transform: translateX(0);
      background: rgba(255, 255, 255, 0.8); }
  .custom_select__list.few_col {
    width: 230px;
    left: 0;
    transform: translateX(0); } }

@media screen and (max-width: 768px) {
  .middle_field .label_field {
    font-size: 14px; }
  .middle_field .custom_select {
    font-size: 14px; }
  .custom_select__list.two_col {
    padding: 5px; }
  .custom_select.with_title .selected_val {
    font-size: 14px;
    padding: 10px 28px 10px 0;
    letter-spacing: -.5px;
    line-height: 1; }
  .custom_select.with_title .custom_select__list {
    font-size: 14px; }
    .custom_select.with_title .custom_select__list.one_col {
      padding: 10px; }
  .custom_select.with_title .title {
    font-size: 14px;
    width: 100%;
    display: block;
    margin: 10px 0 0 0;
    position: relative;
    height: auto;
    line-height: 1; }
  .textarea_field {
    margin-bottom: 30px; }
    .textarea_field__title {
      font-size: 14px; }
    .textarea_field .custom_textarea {
      font-size: 14px;
      line-height: 1.4;
      height: 98px; }
      .textarea_field .custom_textarea:focus + .textarea_field__title {
        transform: translateY(-90px); }
    .textarea_field.focus .textarea_field__title {
      transform: translateY(-90px); }
  .few_fields.captcha {
    flex-wrap: wrap;
    justify-content: center; }
    .few_fields.captcha .label_field {
      width: 100%;
      margin-right: 0; }
    .few_fields.captcha .input_field {
      text-align: left; } }

@media screen and (max-width: 1023px) {
  .space_between {
    flex-direction: column;
    align-items: center; }
  .close_popup,
  .close_white {
    top: 20px;
    right: 20px; }
  .social_network {
    margin-left: -14px; } }

#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  color: #fff;
  transition: all .3s; }
  #header .close_menu {
    display: none; }
  #header .workSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 40px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto; }
  #header.fill {
    background-color: #fff;
    box-shadow: 7px 7px 57px 0px rgba(66, 66, 66, 0.3);
    color: #3385ff; }
    #header.fill .elem_anim:after {
      color: #4b4b4b; }
    #header.fill .logo {
      position: relative; }
      #header.fill .logo img {
        opacity: 0; }
      #header.fill .logo:after {
        content: '';
        z-index: 1;
        width: 107px;
        height: 31px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/logo-blue.png);
        background-repeat: no-repeat; }
    #header.fill .main_menu__more:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -213px;
      width: 15px;
      height: 16px; }
    #header.fill .btn__white {
      color: #3385ff;
      border-color: #3385ff; }
      #header.fill .btn__white:after {
        color: #fff; }
      #header.fill .btn__white:hover {
        background-color: #3385ff; }
      #header.fill .btn__white.btn__fill {
        background-color: #3385ff;
        color: #fff; }
        #header.fill .btn__white.btn__fill:after {
          color: #3385ff; }
    #header.fill #name_user:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -149px;
      width: 17px;
      height: 16px; }
    #header.fill .mob_menu_icon > * {
      background: #3385ff; }

.logo {
  display: block;
  align-self: flex-start; }

.main_menu {
  height: 100%;
  display: block;
  position: relative; }
  .main_menu .lang, .main_menu .overlay {
    display: none; }
  .main_menu__container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative; }
  .main_menu__item {
    display: block;
    padding: 10px 20px;
    box-sizing: border-box;
    cursor: pointer; }
  .main_menu__more {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer; }
    .main_menu__more:after {
      content: '';
      display: inline-block;
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -229px;
      width: 15px;
      height: 16px;
      margin-left: 10px;
      transition: transform .3s; }
    .main_menu__more.elem_droped:after {
      transform: rotateX(180deg); }
  .main_menu__sub {
    display: none;
    top: 100%;
    padding-right: 15px;
    box-sizing: border-box;
    width: auto; }
    .main_menu__sub.elem_active {
      display: flex;
      justify-content: flex-end; }

.user_menu {
  display: none; }

.header_right {
  display: flex;
  align-self: flex-start; }

.mob_menu_icon {
  display: none;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding-left: 7px;
  box-sizing: border-box; }
  .mob_menu_icon > * {
    display: block;
    width: 20px;
    height: 2px;
    background: #fff; }
    .mob_menu_icon > *:not(:last-child) {
      margin-bottom: 8px; }

#name_user {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer; }
  #name_user:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    background-image: url(../images/sprites/sprite.png);
    background-position: -308px -133px;
    width: 17px;
    height: 16px; }

.lang {
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 48px;
  display: block;
  display: flex;
  align-items: center; }

@media screen and (min-width: 1280px) {
  .elem_anim {
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden; }
    .elem_anim:after {
      content: attr(data-text);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      color: #b3d1ff;
      -webkit-transform: translate3d(0, 25%, 0);
      transform: translate3d(0, 25%, 0);
      padding: 0 20px;
      box-sizing: border-box; }
    .elem_anim > span {
      display: block; }
    .elem_anim:after, .elem_anim > span {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s, opacity 0.1s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1); }
    .elem_anim:hover:after {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .elem_anim:hover > span {
      opacity: 0;
      transform: translate3d(0, -25%, 0); } }

@media screen and (max-width: 1280px) {
  .lang {
    margin-left: 20px; }
  .main_menu__item {
    padding: 8px;
    font-size: 13px; }
  .elem_anim:after {
    padding: 8px; }
  #header .workSection {
    padding: 17px 30px; } }

@media screen and (max-width: 1023px) {
  #header .workSection {
    padding: 17px 20px; }
  #header .close_menu {
    display: block;
    top: 20px;
    right: 20px; }
  #header .more_menu {
    display: none; }
  #header.fill .main_menu {
    color: #fff; }
  .mob_menu_icon {
    display: flex; }
  .main_menu, .user_menu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    padding: 65px 0 20px 0;
    box-sizing: border-box;
    animation: hideMenu .3s;
    animation-fill-mode: forwards;
    /* or: backwards, both, none */ }
    .main_menu.show_el, .user_menu.show_el {
      animation: showMenu .3s; }
      .main_menu.show_el .overlay, .user_menu.show_el .overlay {
        display: block;
        opacity: 1;
        z-index: 0; }
      .main_menu.show_el .menu_container, .user_menu.show_el .menu_container {
        animation: showMenuContent .6s; }
    .main_menu .overlay, .user_menu .overlay {
      display: block;
      opacity: 0;
      z-index: -1;
      transition: all .9s; }
    .main_menu:before, .user_menu:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(51, 133, 255, 0.8); }
    .main_menu__container, .user_menu__container {
      flex-direction: column; }
    .main_menu__item, .user_menu__item {
      padding: 17px 35px;
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      text-align: center; }
    .main_menu__sub, .user_menu__sub {
      padding: 0;
      display: block;
      position: relative;
      top: 0;
      left: 0; }
    .main_menu .lang, .user_menu .lang {
      display: block;
      position: relative;
      z-index: 1;
      text-align: center;
      margin: 0;
      padding: 17px 35px; }
  .menu_container {
    height: 100%;
    overflow-y: auto;
    animation: hideMenuContent .3s;
    animation-fill-mode: forwards; }
  .header_right .lang {
    display: none; }
  .user_menu {
    display: block; }
    .user_menu__item {
      position: relative;
      z-index: 1;
      display: block;
      padding: 17px 35px;
      color: #fff; }
  #name_user span {
    display: none; } }

@keyframes showMenu {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

@keyframes showMenuContent {
  from {
    transform: translateX(80%); }
  to {
    transform: translateX(0); } }

@keyframes hideMenuContent {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(80%); } }

@keyframes hideMenu {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100%); } }

.footer {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  color: #fff;
  background-color: transparent;
  padding: 30px 50px 0 50px;
  box-sizing: border-box;
  transition: all .3s; }
  .footer .footer-services {
    padding-bottom: 60px; }
    .footer .footer-services .logo {
      float: left;
      width: 190px; }
      .footer .footer-services .logo img {
        width: 100%;
        height: auto;
        vertical-align: bottom; }
    .footer .footer-services .connection {
      float: right;
      width: 210px;
      text-align: center; }
      .footer .footer-services .connection .btn {
        margin: 0 auto 25px; }
    .footer .footer-services .social {
      font-size: 0; }
      .footer .footer-services .social li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px; }
        .footer .footer-services .social li:hover a.facebook:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -232px 0px;
          width: 38px;
          height: 38px; }
        .footer .footer-services .social li:hover a.vk:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -228px -227px;
          width: 38px;
          height: 38px; }
        .footer .footer-services .social li:hover a.ok:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -190px -227px;
          width: 38px;
          height: 38px; }
        .footer .footer-services .social li:hover a.google:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -148px -104px;
          width: 38px;
          height: 38px; }
      .footer .footer-services .social a {
        display: block;
        position: relative;
        width: 42px;
        height: 42px;
        background: #a5a5a5;
        border-radius: 50%; }
        .footer .footer-services .social a:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .footer .footer-services .social a.facebook:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -232px -152px;
          width: 38px;
          height: 38px; }
        .footer .footer-services .social a.vk:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: 0px -227px;
          width: 38px;
          height: 38px; }
        .footer .footer-services .social a.ok:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -270px -76px;
          width: 38px;
          height: 38px; }
        .footer .footer-services .social a.google:after {
          background-image: url(../images/sprites/sprite.png);
          background-position: -152px -227px;
          width: 38px;
          height: 38px; }
    .footer .footer-services .menu {
      margin: 0 210px 0 190px;
      padding: 0 10px;
      box-sizing: border-box; }
      .footer .footer-services .menu .menu_inner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start; }
      .footer .footer-services .menu ul {
        max-width: 50%;
        box-sizing: border-box; }
        .footer .footer-services .menu ul:first-child {
          padding-right: 27px; }
        .footer .footer-services .menu ul:last-child {
          padding-left: 27px; }
      .footer .footer-services .menu li {
        padding-bottom: 5px;
        font-family: raleway_thin, Arial, sans-serif;
        cursor: pointer; }
        .footer .footer-services .menu li:nth-child(1), .footer .footer-services .menu li:nth-child(2) {
          padding-bottom: 10px;
          font-family: raleway_medium, Arial, sans-serif; }
      .footer .footer-services .menu a, .footer .footer-services .menu span {
        font-size: 14px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        line-height: 1.3; }
        .footer .footer-services .menu a:hover, .footer .footer-services .menu span:hover {
          text-decoration: underline; }
  .footer .footer-payment {
    font-size: 0;
    background-color: #353535;
    position: relative;
    padding: 20px 0 15px; }
    .footer .footer-payment:before, .footer .footer-payment:after {
      content: "";
      display: block;
      background-color: #353535;
      width: 10000000000px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1; }
    .footer .footer-payment:before {
      left: 99%; }
    .footer .footer-payment:after {
      right: 99%; }
    .footer .footer-payment .accreditation,
    .footer .footer-payment .accreditation-yandex {
      display: inline-block;
      vertical-align: top; }
    .footer .footer-payment .accreditation a {
      width: 95px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 15px; }
    .footer .footer-payment .accreditation img {
      width: 100%;
      height: auto;
      vertical-align: bottom; }
    .footer .footer-payment .accreditation-yandex {
      width: 160px; }
      .footer .footer-payment .accreditation-yandex p {
        padding-bottom: 7px; }
      .footer .footer-payment .accreditation-yandex p,
      .footer .footer-payment .accreditation-yandex span {
        font-size: 11px;
        color: #7b7b7b;
        font-family: raleway_medium, Arial, sans-serif; }
      .footer .footer-payment .accreditation-yandex span, .footer .footer-payment .accreditation-yandex img {
        display: inline-block;
        vertical-align: middle; }
      .footer .footer-payment .accreditation-yandex img {
        width: 93px;
        padding-right: 7px; }

@media screen and (max-width: 1024px) {
  .footer {
    padding: 30px 20px 0 20px; }
    .footer .footer-services {
      padding-bottom: 30px; }
      .footer .footer-services .logo {
        width: 170px; }
      .footer .footer-services .connection .btn {
        margin: 0 auto 20px; }
      .footer .footer-services .menu {
        margin: 0 210px 0 170px; }
        .footer .footer-services .menu ul:first-child {
          padding-right: 10px; }
        .footer .footer-services .menu ul:last-child {
          padding-left: 10px; } }

@media screen and (max-width: 850px) {
  .footer {
    padding: 25px 20px 0 20px; }
    .footer .footer-services {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 25px; }
      .footer .footer-services .logo {
        float: none;
        order: 1;
        align-self: center;
        width: 35%;
        min-width: 110px;
        max-width: 170px;
        padding-bottom: 30px; }
      .footer .footer-services .menu {
        margin: 0;
        padding: 0;
        order: 2;
        padding-bottom: 20px; }
        .footer .footer-services .menu .menu_inner {
          flex-direction: column;
          align-items: center;
          text-align: center; }
        .footer .footer-services .menu ul {
          max-width: inherit;
          padding-bottom: 5px; }
          .footer .footer-services .menu ul:first-child {
            padding-right: 0; }
          .footer .footer-services .menu ul:last-child {
            padding-left: 0; }
        .footer .footer-services .menu li {
          padding-bottom: 10px; }
          .footer .footer-services .menu li:nth-child(1), .footer .footer-services .menu li:nth-child(2) {
            padding-bottom: 15px; }
        .footer .footer-services .menu a, .footer .footer-services .menu span {
          line-height: 1.2; }
      .footer .footer-services .connection {
        float: none;
        order: 3; }
        .footer .footer-services .connection .btn {
          margin: 0 auto 30px; }
    .footer .footer-payment {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0; }
      .footer .footer-payment .accreditation {
        order: 2; }
        .footer .footer-payment .accreditation a {
          width: 70px;
          margin-right: 10px; }
      .footer .footer-payment .accreditation-yandex {
        order: 1;
        padding-bottom: 10px; }
        .footer .footer-payment .accreditation-yandex img {
          width: 90px; } }

.slide1 {
  color: #fff;
  padding: 70px 0 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background-image: url(../video/woman_cellphone.png);
  background-size: cover;
  background-repeat: no-repeat; }
  .slide1 .btns_box {
    display: none; }
  .slide1 video {
    z-index: 0;
    opacity: 0;
    animation-name: video-animation;
    animation-timing-function: linear;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-delay: .1s; }
  .slide1:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 100%;
    height: 100%;
    background-color: #3385ff;
    opacity: 0.8;
    z-index: -1;
    max-width: 724px; }
  .slide1:after {
    z-index: 0;
    transform: translateX(100%);
    animation-name: right-elem-anim;
    animation-timing-function: linear;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-delay: .2s; }
  .slide1:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #a4a0bb;
    opacity: .2;
    z-index: 2; }
  .slide1 > * {
    z-index: 2; }

.main_title {
  transform: translateX(-100%);
  animation-name: title-animation;
  animation-timing-function: linear;
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-delay: .2s; }

.container_tabs {
  transform: translateX(100%);
  animation-name: right-elem-anim2;
  animation-timing-function: linear;
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-delay: .2s; }

@keyframes video-animation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes right-elem-anim {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

@keyframes right-elem-anim2 {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

@keyframes title-animation {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }

.centering_container {
  display: flex;
  justify-content: flex-end; }
  .centering_container .main_title br {
    display: none; }

.main_title {
  font-family: raleway_thin, Arial, sans-serif;
  font-size: 94px;
  width: 45%;
  line-height: .87;
  max-width: calc(100% - 750px); }

.container_tabs {
  width: 750px;
  padding-right: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .container_tabs .label_checkbox {
    margin-bottom: 10px; }
  .container_tabs .fields_container {
    margin-bottom: 30px; }

.action_tabs {
  align-self: flex-end;
  display: inline-block;
  position: relative;
  width: auto;
  font-family: raleway_light, Arial, sans-serif;
  color: #fefeff;
  font-size: 28px;
  margin-bottom: 46px;
  width: 100%; }
  .action_tabs:before {
    content: '';
    position: absolute;
    border-bottom: 1px solid rgba(227, 234, 239, 0.5);
    top: 100%;
    left: 0;
    width: 10000px; }
  .action_tabs__item {
    padding-bottom: 5px;
    position: relative;
    display: inline-block;
    opacity: .4;
    cursor: pointer;
    transition: all .3s; }
    .action_tabs__item:not(:last-child) {
      margin-right: 25px;
      box-sizing: border-box; }
    .action_tabs__item:after {
      content: '';
      width: 0;
      position: absolute;
      top: 100%;
      left: 0;
      border-bottom: 2px solid #e3eaef;
      transition: width .4s; }
    .action_tabs__item.active_tab {
      color: #fff;
      opacity: 1; }
      .action_tabs__item.active_tab:after {
        width: 100%; }

.tab_content {
  display: none;
  width: 100%; }
  .tab_content.show_tab {
    display: block; }

.info_about_it {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .info_about_it__title {
    font-family: raleway_medium, Arial, sans-serif;
    font-size: 18px;
    display: block;
    padding-bottom: 10px; }
  .info_about_it__text {
    font-family: whitney_book;
    font-size: 20px;
    display: block; }

.info_container {
  display: flex; }
  .info_container > *:not(:last-child) {
    margin-right: 34px; }

.less_width {
  max-width: 89%; }

.container_card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px; }
  .container_card .flip-container {
    margin-right: 25px;
    margin-bottom: 0; }
  .container_card .fields_container {
    margin-bottom: 0;
    width: calc(100% - 295px); }
    .container_card .fields_container > * {
      margin-bottom: 14px; }
      .container_card .fields_container > *:last-child {
        margin-bottom: 0; }
  .container_card__info {
    display: flex;
    justify-content: flex-end; }
    .container_card__info .left_side > * {
      margin-bottom: 5px; }
      .container_card__info .left_side > *:last-child {
        margin: 10px 0 0 0; }

.benefits {
  padding: 55px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  line-height: 1.7;
  color: #9e9e9e;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }
  .benefits__item {
    width: 33.33333%;
    padding: 0 10px;
    box-sizing: border-box; }
  .benefits__title {
    color: #4b4b4b;
    font-size: 24px;
    display: block;
    margin-bottom: 20px;
    padding-top: 65px;
    position: relative;
    line-height: 1.3; }
    .benefits__title:before {
      content: '';
      position: absolute;
      top: 0;
      left: 2px; }
    .benefits__title.easy:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -56px -44px;
      width: 58px;
      height: 54px; }
    .benefits__title.fast:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: 0px -104px;
      width: 54px;
      height: 54px; }
    .benefits__title.realible:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -106px -104px;
      width: 42px;
      height: 54px; }

.main_reviews {
  position: relative;
  color: #fff;
  padding: 55px 40px;
  box-sizing: border-box; }
  .main_reviews > * {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto; }
  .main_reviews:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/bg/review-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1; }
  .main_reviews:after {
    content: '';
    position: absolute;
    top: 0;
    right: auto;
    left: -50000px;
    width: 100000px;
    height: 100%;
    background-color: #3385ff;
    opacity: 0.8;
    z-index: -1;
    max-width: none; }
  .main_reviews .container_slider {
    padding-top: 65px; }
    .main_reviews .container_slider__title {
      margin-top: -65px; }
  .main_reviews .main__slider {
    margin-bottom: 55px; }

.container_slider {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex; }
  .container_slider__left {
    width: 70%;
    width: calc(100% - 215px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    overflow: hidden; }
  .container_slider__rigth {
    width: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 0;
    text-align: right; }
  .container_slider__title {
    text-align: right;
    font-family: raleway_thin, Arial, sans-serif;
    font-size: 48px; }
  .container_slider__btns {
    display: flex;
    justify-content: flex-end;
    width: 100%; }
    .container_slider__btns > * {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.5);
      cursor: pointer;
      transition: all .3s;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .container_slider__btns > *:before {
        content: '';
        display: block;
        background-image: url(../images/sprites/sprite.png);
        background-position: -308px -229px;
        width: 15px;
        height: 16px;
        transition: margin-top .1s; }
      .container_slider__btns > *.prev_btn {
        transform: rotateZ(90deg); }
      .container_slider__btns > *.next_btn {
        transform: rotateZ(-90deg); }
      .container_slider__btns > *:not(:last-child) {
        margin-right: 10px; }
      .container_slider__btns > *:hover {
        border-color: white; }
        .container_slider__btns > *:hover:before {
          margin-top: 3px; }
    .container_slider__btns.dark > * {
      border: 1px solid rgba(75, 75, 75, 0.5); }
      .container_slider__btns.dark > *:before {
        background-image: url(../images/sprites/sprite.png);
        background-position: -308px -165px;
        width: 16px;
        height: 16px; }
      .container_slider__btns.dark > *:hover {
        border-color: #4b4b4b; }

.main__slider {
  display: flex;
  align-items: flex-start; }
  .main__slider__item {
    width: 50%; }
    .main__slider__item > * {
      padding-right: 20px;
      box-sizing: border-box; }
    .main__slider__item.swiper-slide-next {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end; }
      .main__slider__item.swiper-slide-next > * {
        padding: 0 0 0 20px;
        justify-content: flex-end; }

.title_slide {
  width: 100%;
  font-family: whitney_book;
  display: flex;
  align-items: flex-end;
  margin-bottom: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5); }
  .title_slide > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px; }
    .title_slide > * > *:not(:last-child) {
      margin-right: 13px; }
  .title_slide__text {
    position: relative;
    font-family: raleway_light, Arial, sans-serif;
    font-size: 28px;
    padding-bottom: 7px;
    color: white; }
    .title_slide__text:before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 2px;
      background: white; }
  .title_slide__social {
    display: flex;
    align-items: center; }
    .title_slide__social__item.fb {
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -99px;
      width: 20px;
      height: 14px; }
    .title_slide__social__item.vk {
      background-image: url(../images/sprites/sprite.png);
      background-position: -148px -142px;
      width: 30px;
      height: 14px; }

.content_slide {
  width: 100%;
  font-size: 18px;
  line-height: 1.2;
  display: flex;
  font-family: raleway_light, Arial, sans-serif; }
  .content_slide > * {
    width: 100%;
    max-width: 400px; }

.main_news .col_bg {
  background-image: url(../images/bg/news-bg.png); }

.main_news__container {
  width: 100%;
  max-width: 670px;
  margin: 0 auto;
  margin: 0;
  padding: 55px 40px;
  box-sizing: border-box; }

.main_news .title_big {
  margin-bottom: 55px; }

.news_little:not(:last-child) {
  margin-bottom: 30px; }

.news_little__title {
  font-family: raleway_light, Arial, sans-serif;
  border-bottom: 1px solid rgba(158, 158, 158, 0.8);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 25px; }
  .news_little__title:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 100%;
    width: 10000px;
    border-bottom: 1px solid rgba(158, 158, 158, 0.8); }
  .news_little__title__text {
    color: #4b4b4b;
    font-size: 24px;
    position: relative;
    width: calc(100% - 185px);
    max-width: 350px;
    padding-bottom: 5px;
    line-height: 1.2; }
    .news_little__title__text:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border-bottom: 2px solid #9e9e9e; }
  .news_little__title .box__date {
    margin-bottom: 9px; }

.news_little__contant {
  line-height: 2;
  width: 100%;
  max-width: 440px; }

.main_bonus_system .col_bg {
  background-image: url(../images/bg/bonus-system-bg.png); }

.main_bonus_system .col_content {
  justify-content: flex-end; }

.bonus_system_container {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  margin: 0;
  padding: 55px 40px;
  box-sizing: border-box; }
  .bonus_system_container .title_small {
    margin-bottom: 25px; }

.title_bonus_system {
  color: #4b4b4b;
  font-size: 24px;
  margin-bottom: 25px; }

.bs_content {
  line-height: 1.8; }

.footnote {
  margin-top: 30px;
  position: relative; }
  .footnote:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../images/sprites/sprite.png);
    background-position: -270px -228px;
    width: 36px;
    height: 34px;
    transform: translate(-42%, -18%);
    z-index: -1; }

.question_answer {
  position: relative;
  color: #fff;
  padding: 55px 40px;
  box-sizing: border-box; }
  .question_answer > * {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto; }
  .question_answer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/bg/question-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1; }
  .question_answer:after {
    content: '';
    position: absolute;
    top: 0;
    right: auto;
    left: -50000px;
    width: 100000px;
    height: 100%;
    background-color: #3385ff;
    opacity: 0.8;
    z-index: -1;
    max-width: none; }
  .question_answer .read_more {
    margin: 20px 0; }
  .question_answer .title_slide {
    height: 91px; }

.partners_program {
  padding: 55px 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end; }
  .partners_program .bonus_calc_btn {
    display: none; }
  .partners_program .title_small {
    margin-bottom: 50px; }

.container_partners {
  display: flex; }
  .container_partners__item {
    line-height: 1.7;
    color: #9e9e9e;
    padding-right: 30px;
    box-sizing: border-box;
    width: 50%; }
  .container_partners__title {
    font-size: 28px;
    color: #4b4b4b;
    line-height: 1.2;
    padding-top: 75px;
    position: relative;
    display: block;
    margin-bottom: 10px;
    padding-right: 30px; }
    .container_partners__title:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0; }
    .container_partners__title.invite:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -54px -104px;
      width: 52px;
      height: 54px; }
    .container_partners__title.diagram:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -132px 0px;
      width: 56px;
      height: 54px; }

.partners_container {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  align-items: center; }
  .partners_container__left {
    width: 100%;
    max-width: calc(100% - 430px); }

.bonus_calculator {
  position: relative;
  width: 430px;
  padding: 45px 35px;
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 7px 7px 57px 0px rgba(66, 66, 66, 0.3);
  background: #fff; }
  .bonus_calculator .custom_select {
    margin-left: 10px;
    min-width: 95px; }
  .bonus_calculator .few_fields > *:not(:last-child) {
    margin-right: 0; }
  .bonus_calculator .close_pseudo {
    display: none; }
  .bonus_calculator__title {
    font-family: raleway_light, Arial, sans-serif;
    font-size: 30px;
    color: #777;
    margin-bottom: 30px; }
  .bonus_calculator .few_fields .label_field {
    max-width: 230px; }
  .bonus_calculator .few_fields .input_field {
    max-width: 180px; }

.bonus {
  width: 100%;
  font-size: 21px;
  color: #000;
  font-family: raleway_light, Arial, sans-serif;
  margin: 10px 0 25px 0; }
  .bonus__title {
    color: #9e9e9e; }
  .bonus_rules {
    color: #777; }
  .bonus > * {
    display: block;
    text-align: right; }
  .bonus .bonus__money {
    font-size: 42px;
    color: #3385ff;
    font-family: whitney_semibold; }
  .bonus .currency {
    font-family: raleway_bold, Arial, sans-serif; }

.bonus_rules__title {
  font-family: raleway_bold, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 10px;
  display: block; }

.bonus_rate {
  font-family: whitney_book;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.exchange_info {
  padding-top: 25px;
  font-family: raleway_light, Arial, sans-serif;
  font-size: 10px;
  line-height: 1.3; }
  .exchange_info__txt {
    position: relative;
    padding-left: 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 39px;
    -webkit-line-clamp: 3;
    word-wrap: break-word; }
    .exchange_info__txt:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -46px;
      width: 19px;
      height: 19px; }
  .exchange_info .read_more {
    padding-left: 25px; }
    .exchange_info .read_more span {
      cursor: pointer; }

@media screen and (max-width: 1280px) {
  .benefits,
  .main_reviews,
  .main_news__container,
  .bonus_system_container,
  .question_answer,
  .partners_program {
    padding: 30px 30px;
    max-width: none; }
  .main_reviews > *,
  .question_answer > * {
    max-width: none; }
  .slide1 {
    align-items: stretch; }
  .centering_container {
    align-items: center; }
    .centering_container .main_title {
      align-self: flex-start;
      font-size: 58px;
      padding-left: 30px;
      box-sizing: border-box; } }

@media screen and (max-width: 1023px) {
  .benefits,
  .main_reviews,
  .main_news__container,
  .bonus_system_container,
  .question_answer,
  .partners_program {
    padding: 30px 20px;
    max-width: none; }
  .centering_container .main_title {
    padding-left: 20px; }
    .centering_container .main_title br {
      display: block; }
  .question_answer .title_slide {
    height: 105px; }
  .bonus_calculator {
    width: 280px;
    height: auto;
    padding: 30px 25px; }
    .bonus_calculator .custom_select {
      margin-left: 10px;
      min-width: 95px; }
    .bonus_calculator .label_field {
      width: 125px;
      margin-right: 0 !important; }
    .bonus_calculator__title {
      font-size: 18px;
      margin-bottom: 20px; }
    .bonus_calculator .bonus {
      margin-top: 0;
      font-size: 18px; }
      .bonus_calculator .bonus__money {
        font-size: 24px; }
    .bonus_calculator .bonus_rules__title {
      font-size: 14px; }
    .bonus_calculator .bonus_rate {
      font-size: 9px; }
  .slide1 {
    padding-bottom: 160px;
    box-sizing: border-box; }
    .slide1:after {
      max-width: none; }
    .slide1:before {
      display: none; }
    .slide1 video {
      display: none; }
    .slide1 .btns_box {
      display: block;
      position: absolute;
      left: 0;
      bottom: 30px;
      padding: 0 20px;
      box-sizing: border-box;
      width: 100%; }
      .slide1 .btns_box > * {
        width: 280px;
        margin: 0 auto; }
        .slide1 .btns_box > *:not(:last-child) {
          margin-bottom: 20px; }
    .slide1 .container_tabs {
      display: none; }
    .slide1 .main_title {
      width: 100%;
      max-width: none;
      align-self: center;
      padding: 0 20px;
      text-align: center; }
    .slide1 .centering_container {
      width: 100%;
      justify-content: flex-start; }
  .benefits {
    flex-direction: column;
    align-items: center; }
    .benefits__item {
      width: auto; }
      .benefits__item:not(:last-child) {
        margin-bottom: 30px; }
    .benefits__title {
      text-align: center; }
      .benefits__title:before {
        left: 50%;
        transform: translateX(-50%); }
  .main_news__left {
    display: none; }
  .main_news__right {
    width: 100%; }
  .main_news .btn {
    margin: 0 auto; }
  .main_news .title_big {
    margin-bottom: 30px; }
  .news_little__contant {
    max-width: none; }
  .news_little__title {
    margin-bottom: 35px;
    position: relative; }
    .news_little__title__text {
      width: 100%; }
    .news_little__title .box__date {
      padding-left: 20px;
      margin-top: 7px;
      margin-bottom: 0;
      position: absolute;
      top: 100%; }
      .news_little__title .box__date:before {
        left: 0; }
  .news_little:not(:last-child) {
    margin-bottom: 20px; }
  .main_bonus_system {
    position: relative;
    flex-direction: column-reverse; }
    .main_bonus_system .col_content {
      width: 100%; }
    .main_bonus_system .col_bg {
      width: 100%;
      height: 163px;
      background-position: 0 100%;
      position: relative; }
      .main_bonus_system .col_bg:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2); }
    .main_bonus_system .title_small {
      position: absolute;
      top: 30px;
      left: 20px;
      color: #fff;
      z-index: 1; }
  .partners_program .bonus_calc_btn {
    display: flex;
    margin: 0 auto;
    margin-top: 35px; }
  .partners_program .partners_container__left {
    max-width: none; }
  .partners_program .bonus_calculator {
    display: none; }
  .partners_program .title_small {
    margin-bottom: 25px; }
  .partners_program .container_partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .partners_program .container_partners__item {
      width: auto;
      padding: 0;
      max-width: none;
      width: 100%;
      padding-right: 0;
      padding-left: 0; }
      .partners_program .container_partners__item:not(:last-child) {
        margin-bottom: 30px; }
    .partners_program .container_partners__title:before {
      left: 50%;
      transform: translateX(-50%); }
  .main_reviews .container_slider {
    padding-top: 0; }
    .main_reviews .container_slider__title {
      margin-top: 0; }
  .container_slider {
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: 70px;
    box-sizing: border-box; }
    .container_slider .btn {
      display: none; }
    .container_slider .round_btn {
      display: flex; }
    .container_slider > * {
      width: 100%;
      align-items: flex-start; }
    .container_slider__title {
      text-align: left;
      font-size: 45px;
      line-height: 1.5; }
    .container_slider .container_slider__btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      justify-content: center; }
    .container_slider .content_slide > * {
      max-width: none; }
    .container_slider__left > *:not(.btn) {
      width: 100%; }
    .container_slider__rigth {
      margin-bottom: 40px; }
      .container_slider__rigth .box {
        width: 100%;
        position: relative; }
    .container_slider .title_slide {
      position: relative; }
      .container_slider .title_slide > * > *:not(:last-child) {
        margin-right: 0; }
      .container_slider .title_slide .box__date {
        padding-left: 20px;
        margin-top: 7px;
        margin-bottom: 0;
        position: absolute;
        top: 100%; }
        .container_slider .title_slide .box__date:before {
          left: 0; }
      .container_slider .title_slide__social {
        margin-top: 7px;
        margin-bottom: 0;
        position: absolute;
        top: 100%;
        right: 0; }
      .container_slider .title_slide__text {
        font-size: 24px; }
  .main__slider__item {
    width: 100%; } }

/* весь контейнер поддерживает перспективу */
.flip-container {
  perspective: 1000; }

/* здесь находится скорость перевертывания */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative; }

/* скройте обратную сторону во время переворота */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

/* лицевая сторона размещена над обратной */
.front {
  z-index: 2; }

/* обратная, изначально скрытая сторона */
.back {
  transform: rotateY(180deg); }

.flip-container.flip .flipper {
  transform: rotateY(180deg); }

.flip-container {
  position: relative; }

.credit_card {
  width: 268px;
  height: 168px;
  color: #fff;
  box-shadow: 7px 7px 57px 0px rgba(66, 66, 66, 0.3);
  background: linear-gradient(-118deg, #d5d1d6, #bdb1b1);
  border-radius: 13px;
  position: relative; }
  .credit_card > * {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100500;
    border-left: 1.5px solid #b9adad;
    border-right: 1.5px solid #b2b6c8;
    border-radius: 13px; }
    .credit_card > *:before, .credit_card > *:after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(to top right, #e5e1e2, #fff);
      z-index: -1;
      border-radius: 13px; }
    .credit_card > *:after {
      top: auto;
      bottom: -1px;
      background: linear-gradient(to bottom right, #9d8b88, #a09598); }
    .credit_card > * > * {
      height: 100%;
      width: 100%;
      box-shadow: 7px 7px 57px 0px rgba(66, 66, 66, 0.3);
      background: linear-gradient(-118deg, #d5d1d6, #bdb1b1);
      border-radius: 13px;
      overflow: hidden; }
  .credit_card__logo {
    position: relative; }
    .credit_card__logo:before {
      content: '';
      width: 75px;
      height: 21px;
      display: block;
      background-image: url(../images/logo-dark.png);
      background-size: 100% 100%;
      background-repeat: no-repeat; }
  .credit_card__nubmer {
    font-family: avenir_medium;
    font-size: 18px;
    text-shadow: 0px 1px 1px rgba(81, 81, 81, 0.65);
    display: flex;
    justify-content: space-between; }
  .credit_card__logoCard {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1px; }
    .credit_card__logoCard:before {
      content: '';
      display: block; }
    .credit_card__logoCard.visa_logo:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -137px -202px;
      width: 63px;
      height: 19px; }
  .credit_card__chip {
    position: relative;
    margin-bottom: 12px; }
    .credit_card__chip:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-image: url(../images/sprites/sprite.png);
      background-position: -38px -265px;
      width: 40px;
      height: 32px; }
  .credit_card__expires {
    text-align: center;
    padding-top: 8px;
    margin-bottom: 5px; }
    .credit_card__expires > * {
      display: inline-block; }
    .credit_card__expires__title {
      color: #8e8e8e;
      font-size: 6px;
      font-family: avenir_medium; }
    .credit_card__expires__date {
      font-family: avenir_medium;
      font-size: 12px;
      text-shadow: 0px 1px 1px rgba(81, 81, 81, 0.65); }
      .credit_card__expires__date > *:not(:last-child):after {
        content: ' / '; }
  .credit_card__name {
    font-family: avenir_medium;
    font-size: 12px;
    text-shadow: 0px 1px 1px rgba(81, 81, 81, 0.65);
    padding-top: 3px; }

.front > * {
  padding: 20px;
  position: relative;
  box-sizing: border-box; }
  .front > *:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    width: 269px;
    height: 143px;
    background: url(../images/bg/card-bg.png);
    background-repeat: no-repeat; }
  .front > * > * {
    position: relative;
    z-index: 1; }

.back > * {
  padding: 15px 0;
  box-sizing: border-box;
  font-family: avenir_medium;
  font-size: 12px;
  text-shadow: 0px 1px 1px rgba(81, 81, 81, 0.65);
  color: #585556; }
  .back > * > *:not(.magnite) {
    margin-left: 20px;
    margin-right: 20px; }

.back .magnite {
  width: 100%;
  height: 30px;
  background: linear-gradient(61deg, #585556, #585556);
  margin-bottom: 8px; }

.back .white_line {
  background: linear-gradient(79deg, #f3efec, #ffffff);
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  height: 26px;
  padding: 7px;
  box-sizing: border-box;
  text-shadow: none; }
  .back .white_line .num {
    padding-right: 5px; }

.back .credit_card__nubmer {
  transform: rotateY(180deg);
  color: rgba(255, 255, 255, 0.5);
  background-clip: content-box;
  text-shadow: 0 1px 0 #bdb1b1, 0 0 0 #442424, 2px 0 0 #bdb1b1; }

.back .credit_card__expires__date {
  transform: rotateY(180deg);
  color: rgba(255, 255, 255, 0.5);
  background-clip: content-box;
  text-shadow: 0 1px 0 #bdb1b1, 0 0 0 #442424, 2px 0 0 #bdb1b1 !important; }

.back .card_footer {
  transform: rotateY(180deg);
  color: rgba(255, 255, 255, 0.5);
  background-clip: content-box; }
  .back .card_footer .credit_card__name {
    text-shadow: 0 1px 0 #bdb1b1, 0 0 0 #442424, 2px 0 0 #bdb1b1 !important; }

.card_footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }

.exchange_page {
  padding-top: 264px;
  box-sizing: border-box; }
  .exchange_page .new_wallet {
    margin-top: 5px;
    position: absolute;
    top: 100%;
    left: 20px;
    font-size: 14px;
    color: #3385ff;
    cursor: pointer;
    padding-left: 27px !important;
    line-height: 18px; }
    .exchange_page .new_wallet:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
      background: #3385ff;
      border-radius: 50%;
      color: #fff; }
    .exchange_page .new_wallet:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -82px;
      width: 17px;
      height: 17px; }

.fix_box {
  background: white;
  padding: 80px 0 15px 0;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 5; }
  .fix_box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 133, 255, 0.8);
    z-index: -1; }
  .fix_box .worksection {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box; }
  .fix_box .title_small {
    color: #fff;
    text-align: right;
    margin-bottom: 15px; }

.exchange_steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  font-family: raleway_light, Arial, sans-serif;
  align-items: center;
  margin-bottom: 12px; }
  .exchange_steps__item:not(.arrow) {
    padding: 15px 5px;
    box-sizing: border-box; }
    .exchange_steps__item:not(.arrow).active_step {
      border-bottom: 2px solid #fff; }

.arrow {
  margin: 0 5px;
  background-image: url(../images/sprites/sprite.png);
  background-position: 0px -303px;
  width: 27px;
  height: 11px; }

.box_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .box_bottom__left {
    font-family: raleway_light, Arial, sans-serif;
    font-size: 18px;
    display: flex;
    align-items: center; }
    .box_bottom__left .number {
      padding-left: 10px;
      font-size: 36px; }
  .box_bottom__rigth {
    font-family: raleway_light, Arial, sans-serif;
    text-align: right; }
    .box_bottom__rigth .number {
      font-size: 24px;
      margin-bottom: 10px; }

.exchange_step {
  padding: 55px 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
  line-height: 1.7; }
  .exchange_step .attention {
    line-height: 1.2;
    margin-bottom: 20px; }
  .exchange_step .with_title:not(:last-child) {
    margin-bottom: 30px !important; }
  .exchange_step .attention_container {
    padding: 20px;
    box-sizing: border-box;
    background: rgba(119, 119, 119, 0.07);
    border-radius: 7px;
    margin-bottom: 15px; }
    .exchange_step .attention_container .attention {
      margin-bottom: 5px;
      color: #4b4b4b;
      font-family: raleway_medium, Arial, sans-serif; }
  .exchange_step .title_fields {
    font-size: 30px;
    color: #4b4b4b;
    font-family: raleway_medium, Arial, sans-serif;
    margin-bottom: 25px;
    display: block; }
  .exchange_step .fields_container > *:not(.title_fields):not(:last-child) {
    margin-bottom: 40px; }
  .exchange_step .fields_container:not(:last-child) {
    margin-bottom: 45px; }
  .exchange_step.finish_step .fields_container > *:not(.title_fields):not(:last-child) {
    margin-bottom: 30px; }
  .exchange_step.finish_step .fields_container:not(:last-child) {
    margin-bottom: 30px; }

.exchange_variant {
  display: flex;
  justify-content: space-between; }
  .exchange_variant__item {
    width: calc(50% - 15px);
    background: #fff;
    border-radius: 7px;
    padding: 19px;
    box-sizing: border-box;
    box-shadow: 7px 7px 57px 0px rgba(66, 66, 66, 0.3); }
    .exchange_variant__item p {
      letter-spacing: -.1px; }
      .exchange_variant__item p span {
        color: #3385ff;
        font-family: raleway_medium, Arial, sans-serif; }
  .exchange_variant .label_radio {
    font-family: raleway_medium, Arial, sans-serif;
    color: #4b4b4b;
    font-size: 16px;
    line-height: 18px; }
  .exchange_variant .radio_container {
    margin-bottom: 15px; }

.wallet_field {
  margin-bottom: 65px !important; }
  .wallet_field .error_text {
    padding-top: 25px; }

.confirm_step .exchange_btn {
  justify-content: space-between; }

.finish_step {
  text-align: center;
  font-size: 16px;
  position: relative; }
  .finish_step:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100px;
    left: -65%;
    background: url(../images/bg/paper-plane-bg.png);
    background-repeat: no-repeat;
    background-position: 100% 0; }
  .finish_step .title_fields {
    padding-top: 65px;
    position: relative;
    margin-bottom: 15px; }
    .finish_step .title_fields:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(../images/sprites/sprite.png);
      background-position: 0px -44px;
      width: 56px;
      height: 60px; }
  .finish_step .exchange_btn {
    justify-content: center; }
  .finish_step__text {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 30px; }
    .finish_step__text > *:not(:last-child) {
      padding-bottom: 10px; }

.exchange_thanks {
  color: #3385ff;
  font-size: 30px; }

.confirm_text {
  font-size: 24px;
  color: #777; }

.exchange_currency {
  padding: 55px 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 950px;
  margin: 0 auto; }
  .exchange_currency .number {
    text-transform: uppercase; }
    .exchange_currency .number.blue {
      color: #3385ff; }
  .exchange_currency__title {
    font-family: raleway_light, Arial, sans-serif;
    color: #4b4b4b;
    font-size: 24px;
    line-height: 1.2;
    padding-bottom: 30px;
    text-align: center; }
  .exchange_currency__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 1.2;
    color: #4b4b4b;
    padding-bottom: 35px; }
    .exchange_currency__list li {
      width: 50%;
      padding-bottom: 15px;
      box-sizing: border-box; }
      .exchange_currency__list li:nth-child(odd) {
        padding-right: 5px; }
      .exchange_currency__list li:nth-child(even) {
        padding-left: 5px; }
    .exchange_currency__list .list_title {
      font-family: raleway_light, Arial, sans-serif; }
    .exchange_currency__list .list_info {
      font-family: raleway_medium, Arial, sans-serif;
      color: #3385ff; }
    .exchange_currency__list .arrow {
      display: inline-block;
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -215px;
      width: 28px;
      height: 12px; }
    .exchange_currency__list .arrow_blue {
      display: inline-block;
      background-image: url(../images/sprites/sprite.png);
      background-position: -27px -303px;
      width: 27px;
      height: 11px; }
  .exchange_currency .next-step {
    font-family: raleway_medium, Arial, sans-serif;
    font-size: 18px;
    color: #4b4b4b;
    line-height: 1.2;
    padding-bottom: 25px; }
  .exchange_currency form {
    width: 100%;
    max-width: 415px;
    margin: 0 auto;
    padding-bottom: 10px; }
    .exchange_currency form .label_field,
    .exchange_currency form .input_field {
      color: #777; }
    .exchange_currency form ::-webkit-input-placeholder {
      color: #777; }
    .exchange_currency form ::-moz-placeholder {
      color: #777; }
    .exchange_currency form :-ms-input-placeholder {
      color: #777; }
    .exchange_currency form :-moz-placeholder {
      color: #777; }
    .exchange_currency form .label_field .error_text {
      width: 100%; }

@media screen and (max-width: 1280px) {
  .exchange_step {
    padding: 30px 30px; }
  .exchange_currency {
    padding: 30px 30px; } }

@media screen and (max-width: 1023px) {
  .exchange_page {
    padding-top: 0; }
  .exchange_step {
    padding: 30px 20px; }
    .exchange_step .title_fields {
      font-size: 22px;
      margin-bottom: 5px; }
    .exchange_step .fields_container > *:not(.title_fields):not(:last-child) {
      margin-bottom: 15px; }
    .exchange_step .fields_container:not(:last-child) {
      margin-bottom: 20px; }
    .exchange_step .space_between, .exchange_step .flex_end {
      padding-top: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .exchange_step .space_between > *:not(:last-child), .exchange_step .flex_end > *:not(:last-child) {
        margin-bottom: 10px; }
    .exchange_step.finish_step {
      font-size: 14px;
      line-height: 1.3; }
      .exchange_step.finish_step:before {
        display: none; }
      .exchange_step.finish_step .title_fields {
        font-size: 18px;
        margin-bottom: 0px; }
      .exchange_step.finish_step .fields_container > *:not(.title_fields):not(:last-child) {
        margin-bottom: 20px; }
      .exchange_step.finish_step .fields_container:not(:last-child) {
        margin-bottom: 25px; }
  .finish_step__text > *:not(:last-child) {
    font-size: 13px;
    padding-bottom: 15px; }
  .exchange_thanks {
    font-size: 16px; }
  .exchange_steps {
    position: relative;
    justify-content: center; }
    .exchange_steps__item:not(.active_step) {
      display: none; }
    .exchange_steps__item:not(.arrow) {
      padding: 10px 0; }
    .exchange_steps .active_step + .arrow {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%); }
  .fix_box {
    position: relative; }
    .fix_box .worksection {
      max-width: 320px;
      box-sizing: border-box; }
    .fix_box .title_small {
      font-size: 24px;
      text-align: center;
      margin-bottom: 5px; }
  .box_bottom__left {
    display: none; }
  .box_bottom__rigth {
    width: 100%;
    text-align: center;
    font-size: 12px;
    letter-spacing: -.8px; }
    .box_bottom__rigth .number {
      font-size: 18px;
      margin-bottom: 5px; }
  .exchange_currency {
    padding: 30px 20px; }
    .exchange_currency__title {
      font-size: 20px;
      padding-bottom: 20px; }
    .exchange_currency__list {
      font-size: 14px;
      padding-bottom: 15px; }
      .exchange_currency__list li {
        width: 100%;
        padding-bottom: 10px; }
        .exchange_currency__list li:nth-child(odd) {
          padding-right: 0; }
        .exchange_currency__list li:nth-child(even) {
          padding-left: 0; }
    .exchange_currency .next-step {
      font-size: 16px;
      padding-bottom: 20px; }
    .exchange_currency form {
      padding-bottom: 5px; } }

@media screen and (max-width: 768px) {
  .exchange_variant {
    flex-direction: column; }
    .exchange_variant__item {
      width: 100%; }
      .exchange_variant__item:not(:last-child) {
        margin-bottom: 20px; } }

.field_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 7px 0;
  border-bottom: 1px solid rgba(75, 75, 75, 0.8);
  margin-bottom: 40px;
  line-height: 1.2;
  font-size: 19px; }
  .field_box:last-child {
    margin-bottom: 0; }
  .field_box span:not(:last-child) {
    padding-right: 5px;
    box-sizing: border-box; }
  .field_box .box_title {
    color: rgba(75, 75, 75, 0.8); }
  .field_box .box_val {
    color: #4b4b4b;
    font-size: 22px;
    font-family: whitney_book; }

.box_checked {
  font-family: raleway_light, Arial, sans-serif;
  font-size: 14px;
  position: relative;
  padding-left: 24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: block; }
  .box_checked:before, .box_checked:after {
    content: '';
    position: absolute;
    top: 0; }
  .box_checked:before {
    left: 0;
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid #4b4b4b;
    border-radius: 50%;
    box-sizing: border-box; }
  .box_checked:after {
    left: 9px;
    top: 9px;
    transform: translate(-50%, -50%);
    margin-left: 1px;
    background-image: url(../images/sprites/sprite.png);
    background-position: -324px -165px;
    width: 8px;
    height: 8px; }

@media screen and (max-width: 1023px) {
  .field_box {
    margin-bottom: 30px;
    font-size: 14px;
    flex-direction: column; }
    .field_box .box_title {
      align-self: flex-start; } }

.article_top {
  padding: 140px 40px 75px 40px;
  box-sizing: border-box;
  position: relative; }
  .article_top:after {
    content: '';
    position: absolute;
    top: 0;
    right: auto;
    left: -50000px;
    width: 100000px;
    height: 100%;
    background-color: #3385ff;
    opacity: 0.8;
    z-index: -1;
    max-width: none; }
  .article_top .title_small {
    color: #fff;
    padding: 0 30px;
    box-sizing: border-box;
    text-align: right;
    width: 100%;
    max-width: 1155px;
    margin: 0 auto; }

.about_page .article_top:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bg/about-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; }

.about_page .title_article {
  display: flex;
  justify-content: flex-end; }
  .about_page .title_article > * {
    display: inline-block;
    width: 100%;
    max-width: 800px;
    text-align: right; }

.agreement_page .article_top:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bg/agreement-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; }

.article_content {
  width: 100%;
  max-width: 1175px;
  margin: 0 auto;
  padding: 55px 40px;
  box-sizing: border-box;
  line-height: 1.7;
  font-family: whitney_book; }
  .article_content p {
    min-height: 23px; }

.title_article {
  color: #4b4b4b;
  font-size: 24px;
  margin-bottom: 40px; }

.sub_title_article {
  font-family: whitney_semibold;
  font-size: 18px; }
  .sub_title_article:not(:first-child) {
    margin-top: 40px; }

.agreement_btn {
  padding-top: 40px;
  display: flex;
  justify-content: flex-end; }

@media screen and (max-width: 1280px) {
  .article_top {
    padding: 140px 30px 75px 30px; }
  .article_content {
    padding: 30px 30px; } }

@media screen and (max-width: 1024px) {
  .article_top {
    padding: 80px 20px 20px 20px; }
    .article_top .title_small {
      text-align: center;
      padding: 0;
      font-size: 24px; }
  .article_content {
    padding: 30px 20px;
    font-size: 12px; }
  .sub_title_article {
    font-size: 12px; }
    .sub_title_article:not(:first-child) {
      margin-top: 10px; }
  .about_page .title_article > * {
    max-width: none;
    text-align: left;
    line-height: 1.3; }
  .title_article {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
    max-width: none;
    width: 100%; } }

.exchange_rates_top {
  position: relative;
  padding: 120px 0 60px 0;
  color: #fff; }
  .exchange_rates_top:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/bg/exchange-rates-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1; }
  .exchange_rates_top:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 100000px;
    height: 100%;
    background-color: #3385ff;
    opacity: 0.8;
    z-index: -1;
    max-width: 53%; }
  .exchange_rates_top:after {
    min-width: 723px; }
  .exchange_rates_top .work_section {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between; }
  .exchange_rates_top .title_small {
    font-size: 60px;
    color: #fff;
    margin-bottom: 45px; }
  .exchange_rates_top .few_fields {
    margin-bottom: 25px; }
  .exchange_rates_top .custom_select {
    width: 110px; }
  .exchange_rates_top .box > *:not(:last-child) {
    margin-right: 40px; }
  .exchange_rates_top .delimiter {
    align-self: center; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.title_filters_rate {
  font-family: raleway_medium, Arial, sans-serif;
  font-size: 21px;
  display: block;
  margin-bottom: 15px; }

.exchange_rates_content {
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  padding: 55px 40px; }

.container_currency:not(:last-child) {
  margin-bottom: 50px; }

.title_currency {
  margin: 0 0 30px 0;
  position: relative;
  display: inline-block;
  border-bottom: 2px solid #3385ff;
  color: #3385ff;
  font-size: 30px;
  font-family: raleway_medium, Arial, sans-serif; }
  .title_currency:before {
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    width: 100000000px;
    background-color: rgba(51, 133, 255, 0.5); }
  .title_currency:before {
    left: 50%;
    transform: translateX(-50%); }

.delimiter {
  width: 55px; }
  .delimiter_arrow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .delimiter_arrow:before {
      content: '';
      display: block;
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -215px;
      width: 28px;
      height: 12px; }
    .delimiter_arrow.arrow_light:before {
      background-image: url(../images/sprites/sprite.png);
      background-position: -200px -202px;
      width: 28px;
      height: 12px; }

.currencies_list__item {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 1.2; }
  .currencies_list__item > * {
    padding: 8px 15px; }
    .currencies_list__item > *:first-child {
      padding-left: 0; }
    .currencies_list__item > *:last-child {
      padding-right: 0; }

.currency_from {
  width: 135px; }

.currency_to {
  width: 215px; }

.currency_rate {
  width: 215px;
  color: #3385ff;
  font-family: whitney_book; }

@media screen and (max-width: 1280px) {
  .exchange_rates_top .work_section {
    padding: 0 30px; }
  .exchange_rates_top .left_col {
    max-width: 203px;
    margin: 0 auto; }
    .exchange_rates_top .left_col .btn {
      margin: 0 auto; }
  .exchange_rates_content {
    padding: 30px 30px; } }

@media screen and (max-width: 1024px) {
  .exchange_rates_top {
    padding: 100px 0 40px 0; }
    .exchange_rates_top:after {
      max-width: 100%; }
    .exchange_rates_top .work_section {
      padding: 0 20px; }
    .exchange_rates_top .left_col {
      display: none; }
    .exchange_rates_top .right_col {
      margin: 0 auto; }
    .exchange_rates_top .title_filters_rate {
      font-size: 16px; }
    .exchange_rates_top .ratesFilters {
      max-width: 280px; }
      .exchange_rates_top .ratesFilters .few_fields {
        flex-wrap: wrap;
        position: relative;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%; }
        .exchange_rates_top .ratesFilters .few_fields > * {
          margin-right: 0;
          display: flex; }
        .exchange_rates_top .ratesFilters .few_fields .box {
          margin-bottom: 0; }
      .exchange_rates_top .ratesFilters .delimiter {
        width: auto;
        position: absolute;
        top: 50%;
        right: -8px;
        transform: rotateZ(90deg);
        margin: 0; }
      .exchange_rates_top .ratesFilters .flex-end {
        justify-content: center; }
  .exchange_rates_content {
    padding: 30px 20px;
    max-width: 320px;
    box-sizing: border-box; }
    .exchange_rates_content .delimiter {
      display: none;
      width: auto; }
      .exchange_rates_content .delimiter_arrow {
        display: block; }
  .currencies_list__item {
    flex-wrap: wrap;
    font-size: 14px; }
    .currencies_list__item:not(:last-child) {
      margin-bottom: 10px; }
    .currencies_list__item > * {
      padding: 5px 7px; }
    .currencies_list__item .currency_from {
      width: 82px;
      box-sizing: border-box; }
    .currencies_list__item .currency_to {
      width: 156px;
      box-sizing: border-box;
      padding-right: 0; }
    .currencies_list__item .currency_rate {
      padding-left: 0; }
  .title_currency {
    margin-bottom: 20px; } }

.profile_menu {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin: 30px auto 45px auto;
  color: rgba(255, 255, 255, 0.8); }
  .profile_menu:before, .profile_menu:after {
    content: '';
    position: absolute;
    top: 100%;
    width: 999999999px;
    height: 1px;
    background: rgba(255, 255, 255, 0.8);
    margin-top: -2px; }
  .profile_menu:before {
    left: 50%;
    transform: translateX(-50%); }
  .profile_menu:after {
    left: 100%; }
  .profile_menu__item {
    padding: 0 10px;
    display: block;
    box-sizing: border-box; }
    .profile_menu__item span {
      display: block;
      padding: 7px 0;
      box-sizing: border-box; }
    .profile_menu__item.active_item {
      color: #fff; }
      .profile_menu__item.active_item span {
        border-bottom: 2px solid #fff; }

.profile_page {
  padding-top: 263px;
  box-sizing: border-box; }
  .profile_page .checkbox_container {
    width: 100%; }
  .profile_page .few_fields {
    flex-wrap: wrap; }
    .profile_page .few_fields .social_network {
      margin-right: 0 !important;
      max-width: 220px; }

.content_profile {
  padding: 55px 40px; }

.profile_form {
  width: 100%;
  max-width: 620px;
  margin: 0 auto; }
  .profile_form:not(:last-child) {
    margin-bottom: 55px; }
  .profile_form .few_fields > * {
    max-width: 50%; }
    .profile_form .few_fields > *:not(:last-child) {
      max-width: calc(50% - 40px); }
  .profile_form > *:not(:last-child) {
    margin-bottom: 55px; }
  .profile_form .agreement_btn {
    padding-top: 20px; }

@media screen and (max-width: 1280px) {
  .content_profile {
    padding: 30px 30px; } }

@media screen and (max-width: 1023px) {
  .content_profile {
    padding: 30px 20px; }
  .profile_page {
    padding-top: 0; }
    .profile_page .title_small {
      text-align: center;
      margin-bottom: 0;
      font-size: 24px; }
    .profile_page .fix_box {
      position: relative;
      position: relative; }
  .profile_menu {
    display: none; }
  .user_profile .few_fields {
    flex-direction: column; }
    .user_profile .few_fields > * {
      width: 100%;
      max-width: 100%; }
      .user_profile .few_fields > *:not(:last-child) {
        margin-right: 0;
        width: 100%;
        max-width: 100%; }
      .user_profile .few_fields > *:not(.custom_select) {
        margin-bottom: 0; }
    .user_profile .few_fields .social_network {
      order: 1;
      margin: 20px 0 0 10px;
      align-self: center; }
    .user_profile .few_fields .title_fields {
      margin-bottom: 18px; }
    .user_profile .few_fields .label_field {
      margin-bottom: 30px; }
  .confirm_pass > span:first-child {
    min-width: 185px; }
  .profile_form:not(:last-child) {
    margin-bottom: 25px; } }

.user_bonus {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative; }
  .user_bonus__container {
    box-sizing: border-box; }
    .user_bonus__container:first-child {
      padding: 375px 20px 0 0; }
    .user_bonus__container:last-child {
      overflow: hidden;
      width: calc(100% - 450px); }
    .user_bonus__container p {
      line-height: 1.2; }
      .user_bonus__container p:not(:last-child) {
        padding-bottom: 15px; }
    .user_bonus__container__top:not(:last-child) {
      margin-bottom: 40px; }
    .user_bonus__container__top > *:not(p) {
      margin-bottom: 25px; }
  .user_bonus__title {
    font-family: raleway_light, Arial, sans-serif;
    color: #4b4b4b;
    font-size: 36px;
    line-height: .9; }
    .user_bonus__title .number {
      font-size: 48px;
      color: #3385ff; }

.diagram_box {
  position: absolute;
  top: 55px;
  left: 40px;
  width: 430px; }

.user_bonus__title__small {
  font-family: raleway_light, Arial, sans-serif;
  color: #4b4b4b;
  font-size: 24px; }

.diagram_container {
  position: relative; }

.diagram_circle {
  transform: rotateZ(-90deg);
  display: flex;
  justify-content: center;
  position: relative; }

.val_diagram {
  transform: translate(-50%, -50%);
  font-family: raleway_medium, Arial, sans-serif;
  color: #4b4b4b;
  text-align: center;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%; }
  .val_diagram .number {
    display: block;
    font-family: whitney_semibold;
    font-size: 56px;
    color: #777;
    display: flex;
    align-items: center;
    justify-content: center; }
  .val_diagram .coin {
    font-size: 18px;
    padding-top: 15px; }

.cicle_chart {
  width: 100%;
  max-width: 325px;
  margin: 0 auto;
  display: flex;
  font-family: raleway_medium, Arial, sans-serif;
  justify-content: space-between;
  color: #4b4b4b;
  font-size: 14px;
  text-align: center;
  margin-bottom: 35px;
  padding-top: 17px; }
  .cicle_chart .number {
    font-family: whitney_semibold;
    color: #777;
    font-size: 37px;
    display: block;
    margin-bottom: 10px; }
  .cicle_chart > * {
    padding-bottom: 20px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer; }
    .cicle_chart > *:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 35px;
      height: 5px;
      border-radius: 2px;
      background: #113b79; }
    .cicle_chart > *:first-child:after {
      background: #3385ff; }
    .cicle_chart > *:last-child:after {
      background: #85b6ff; }

@media screen and (max-width: 1280px) {
  .diagram_box {
    top: 30px;
    left: 30px; } }

@media screen and (max-width: 1023px) {
  .diagram_box {
    position: absolute;
    position: static;
    top: 0;
    left: 0;
    width: 100%; }
  .user_bonus {
    max-width: 280px; }
    .user_bonus__container:first-child {
      display: none; }
    .user_bonus__container:last-child {
      width: 100%; }
    .user_bonus__container p:not(:last-child) {
      padding-bottom: 10px; }
    .user_bonus__container__top:not(:last-child) {
      margin-bottom: 20px; }
    .user_bonus__title {
      font-size: 30px;
      line-height: 1.4; }
      .user_bonus__title .number {
        font-size: 36px; }
      .user_bonus__title__small {
        font-size: 18px; }
  .cicle_chart {
    margin-bottom: 25px; } }

.table_profile {
  width: 100%;
  font-family: whitney_book;
  font-size: 18px; }
  .table_profile td, .table_profile th {
    padding: 15px;
    box-sizing: border-box;
    width: 20%;
    text-align: center; }
  .table_profile th {
    font-size: 17px; }
    .table_profile th:first-child .box_cell {
      position: relative; }
      .table_profile th:first-child .box_cell:before, .table_profile th:first-child .box_cell:after {
        content: '';
        position: absolute;
        top: 100%;
        width: 999999999px;
        height: 1px;
        background: rgba(51, 133, 255, 0.8); }
      .table_profile th:first-child .box_cell:before {
        right: -1px; }
      .table_profile th:first-child .box_cell:after {
        left: 100%; }
    .table_profile th > * {
      padding-bottom: 5px; }

.cell {
  width: 100%; }
  .cell_title {
    display: none;
    color: #3385ff; }

.box_cell {
  display: flex;
  justify-content: center;
  letter-spacing: -1.1px; }

.table_row_title {
  position: relative; }

.table_title {
  font-family: raleway_medium, Arial, sans-serif;
  font-size: 17px;
  color: #3385ff; }

.user_promocodes .table_profile td, .user_promocodes .table_profile th, .user_history .table_profile td, .user_history .table_profile th {
  text-align: left; }

.user_promocodes .box_cell, .user_history .box_cell {
  justify-content: flex-start; }

.arrow_filter {
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center; }
  .arrow_filter > * {
    display: block;
    background-image: url(../images/sprites/sprite.png);
    background-position: -322px -113px;
    width: 10px;
    height: 16px; }
    .arrow_filter > *.arrow_filter__top {
      background-image: url(../images/sprites/sprite.png);
      background-position: -322px -113px;
      width: 10px;
      height: 16px;
      transform: rotate(180deg) translateX(2px); }
  .arrow_filter__top {
    transform: rotateZ(180deg); }

.descending .arrow_filter > * {
  background-image: url(../images/sprites/sprite.png);
  background-position: -308px -282px;
  width: 10px;
  height: 16px; }
  .descending .arrow_filter > *.arrow_filter__top {
    background-image: url(../images/sprites/sprite.png);
    background-position: -322px -113px;
    width: 10px;
    height: 16px; }

.ascending .arrow_filter > * {
  background-image: url(../images/sprites/sprite.png);
  background-position: -322px -113px;
  width: 10px;
  height: 16px; }
  .ascending .arrow_filter > *.arrow_filter__top {
    background-image: url(../images/sprites/sprite.png);
    background-position: -308px -282px;
    width: 10px;
    height: 16px; }

.user_feedback, .user_promocodes, .user_history {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box; }

.profile_page td {
  font-family: whitney_book;
  font-size: 17px;
  letter-spacing: -.8px; }

@media screen and (max-width: 1023px) {
  .table_profile {
    margin: 0 auto;
    max-width: 280px;
    width: 100%;
    display: block; }
    .table_profile tbody, .table_profile tr, .table_profile th, .table_profile td {
      display: block;
      width: 100%;
      padding: 3px 0; }
    .table_profile tr {
      padding: 10px 0; }
      .table_profile tr:not(:last-child) {
        position: relative; }
        .table_profile tr:not(:last-child):before {
          content: '';
          width: 10000px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 1px solid rgba(158, 158, 158, 0.8); }
      .table_profile tr td:first-child {
        font-size: 18px;
        padding-bottom: 10px; }
        .table_profile tr td:first-child .cell_title {
          font-size: 18px; }
      .table_profile tr td {
        font-size: 14px; }
    .table_profile .table_row_title {
      display: none; }
    .table_profile .cell {
      display: flex;
      justify-content: space-between; }
      .table_profile .cell_title {
        display: inline-block; } }

.user_partners_program {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box; }
  .user_partners_program .container_partners__title {
    font-size: 24px; }
  .user_partners_program > *:not(:last-child) {
    margin-bottom: 50px; }

.box_program {
  display: flex; }

.title_programm {
  display: block;
  font-size: 30px;
  color: #4b4b4b;
  font-family: raleway_medium, Arial, sans-serif;
  margin-bottom: 20px; }

.box_program__right {
  padding-left: 50px;
  box-sizing: border-box;
  font-size: 24px; }
  .box_program__right .number {
    font-size: 36px;
    color: #3385ff; }
  .box_program__right > *:not(:last-child) {
    margin-bottom: 60px; }

.bonus_links > * {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .bonus_links > *:last-child .bonus_links__title:before {
    content: '2'; }
  .bonus_links > * > * {
    padding-left: 80px; }

.bonus_links__title {
  position: relative;
  line-height: 1.5;
  margin-bottom: 50px; }
  .bonus_links__title:before {
    content: '1';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-family: whitney_book;
    color: #3385ff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translateY(-50%);
    border: 1px solid #3385ff; }

.will_show_banner {
  font-family: raleway_medium, Arial, sans-serif;
  color: #3385ff;
  font-size: 18px;
  letter-spacing: -1.6px;
  align-self: flex-end;
  padding: 5px 10px 0 0;
  box-sizing: border-box;
  cursor: pointer; }

.banners_container .title_programm {
  text-align: center;
  margin-bottom: 30px; }

.banners_container [data-tab] {
  display: none; }
  .banners_container [data-tab].show_tab {
    display: block; }

.banner_tab_list {
  display: flex;
  justify-content: space-between;
  font-family: whitney_book;
  font-size: 18px;
  color: rgba(51, 133, 255, 0.8);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative; }
  .banner_tab_list:before, .banner_tab_list:after {
    content: '';
    position: absolute;
    top: 100%;
    height: 1px;
    width: 999999999px;
    background: rgba(51, 133, 255, 0.8);
    margin-top: -2px; }
  .banner_tab_list:before {
    right: 0; }
  .banner_tab_list:after {
    left: 100%; }

.banner_tab_item {
  padding: 0 10px; }
  .banner_tab_item > * {
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 0; }
  .banner_tab_item.active_tab {
    color: #3385ff; }
    .banner_tab_item.active_tab > * {
      border-bottom: 2px solid #3385ff; }

.banner_tab {
  width: 100%;
  max-width: 670px;
  margin: 0 auto;
  padding: 50px 0; }
  .banner_tab .custom_select {
    min-width: 150px; }
  .banner_tab .few_fields > *:not(:last-child) {
    margin-right: 0; }

.title_banner_exchange {
  font-family: raleway_medium, Arial, sans-serif;
  font-size: 18px;
  padding: 10px 10px 10px 0; }

@media screen and (max-width: 1023px) {
  .box_program {
    justify-content: center; }
    .box_program .bonus_calculator {
      display: none; }
  .title_programm {
    font-size: 22px; }
  .box_program__right {
    font-size: 18px;
    padding-left: 0;
    width: 100%; }
    .box_program__right > *:not(:last-child) {
      margin-bottom: 30px; }
    .box_program__right .number {
      font-size: inherit; }
  .user_partners_program {
    max-width: 320px;
    box-sizing: border-box; }
    .user_partners_program .space_between {
      flex-wrap: wrap;
      justify-content: center; }
      .user_partners_program .space_between .will_show_banner {
        width: 100%;
        text-align: center;
        margin-bottom: 35px;
        padding: 0; }
    .user_partners_program .label_field {
      font-size: 14px; }
    .user_partners_program > *:not(:last-child) {
      margin-bottom: 20px; }
    .user_partners_program .container_partners__title {
      font-size: 14px; }
  .container_partners {
    justify-content: space-between; }
    .container_partners__item {
      padding: 0;
      box-sizing: border-box;
      max-width: 50%;
      width: auto; }
      .container_partners__item .container_partners__title {
        padding-right: 0;
        letter-spacing: -.5px; }
      .container_partners__item:first-child {
        padding-right: 10px; }
      .container_partners__item:last-child {
        padding-left: 10px; }
  .bonus_links {
    flex-wrap: wrap; }
    .bonus_links__title {
      margin-bottom: 30px; }
      .bonus_links__title:before {
        width: 35px;
        height: 35px;
        font-size: 24px; }
    .bonus_links > * {
      width: 100%;
      padding: 0; }
      .bonus_links > *:not(:last-child) {
        margin-bottom: 35px; }
      .bonus_links > *:last-child {
        padding: 0; }
      .bonus_links > * > * {
        padding-left: 45px; }
      .bonus_links > * > form {
        padding-left: 0; }
  .banners_container {
    padding-top: 10px; }
    .banners_container .title_programm {
      margin-bottom: 10px; }
    .banners_container .few_fields {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative; }
      .banners_container .few_fields > *:not(.custom_select) {
        margin-bottom: 20px; }
      .banners_container .few_fields .delimiter {
        width: auto;
        margin: 0;
        position: absolute;
        bottom: 42px;
        right: 0;
        transform: rotateZ(90deg); }
      .banners_container .few_fields > *:not(:last-child) {
        margin-right: 0; }
  .banner_tab {
    padding: 20px 0; } }

.user_wallets {
  background: #fcfcfc; }
  .user_wallets .btn {
    margin: 0 auto; }
  .user_wallets__container {
    width: 100%;
    max-width: 995px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
  .user_wallets__col {
    width: 48.7%; }

.user_wallets__item {
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 15px 0 rgba(66, 66, 66, 0.3);
  margin-bottom: 40px; }
  .user_wallets__item__title {
    position: relative;
    font-family: raleway_medium, Arial, sans-serif;
    font-size: 30px;
    line-height: 1.2;
    color: #4b4b4b;
    padding-bottom: 10px; }
    .user_wallets__item__title:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0; }
  .user_wallets__item.webmoney .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -188px -44px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.visa-master .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: 0px 0px;
    width: 132px;
    height: 44px; }
  .user_wallets__item.cash .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -132px -54px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.yandex .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -188px -88px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.upc .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -44px -158px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.p24 .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -176px -158px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.qiwi .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: 0px -158px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.alfaclick .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -188px 0px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.sber .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -88px -158px;
    width: 44px;
    height: 44px; }
  .user_wallets__item.rsb .user_wallets__item__title:after {
    background-image: url(../images/sprites/sprite.png);
    background-position: -132px -158px;
    width: 44px;
    height: 44px; }
  .user_wallets__item__accounts {
    width: 87%;
    padding-bottom: 25px; }
    .user_wallets__item__accounts li {
      position: relative;
      font-family: whitney_book;
      font-size: 20px;
      line-height: 1.2;
      color: #777;
      margin-bottom: 10px; }
      .user_wallets__item__accounts li:hover .account_del {
        opacity: 1;
        transition: opacity 0.05s linear; }
    .user_wallets__item__accounts .account_currency {
      font-family: raleway_semibold, Arial, sans-serif;
      font-size: 20px;
      line-height: 1.2;
      color: #4b4b4b;
      text-transform: uppercase;
      float: right;
      padding-right: 40px; }
    .user_wallets__item__accounts .account_del {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -65px;
      width: 17px;
      height: 17px;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.05s linear; }
  .user_wallets__item .account_currency_list {
    padding-bottom: 40px; }
    .user_wallets__item .account_currency_list span {
      font-family: raleway_thin, Arial, sans-serif;
      font-size: 18px;
      line-height: 1.2;
      color: #777;
      text-transform: uppercase;
      padding-right: 5px; }
  .user_wallets__item__btn-group {
    text-align: right; }
    .user_wallets__item__btn-group .btn_custom {
      display: inline-block;
      font-size: 16px;
      font-family: raleway_medium, Arial, sans-serif;
      cursor: pointer; }
      .user_wallets__item__btn-group .btn_custom:not(:last-child) {
        margin-right: 15px; }
      .user_wallets__item__btn-group .btn_custom.add {
        color: #3385ff; }
      .user_wallets__item__btn-group .btn_custom.del {
        color: #9e9e9e; }

@media screen and (max-width: 1024px) {
  .user_wallets__container {
    padding-bottom: 15px; }
  .user_wallets__item {
    padding: 15px 0;
    margin-bottom: 20px; }
    .user_wallets__item.webmoney .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -188px -132px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.visa-master .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: 0px -202px;
      width: 75px;
      height: 25px; }
    .user_wallets__item.cash .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -190px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.yandex .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -78px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.upc .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -128px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.p24 .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -153px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.qiwi .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -228px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.alfaclick .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -178px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.sber .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -203px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item.rsb .user_wallets__item__title:after {
      background-image: url(../images/sprites/sprite.png);
      background-position: -103px -265px;
      width: 25px;
      height: 25px; }
    .user_wallets__item__title {
      font-size: 23px;
      margin: 0 15px; }
    .user_wallets__item__accounts {
      width: 80%;
      padding-bottom: 15px; }
      .user_wallets__item__accounts li {
        font-size: 15px;
        cursor: pointer;
        padding: 5px 15px;
        margin-bottom: 0; }
        .user_wallets__item__accounts li:hover .account_del {
          display: none; }
        .user_wallets__item__accounts li.active {
          background: #efefef; }
          .user_wallets__item__accounts li.active .account_del {
            display: block; }
      .user_wallets__item__accounts .account_currency {
        font-size: 15px;
        padding-right: 0; }
      .user_wallets__item__accounts .account_del {
        opacity: 1;
        right: -12%;
        display: none; }
    .user_wallets__item .account_currency_list {
      padding: 0 15px 25px 15px; }
      .user_wallets__item .account_currency_list span {
        font-size: 15px; }
    .user_wallets__item__btn-group {
      padding: 0 15px; }
      .user_wallets__item__btn-group .btn_custom {
        font-size: 14px; } }

@media screen and (max-width: 768px) {
  .user_wallets__container {
    flex-direction: column;
    align-items: center; }
  .user_wallets__col {
    min-width: 280px;
    width: 55%; }
  .user_wallets__item__title {
    font-size: 18px; }
  .user_wallets__item__accounts li {
    font-size: 12px; }
  .user_wallets__item__accounts .account_currency {
    font-size: 12px; }
  .user_wallets__item .account_currency_list span {
    font-size: 12px; }
  .user_wallets__item__btn-group .btn_custom {
    font-size: 12px; } }

.news_page .article_top:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bg/news-single-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; }

.news_page .article_content {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box; }

.news_page .container_news {
  overflow: hidden; }
  .news_page .container_news .news_little__title__text {
    max-width: calc(100% - 200px);
    width: auto; }
  .news_page .container_news .news_little__contant {
    max-width: 100%; }

.single_news__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .single_news__title__text {
    max-width: 350px;
    font-size: 24px;
    line-height: 1.5;
    text-align: right; }

.single_news .box__date {
  margin-top: 4px; }

.single_news .sub_title_article {
  margin: 0 0 30px 0; }

.single_news .container_slider__btns {
  margin: 20px 0; }

@media screen and (max-width: 1024px) {
  .article_content p {
    min-height: 13px; }
  .news_page .article_content {
    font-size: 14px;
    position: relative; }
    .news_page .article_content .container_slider__btns > * {
      position: absolute;
      top: -57px;
      border: none; }
      .news_page .article_content .container_slider__btns > *:before {
        background-image: url(../images/sprites/sprite.png);
        background-position: -308px -113px;
        width: 14px;
        height: 20px; }
      .news_page .article_content .container_slider__btns > *:hover:before {
        margin: 0; }
      .news_page .article_content .container_slider__btns > *.next_btn {
        right: 0;
        transform: rotateZ(180deg); }
      .news_page .article_content .container_slider__btns > *.prev_btn {
        left: 0;
        transform: rotate(0deg); }
  .news_page .container_news {
    overflow: inherit; }
  .news_page .title_small {
    padding: 0 40px; }
  .single_news {
    line-height: 1.4; }
    .single_news__title {
      position: relative; }
      .single_news__title:before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        height: 1px;
        background: rgba(158, 158, 158, 0.8);
        width: 100%;
        z-index: -1; }
      .single_news__title__text {
        text-align: left;
        max-width: none;
        border-bottom: 2px solid #9e9e9e;
        line-height: 1.4; }
      .single_news__title .box__date {
        position: absolute;
        top: 100%;
        left: 0; }
    .single_news .sub_title_article {
      margin-bottom: 5px; } }

.faq_page .article_top:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bg/question-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; }

.faq_page .article_content {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box; }

.faq_page .container_news {
  overflow: hidden; }
  .faq_page .container_news .news_little__title__text {
    max-width: calc(100% - 200px);
    width: auto; }
  .faq_page .container_news .news_little__contant {
    max-width: 100%; }

.faq_page .read_more {
  display: inline-block;
  font-family: raleway_medium, Arial, sans-serif; }

@media screen and (max-width: 1024px) {
  .faq_page .container_news .news_little__title {
    margin-bottom: 10px; }
    .faq_page .container_news .news_little__title__text {
      max-width: none; } }

@keyframes showAuth {
  from {
    transform: translateX(100%);
    background: rgba(255, 255, 255, 0); }
  to {
    transform: translateX(0);
    background: white; } }

@keyframes showAuthForm {
  from {
    transform: translateX(80%); }
  to {
    transform: translateX(0); } }

@keyframes hideAuth {
  from {
    transform: translateX(0);
    background: white; }
  to {
    transform: translateX(100%);
    background: rgba(255, 255, 255, 0); } }

.popup_auth {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  display: none; }
  .popup_auth .btn {
    text-transform: uppercase;
    font-size: 12px; }
  .popup_auth.active_popup {
    display: flex; }
    .popup_auth.active_popup .content_popup {
      animation: showAuth .3s; }
      .popup_auth.active_popup .content_popup .active_form {
        animation: showAuthForm .8s; }
  .popup_auth .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 133, 255, 0.41);
    z-index: -1; }
  .popup_auth .content_popup {
    height: 100%;
    overflow: hidden;
    width: 50%;
    min-width: 723px;
    background: white;
    transition: all .5s;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: hideAuth .3s;
    animation-fill-mode: forwards;
    /* or: backwards, both, none */
    padding: 65px 0 55px 0;
    box-sizing: border-box; }
    .popup_auth .content_popup:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(51, 133, 255, 0.8);
      bottom: 0; }
    .popup_auth .content_popup > * {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 430px;
      margin: 0 auto;
      height: 100%;
      overflow-y: auto;
      padding: 0 40px; }
  .popup_auth .checkbox_container:not(:last-child) {
    margin-bottom: 15px; }
  .popup_auth .agree {
    font-family: raleway_semibold, Arial, sans-serif;
    padding-left: 5px; }

.title_auth {
  font-family: raleway_light, Arial, sans-serif;
  font-size: 30px;
  margin-bottom: 30px; }

.forgot_pass {
  display: block;
  text-align: right;
  padding: 6px 0;
  margin-bottom: 20px;
  cursor: pointer; }

.no-margin {
  margin-bottom: 0 !important; }

.auth_from {
  margin: 70px 0 15px 0;
  font-size: 18px;
  font-family: raleway_medium, Arial, sans-serif;
  padding-left: 15px; }

.social_auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px; }
  .social_auth .social_auth__item .social_icon {
    display: block; }
    .social_auth .social_auth__item .social_icon.fb {
      background-image: url(../images/sprites/sprite.png);
      background-position: -232px -152px;
      width: 38px;
      height: 38px; }
    .social_auth .social_auth__item .social_icon.vk {
      background-image: url(../images/sprites/sprite.png);
      background-position: 0px -227px;
      width: 38px;
      height: 38px; }
    .social_auth .social_auth__item .social_icon.ok {
      background-image: url(../images/sprites/sprite.png);
      background-position: -270px -76px;
      width: 38px;
      height: 38px; }
    .social_auth .social_auth__item .social_icon.google-plus {
      background-image: url(../images/sprites/sprite.png);
      background-position: -152px -227px;
      width: 38px;
      height: 38px; }

.form_auth, .form_reg, .form_recovery {
  display: none; }
  .form_auth.active_form, .form_reg.active_form, .form_recovery.active_form {
    display: block;
    animation: showAuthForm .3s; }

.form_recovery {
  line-height: 1.3; }
  .form_recovery > *:not(:last-child) {
    margin-bottom: 30px; }

.check_container {
  padding: 25px 0 50px 0; }

@media screen and (max-width: 1280px) {
  .popup_auth .content_popup {
    padding: 65px 0 30px 0;
    box-sizing: border-box; }
    .popup_auth .content_popup > * {
      padding: 0 30px; } }

@media screen and (max-width: 1023px) {
  .popup_auth .btn {
    margin-bottom: 25px; }
    .popup_auth .btn:last-child {
      margin-bottom: 0; }
  .popup_auth .content_popup {
    width: 100%;
    min-width: 100%;
    padding: 65px 0 30px 0;
    overflow-y: auto;
    overflow-x: hidden; }
    .popup_auth .content_popup > * {
      padding: 0 20px; }
  .title_auth {
    font-size: 36px; }
  .auth_from {
    margin: 40px 0 15px 0;
    padding-left: 0; }
  .form_recovery p {
    font-size: 12px; }
  .check_container {
    padding: 10px 0 25px; } }

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  .popup_auth .content_popup {
    align-items: flex-start; } }

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  .popup_auth .form_reg #reg_confirm_pass {
    width: 50%; } }

.popup_ticket {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0ms linear 700ms, visibility 0s linear 700ms; }
  .popup_ticket.blue_popup .overlay {
    background: rgba(51, 133, 255, 0.8); }
  .popup_ticket.blue_popup .popup_ticket__item {
    position: relative; }
    .popup_ticket.blue_popup .popup_ticket__item:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(51, 133, 255, 0.8); }
    .popup_ticket.blue_popup .popup_ticket__item > *:not(.popup_ticket__item__del) {
      position: relative;
      z-index: 1; }
  .popup_ticket.blue_popup .popup_ticket__item__del {
    background-image: url(../images/sprites/sprite.png);
    background-position: -308px 0px;
    width: 24px;
    height: 24px; }
  .popup_ticket.active_popup {
    opacity: 1;
    visibility: visible;
    transition: opacity 0ms linear 0s, visibility 0s linear 0s; }
    .popup_ticket.active_popup .overlay {
      opacity: 1;
      visibility: visible;
      transition: opacity 700ms linear 0ms, visibility 0s linear 0ms; }
    .popup_ticket.active_popup .content_popup {
      transform: translateY(0);
      transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms; }
  .popup_ticket .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(99, 99, 99, 0.3);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 700ms linear 0ms, visibility 0s linear 700ms; }
  .popup_ticket .content_popup {
    transform: translateY(200%);
    transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms;
    max-height: 100%;
    box-sizing: border-box;
    border-radius: 7px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 2px 15px 0 rgba(66, 66, 66, 0.3); }
  .popup_ticket__item {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    box-sizing: border-box; }
    .popup_ticket__item.review .btn_inner {
      margin-top: 20px; }
    .popup_ticket__item.review, .popup_ticket__item.new_ticket, .popup_ticket__item.read {
      width: 540px;
      padding: 55px; }
      .popup_ticket__item.review .textarea_field, .popup_ticket__item.new_ticket .textarea_field, .popup_ticket__item.read .textarea_field {
        margin-top: 50px; }
    .popup_ticket__item.read {
      font-family: raleway_light, Arial, sans-serif;
      font-size: 14px;
      line-height: 1.3;
      text-align: justify; }
    .popup_ticket__item.new_wallet .btn_inner {
      margin-top: 30px; }
    .popup_ticket__item.new_bill, .popup_ticket__item.new_wallet {
      width: 430px;
      padding: 50px 40px; }
      .popup_ticket__item.new_bill .custom_select, .popup_ticket__item.new_wallet .custom_select {
        width: 100%;
        color: rgba(75, 75, 75, 0.8); }
        .popup_ticket__item.new_bill .custom_select .selected_val, .popup_ticket__item.new_wallet .custom_select .selected_val {
          text-align: left;
          padding: 10px 28px 10px 0; }
    .popup_ticket__item__title {
      font-family: raleway_light, Arial, sans-serif;
      font-size: 36px;
      line-height: 1.2;
      color: #3385ff;
      padding-bottom: 30px; }
    .popup_ticket__item__del {
      position: absolute;
      top: 30px;
      right: 30px;
      background-image: url(../images/sprites/sprite.png);
      background-position: -253px -265px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      z-index: 1; }
  .popup_ticket .btn_inner {
    text-align: right; }
    .popup_ticket .btn_inner .btn {
      display: inline-block; }

#main.active_pop_up {
  transform: translateY(-60px) scale(0.9);
  transition: transform 650ms cubic-bezier(0.77, 0, 0.11, 0.97) 50ms; }

.tabs_popup {
  padding: 55px 40px 40px 40px;
  box-sizing: border-box;
  max-width: 506px;
  color: #fff; }
  .tabs_popup .tabs__title {
    text-align: center;
    font-size: 20px;
    font-family: raleway_light, Arial, sans-serif;
    color: #fff;
    margin-bottom: 20px; }
  .tabs_popup .tab_content > *:not(:last-child) {
    margin-bottom: 35px; }
  .tabs_popup .checkbox_container + .text_explain {
    padding: 5px 0 0 24px; }
  .tabs_popup .few_fields {
    flex-wrap: wrap;
    margin-bottom: 0; }
    .tabs_popup .few_fields > *:first-child {
      width: 100%;
      margin-right: 0; }
  .tabs_popup .info_about_it {
    flex-wrap: wrap;
    font-size: 14px; }
    .tabs_popup .info_about_it__title, .tabs_popup .info_about_it__text {
      font-size: 14px; }
  .tabs_popup .info_container {
    width: 100%;
    justify-content: space-between; }
  .tabs_popup .btn {
    margin: 0 auto;
    margin-top: 30px; }
  .tabs_popup .container_card {
    flex-direction: column; }
    .tabs_popup .container_card > * {
      width: 100%;
      margin: 0; }
      .tabs_popup .container_card > *:not(:last-child) {
        margin-bottom: 20px; }
    .tabs_popup .container_card .flip-container .credit_card {
      margin: 0 auto; }
  .tabs_popup .container_card__info {
    justify-content: flex-start; }

.notification_popup {
  padding: 60px 40px 50px 40px;
  box-sizing: border-box;
  max-width: 429px;
  width: 100%;
  line-height: 1.5; }
  .notification_popup .attention:before {
    top: 4px; }

.notification_box {
  display: block;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-align: center;
  z-index: 3;
  width: 295px; }

.notification {
  display: none;
  width: 100%;
  background: #5c9dff;
  border-radius: 5px;
  position: relative;
  margin-bottom: 7px;
  box-shadow: 0 2px 15px 0 rgba(66, 66, 66, 0.3); }
  .notification.active {
    display: block; }
  .notification__img {
    width: 65px;
    height: 100%;
    background: #3385ff;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
    .notification__img:after {
      display: block;
      content: '';
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -24px;
      width: 22px;
      height: 22px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .notification__info {
    padding: 15px 20px 15px 85px;
    color: #fff;
    line-height: 1.2;
    text-align: left; }
    .notification__info__title {
      font-family: raleway_semibold, Arial, sans-serif;
      font-size: 11px;
      padding-bottom: 5px; }
    .notification__info__price {
      font-family: whitney_semibold;
      font-size: 12px; }
    .notification__info__currency {
      font-family: raleway_regular, Arial, sans-serif;
      font-size: 12px;
      text-transform: uppercase; }
    .notification__info__img {
      display: inline-block;
      background-image: url(../images/sprites/sprite.png);
      background-position: -308px -273px;
      width: 20px;
      height: 9px; }
  .notification__close {
    position: absolute;
    top: 7px;
    right: 7px;
    background-image: url(../images/sprites/sprite.png);
    background-position: -318px -282px;
    width: 13px;
    height: 12px;
    cursor: pointer; }

@media screen and (max-width: 768px) {
  .popup_ticket {
    bottom: 0;
    overflow: auto; }
    .popup_ticket__item.new_ticket .custom_select.with_title .custom_select__list {
      font-size: 14px; }
    .popup_ticket__item.new_ticket .textarea_field {
      margin-top: 40px; }
      .popup_ticket__item.new_ticket .textarea_field .custom_textarea {
        height: 58.8px; }
        .popup_ticket__item.new_ticket .textarea_field .custom_textarea:focus + .textarea_field__title {
          transform: translateY(-50px); }
      .popup_ticket__item.new_ticket .textarea_field.focus .textarea_field__title {
        transform: translateY(-50px); }
    .popup_ticket__item.review, .popup_ticket__item.new_ticket {
      width: 280px;
      padding: 40px 30px; }
    .popup_ticket__item.new_wallet {
      width: 290px; }
      .popup_ticket__item.new_wallet .custom_select__list {
        font-size: 12px; }
        .popup_ticket__item.new_wallet .custom_select__list.two_col {
          padding: 5px 0; }
          .popup_ticket__item.new_wallet .custom_select__list.two_col > * {
            padding: 8px 5px; }
      .popup_ticket__item.new_wallet .btn_inner {
        margin-top: 40px; }
    .popup_ticket__item.new_bill {
      width: 280px; }
    .popup_ticket__item.new_bill, .popup_ticket__item.new_wallet {
      padding: 40px 30px; }
    .popup_ticket__item__title {
      font-size: 24px; }
    .popup_ticket__item__del {
      top: 20px;
      right: 20px; }
    .popup_ticket .btn_inner {
      text-align: center; } }

@media screen and (max-width: 500px) {
  .tabs_popup {
    padding: 55px 20px 20px 20px; } }
