.field_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 7px 0;
  border-bottom: 1px solid rgba($darkColor,.8);
  //border-bottom: 1px solid rgba(75, 75, 75, 0.8);
  margin-bottom: 40px;
  line-height: 1.2;
  font-size: 19px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    &:not(:last-child) {
      padding-right: 5px;
      box-sizing: border-box;
    }
  }

  .box_title {
    color: rgba($darkColor,.8);
  }

  .box_val {
    color: $darkColor;
    font-size: 22px;
    font-family: $fontMoneyField;
  }
}

.box_checked {
  font-family: $fontLight;
  font-size: 14px;
  position: relative;
  padding-left: 24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: block;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    //transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: transparent;
    border: 1px solid $darkColor;
    border-radius: 50%;
    box-sizing: border-box;
  }

  &:after {
    left: 9px;
    top: 9px;
    transform: translate(-50%, -50%);
    margin-left: 1px;
    @include sprite($checbox-checked-dark);
  }
}


@media screen and (max-width: 1023px){
  .field_box {
    margin-bottom: 30px;
    font-size: 14px;
    flex-direction: column;

    .box_title {
      align-self: flex-start;
    }
  }


}
