.profile_menu {
  display: flex;
  justify-content: space-between;
  position: relative;
  @include max-width-center(100%, 900px);
  margin: 30px auto 45px auto;
  color: rgba($liteColor, .8);

  &:before, &:after {
    content: '';
    position: absolute;
    top: 100%;
    width: 999999999px;
    height: 1px;
    background: rgba($liteColor, 0.8);
    margin-top: -2px;
  }

  &:before {
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    left: 100%;
  }

  &__item {
    padding: 0 10px;
    display: block;
    box-sizing: border-box;

    span {
      display: block;
      padding: 7px 0;
      box-sizing: border-box;
    }

    &.active_item {
      color: $liteColor;
      span {
        border-bottom: 2px solid $liteColor;
      }
    }
  }
}

.profile_page {
  padding-top: 263px;
  box-sizing: border-box;

  .checkbox_container {
    width: 100%;
  }

  .few_fields {
    flex-wrap: wrap;

    .social_network {
      margin-right: 0 !important;
      max-width: 220px;
    }
  }
}

.content_profile {
  padding: $verticalPd $gorisontalPd;
}

.profile_form {
  @include max-width-center(100%, 620px);

  &:not(:last-child) {
    margin-bottom: 55px;
  }

  .few_fields {

    & > * {
      max-width: 50%;

      &:not(:last-child){
        max-width: calc(50% - 40px)
      }
    }
  //
  //  .input_field {
  //    width: 130px;
  //  }
  }

  & > *:not(:last-child) {
    margin-bottom: 55px;
  }

  .agreement_btn {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1280px){
  .content_profile{
    padding: $verticalPdTab $gorisontalPdTab;
  }
}

@media screen and (max-width: 1023px){
	.content_profile{
    padding: $verticalPdMob $gorisontalPdMob;

    //.input_field {
      //width: calc(100% - 115px);
    //}
	}

  .profile_page {
    padding-top: 0;

    .title_small {
      text-align: center;
      margin-bottom: 0;
      font-size: 24px;
    }

    .fix_box {
      position: relative;
      position: relative;
    }
  }

  .profile_menu {
    display: none;
  }

  .user_profile {
    .few_fields {
      //flex-wrap: wrap;
      flex-direction: column;

      & > * {
        width: 100%;
        max-width: 100%;

        &:not(:last-child) {
          margin-right: 0;
          width: 100%;
          max-width: 100%;
        }

        &:not(.custom_select) {
          margin-bottom: 0;
        }
      }

      .social_network  {
        order: 1;
        margin: 20px 0 0 10px;
        align-self: center;
      }

      .title_fields {
        margin-bottom: 18px;
      }

      .label_field {
        margin-bottom: 30px;
      }
    }
  }

  .label_field {
    & > span {
      &:first-child {
        //min-width: 115px;
      }
    }
  }

  .confirm_pass {
    & > span {
      &:first-child {
        min-width: 185px;
      }
    }
  }

  //.profile_form {
  //  .few_fields {
  //    .input_field {
  //      width: calc(100% - 115px)
  //    }
  //  }
  //}

  .profile_form:not(:last-child) {
    margin-bottom: 25px;
  }
}